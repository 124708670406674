/* DON'T EDIT THIS FILE: edit original and run build again */ export const usersWebPath = "/usuarios";

export const usersProfileWebPath = (
  uid: string | null = null,
  action: string | null = null
) => `${usersWebPath}/${uid ?? ":uid"}/${action ?? ":dir"}`;

export enum UsersProfilePages {
  view = "",
  editProfile = "editar-perfil",
  editRoles = "editar-roles",
  editTenant = "editar-tenant",
  disableProfile = "deshabilitar",
}
