/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveData } from "../../../credit/live-form/types.ts";
import {
  BaseTextLiveField,
  BaseTextLiveFieldSpec,
} from "../../../credit/live-form/ui/base-text-live-field-spec.tsx";
import { tokenLiveFieldType } from "./type.ts";

export type TokenLiveFieldSpec = BaseTextLiveFieldSpec & {
  length: number;
};

export class TokenLiveField extends BaseTextLiveField {
  private length: number;

  constructor(spec: TokenLiveFieldSpec) {
    super(spec, tokenLiveFieldType);
    this.length = spec.length;
  }

  isEmpty(liveData: LiveData): boolean {
    return !this.getValue(liveData);
  }

  getLength(): number {
    return this.length;
  }

  getError(liveData: LiveData): string | null {
    const value = this.getValue(liveData) as string;
    if (value.length !== this.getLength()) {
      return "El token debe tener largo " + this.length + ".";
    }
    return null;
  }

  getTypeDefaultValue(): string {
    return "";
  }
}
