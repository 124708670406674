/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { aptorganOnboardingOptions } from "./aptorgan-onboarding-options.ts";
import { aptorganTenant } from "./code.ts";
import { aptorganFullLabel } from "./full-label.ts";

class AptorganTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_aptorgan.svg?react")).default;
  }

  getOnboardingOptions(): any | null {
    return aptorganOnboardingOptions;
  }

  getCode(): string {
    return aptorganTenant;
  }

  getPathLabel(): string | null {
    return "ap-torgan";
  }

  getLabel(): string {
    return "AP Torgan";
  }

  getFullLabel(): string {
    return aptorganFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@aptorgan.com.ar";
  }

  getComplianceEmailAddress(): string {
    return "teamcompliance@aptorgan.com.ar";
  }

  getCuit(): string | null {
    return "33-63722141-1";
  }

  getCnvRegistryId(): string | null {
    return null;
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return true;
  }
}

const aptorganTenantHandler = new AptorganTenantHandler();

export default aptorganTenantHandler;
