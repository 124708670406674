/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { deltaTenant } from "./code.ts";
import { deltaFullLabel } from "./full-label.ts";
import { deltaLabel } from "./label.ts";

class DeltaTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return deltaTenant;
  }

  getPathLabel(): string | null {
    return "delta";
  }

  getLabel(): string {
    return deltaLabel;
  }

  getFullLabel(): string {
    return deltaFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "33-70917291-9";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const deltaTenantHandler = new DeltaTenantHandler();

export default deltaTenantHandler;
