/* DON'T EDIT THIS FILE: edit original and run build again */ import { spaceship } from "./spaceship.ts";

export const ascSorter =
  <T>(callback: FieldGetter<T>): Sorter<T> =>
  (a: T, b: T) =>
    spaceship(callback(a), callback(b));

type Sorter<T> = (a: T, b: T) => number;

type FieldGetter<T> = (a: T) => any;

export const descSorter =
  <T>(callback: FieldGetter<T>): Sorter<T> =>
  (a: T, b: T) =>
    -spaceship(callback(a), callback(b));

const multiSorter =
  <T>(...sorters: Sorter<T>[]) =>
  (a: T, b: T) => {
    for (let i = 0; i < sorters.length; ++i) {
      const result = sorters[i](a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };

export const sortByMany = <T>(array: T[], ...sorters: Sorter<T>[]) =>
  array.sort(multiSorter(...sorters));
