/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { stonexTenant } from "./code.ts";
import { stonexFullLabel } from "./full-label.ts";
import { stonexLabel } from "./label.ts";

class StonexTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return stonexTenant;
  }

  getPathLabel(): string | null {
    return "stonex";
  }

  getLabel(): string {
    return stonexLabel;
  }

  getFullLabel(): string {
    return stonexFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-69376494-3";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const stonexTenantHandler = new StonexTenantHandler();

export default stonexTenantHandler;
