/* DON'T EDIT THIS FILE: edit original and run build again */ // @ts-expect-error app-routes.js is generated
import { appRoutes } from "../../derived/app-routes.js";
import { RedirectIntoTenant } from "../../framework/routing/tenant-routing.tsx";
import { SimplePageNotFoundPage } from "../../framework/theme-pages/page-not-found.front.tsx";
import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { RouteWrapper } from "./route-wrapper.front.tsx";

export const MultiTenantRouting = ({
  currentTenant,
}: {
  currentTenant: string;
}) => {
  const nonTenantRoutes = appRoutes.filter(
    (route: any) => !route.requires.tenant
  );
  const tenantRoutes = appRoutes.filter((route: any) => route.requires.tenant);

  return (
    <Routes>
      {nonTenantRoutes.map(
        ({
          path,
          Component,
          requires,
          redirectOnNoAccount,
          isMatchfinSpecific,
          hardcodedProps,
        }: {
          path: string;
          Component: React.ComponentType<any>;
          requires?: {
            account?: boolean;
            permission?: string;
            tenant?: boolean;
          };
          redirectOnNoAccount?: string;
          isMatchfinSpecific: boolean;
          hardcodedProps: Record<string, any>;
        }) => (
          <Route
            key={path}
            path={path}
            element={
              <RouteWrapper
                currentTenant={currentTenant}
                path={path}
                Component={Component}
                requires={requires}
                redirectOnNoAccount={redirectOnNoAccount}
                hardcodedProps={hardcodedProps}
                isMatchfinSpecific={isMatchfinSpecific}
              />
            }
          />
        )
      )}
      {tenantRoutes.map(
        ({
          path,
          Component,
          requires,
          redirectOnNoAccount,
          hardcodedProps,
          isMatchfinSpecific,
        }: {
          path: string;
          Component: React.ComponentType<any>;
          requires?: {
            account?: boolean;
            permission?: string;
            tenant?: boolean;
          };
          isMatchfinSpecific: boolean;
          redirectOnNoAccount: string;
          hardcodedProps: Record<string, any>;
        }) => {
          const tenantPath = "/t/:tenantLabel" + path;
          return (
            <Fragment key={"f-" + path}>
              <Route
                key={tenantPath}
                path={tenantPath}
                element={
                  <RouteWrapper
                    currentTenant={currentTenant}
                    path={tenantPath}
                    Component={Component}
                    requires={requires}
                    redirectOnNoAccount={redirectOnNoAccount}
                    hardcodedProps={hardcodedProps}
                    isMatchfinSpecific={isMatchfinSpecific}
                  />
                }
              />
              <Route key={path} path={path} element={<RedirectIntoTenant />} />
            </Fragment>
          );
        }
      )}
      <Route key={404} path="*" element={<SimplePageNotFoundPage />} />
    </Routes>
  );
};
