/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { movilTenant } from "./code.ts";
import { movilFullLabel } from "./full-label.ts";
import { movilLabel } from "./label.ts";

class MovilTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return movilTenant;
  }

  getPathLabel(): string | null {
    return "movil";
  }

  getLabel(): string {
    return movilLabel;
  }

  getFullLabel(): string | null {
    return movilFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "33-71649227-9";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const movilTenantHandler = new MovilTenantHandler();

export default movilTenantHandler;
