/* DON'T EDIT THIS FILE: edit original and run build again */ import { Div } from "../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import React from "react";

export const FormBorder = ({
  children,
  className = "",
  footer = null,
}: {
  children: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
}): React.ReactNode => (
  <div className="mt-2 mb-6">
    <div className={className + " rounded-3 bg-white shadow-sm pt-4"}>
      <div className="pb-2">{children}</div>
    </div>
    {footer}
  </div>
);

export const TitledFormBorder = ({
  title,
  children,
  className,
}: {
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}) => (
  <FormBorder className={className}>
    <FormSectionTitle className="p-4" titleBold={true} title={title} />
    {children}
  </FormBorder>
);

export const FormSection = ({
  className = "",
  includeBottomMargin = true,
  children,
}: {
  className?: string;
  includeBottomMargin?: boolean;
  children: React.ReactNode;
}) => (
  <Div className={"px-5 " + (includeBottomMargin ? "mb-2 " : "") + className}>
    {children}
  </Div>
);

export const FormSectionTitle = ({
  title,
  note,
  right,
  bottom,
  icon,
  className = "",
  titleBold = false,
  border = true,
  isSectionOptional = false,
  inLineIcon = false,
}: {
  title: React.ReactNode;
  note?: React.ReactNode;
  right?: React.ReactNode;
  bottom?: React.ReactNode;
  className?: string;
  titleBold?: boolean;
  border?: boolean;
  icon?: React.ReactNode;
  isSectionOptional?: boolean;
  inLineIcon?: boolean;
}) => (
  <Div
    minPresenceAhead={3}
    className={
      "pb-4 px-5 " +
      (border ? "border-bottom-gray80-2 mb-3" : "") +
      " " +
      className
    }
  >
    <Div className={inLineIcon ? "d-flex flex-row gap-3" : ""}>
      {icon}
      <Div className="d-flex flex-row align-items-center">
        <Div
          className={"text-l-compact " + (titleBold ? "fw-bold" : "")}
          data-is-form-section-title
        >
          {title}
        </Div>
        <Div className="ms-4 fst-italic text-gray50">
          {isSectionOptional ? "Opcional" : null}
        </Div>
      </Div>
      {note ? <Div className="pt-1">{note}</Div> : null}
      {right}
    </Div>
    {bottom ? <Div className="mt-4">{bottom}</Div> : null}
  </Div>
);
