/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  ProofOfFciRulebookParams,
  RulebookValueOption,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import {
  emailChannelType,
  webHomeChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";
import { argenfundsTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";
import rulebookValueOptions from "./rulebook-value-options.ts";

class ArgenfundsOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return argenfundsTermsOfUseTitle;
  }

  async getTermsOfUse(): Promise<ComponentType<TermsOfUseParams>> {
    return (await import("./onboarding-terms-of-use.tsx")).default;
  }

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSwornStatementAboutLegality(): boolean {
    return true;
  }

  hasProofOfFciRulebookSection(): boolean {
    return true;
  }

  hasProofOfFciRulebookContent(): boolean {
    return true;
  }

  async getProofOfFciRulebookContent(): Promise<
    ComponentType<ProofOfFciRulebookParams>
  > {
    return (await import("./proof-of-fci-rulebook.tsx")).default;
  }

  async getProofOfFciRulebookAfterLabel(): Promise<
    ComponentType<ProofOfFciRulebookParams>
  > {
    return (await import("./proof-of-fci-rulebook-after-label.tsx")).default;
  }

  getRulebookValueOptions(): Record<string, RulebookValueOption> {
    return rulebookValueOptions;
  }

  getSupportedChannelTypes(): string[] {
    return [webHomeChannelType, emailChannelType];
  }

  supportsWebHomeChannelAccessType(): boolean {
    return true;
  }

  async getWebHomeHeader(): Promise<ComponentType<WebHomeHeaderParams>> {
    return (await import("./web-home-header.tsx")).default;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "none";
  }

  hasMatbaRofex(): boolean {
    return false;
  }

  hasSgrComissions(): boolean {
    return false;
  }

  hasIsQualifiedInvestorText(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const argenfundsOnboardingOptions = new ArgenfundsOnboardingOptions();
