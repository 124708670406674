/* DON'T EDIT THIS FILE: edit original and run build again */ import { DataPipe } from "./data-pipe.ts";

export const promisePipe = <T>(promise: Promise<T>): DataPipe<T> => {
  let canceled = false;
  const out = new DataPipe<T>(() => (canceled = true));
  (async () => {
    try {
      const value = await promise;
      if (!canceled) {
        out.setValue(value);
      }
    } catch (e) {
      if (!canceled) {
        out.setError(e);
      }
    }
  })();
  return out;
};
