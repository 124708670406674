/* DON'T EDIT THIS FILE: edit original and run build again */ import { LineOptions } from "../../../framework/tenant-handler-type/line-options.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BankTenantHandler } from "../../../framework/tenant-handlers/bank-tenant-handler.ts";
import { bancobicaLineOptions } from "./bancobica-line-options.ts";
import { bancobicaOnboardingOptions } from "./bancobica-onboarding-options.ts";
import { bancobicaTenant } from "./code.ts";
import { bancobicaFullLabel } from "./full-label.ts";

class BancobicaTenantHandler extends BankTenantHandler {
  getCode(): string {
    return bancobicaTenant;
  }

  getLineOptions(): LineOptions {
    return bancobicaLineOptions;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return bancobicaOnboardingOptions;
  }

  getPathLabel(): string | null {
    return "banco-bica";
  }

  getLabel(): string {
    return "Banco Bica";
  }

  getFullLabel(): string {
    return bancobicaFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "bancobica@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71233123-9";
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const bancobicaTenantHandler = new BancobicaTenantHandler();

export default bancobicaTenantHandler;
