/* DON'T EDIT THIS FILE: edit original and run build again */ import { currencySymbols } from "../../credit/currency/symbols.ts";
import { ARS, Currency } from "../../credit/currency/types.ts";
import { shortMonthNames } from "../../framework/core/month-names.ts";
import { padDayOrMonth } from "./pad-day-or-month.ts";

export const currencyPrecisionFullValue = "currencyPrecisionFullValue";
export const currencyPrecisionInK = "currencyPrecisionInK";
export const currencyPrecisionInM = "currencyPrecisionInM";

type CurrencyPrecision =
  | typeof currencyPrecisionFullValue
  | typeof currencyPrecisionInK
  | typeof currencyPrecisionInM;

const getFormatPesosValue = (
  amount: number,
  currencyPrecision: CurrencyPrecision
) => {
  if (currencyPrecision === currencyPrecisionFullValue) {
    return amount;
  } else if (currencyPrecision === currencyPrecisionInK) {
    return amount / 1000;
  } else if (currencyPrecision === currencyPrecisionInM) {
    return amount / 1000000;
  }
};

const getFormatPesosValueSuffix = (
  amount: number,
  currencyPrecision: CurrencyPrecision
) => {
  if (amount === 0 || currencyPrecision === currencyPrecisionFullValue) {
    return "";
  } else if (currencyPrecision === currencyPrecisionInK) {
    return " (miles)";
  } else if (currencyPrecision === currencyPrecisionInM) {
    return "M";
  }
};

export const formatCurrency = (
  amount: number,
  {
    currency = ARS,
    currencyPrecision = currencyPrecisionFullValue,
    includeAmountSuffix = true,
    maximumFractionDigits = 3,
  }: {
    currency?: Currency;
    currencyPrecision?: CurrencyPrecision;
    includeAmountSuffix?: boolean;
    maximumFractionDigits?: number;
  } = {}
) => {
  const symbols = currencySymbols[currency];
  if (!symbols) {
    throw new Error("unsupportedCurrency: " + currency);
  }
  return (
    new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
      currencySign: "accounting",
      minimumFractionDigits: 0,
      maximumFractionDigits,
    })
      // @ts-expect-error: FIXME: this "works" with undefined returning "$ NaN
      .format(getFormatPesosValue(amount, currencyPrecision))
      .replace(/\$\u00A0/, symbols.prefix) +
    symbols.suffix +
    (includeAmountSuffix
      ? getFormatPesosValueSuffix(amount, currencyPrecision)
      : "")
  );
};

export const formatKCurrency = (amount: number, currency: Currency = ARS) =>
  formatCurrency(amount, {
    currency,
    currencyPrecision: currencyPrecisionInK,
    maximumFractionDigits: 0,
  });

export const formatKCurrencyNoSuffix = (
  amount: number,
  currency: Currency = ARS
) =>
  formatCurrency(amount, {
    currency,
    maximumFractionDigits: 0,
    currencyPrecision: currencyPrecisionInK,
    includeAmountSuffix: false,
  });

export const formatMillionsOfPesos = (amount: number) =>
  formatCurrency(amount, {
    currencyPrecision: currencyPrecisionInM,
    maximumFractionDigits: 2,
  });

export const formatPercent = (
  value: number,
  { maximumFractionDigits = 0, minimumFractionDigits = 0 } = {}
) =>
  Intl.NumberFormat("es-AR", {
    style: "percent",
    minimumFractionDigits,
    maximumFractionDigits,
  })
    .format(value)
    .replace(/\u00A0%/, "%");

export const formatNumber = (
  number: number,
  { maximumFractionDigits = 3, minimumFractionDigits = 0 } = {}
) =>
  new Intl.NumberFormat("es-AR", {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(number);

export const formatDate = (day: number, month: number, year: number) =>
  padDayOrMonth(day) +
  "-" +
  shortMonthNames[month] +
  "-" +
  year.toString().slice(2);

export const formatUtcDateAsIso = (date: Date) =>
  date.getUTCFullYear() +
  "-" +
  padDayOrMonth(date.getUTCMonth() + 1) +
  "-" +
  padDayOrMonth(date.getUTCDate());

export const pesosType = "pesos";
export const dollarsType = "dollars";
export const numberType = "number";
export const percentType = "percent";
export const dateType = "date";

export type FormatType =
  | typeof pesosType
  | typeof dollarsType
  | typeof numberType
  | typeof percentType
  | typeof dateType;

export const formatValue = (
  type: FormatType,
  value: any,
  options: any = undefined
) => {
  if (value === null) {
    return null;
  } else if (isNaN(value) || !isFinite(value)) {
    return "-.-";
  } else if (type === pesosType || type === dollarsType) {
    return formatCurrency(value, options);
  } else if (type === numberType) {
    return formatNumber(value, options);
  } else if (type === percentType) {
    return formatPercent(value, options);
  } else if (type === dateType) {
    return formatUtcDate(value);
  } else {
    throw new Error("unknownFormatType: " + type);
  }
};

/**
 * when we deal with a date (without specific time) we store it and show it in UTC
 * to avoid conversions
 */
export const formatUtcDate = (date: Date) =>
  formatDate(date.getUTCDate(), date.getUTCMonth(), date.getUTCFullYear());

export const formatItemsLabel = <T>(
  items: T[],
  format: (item: T) => string,
  lastItemSeparator = " y "
) => {
  if (items.length === 1) {
    return format(items[0]);
  }
  const [otherItems, lastItem] = [items.slice(0, -1), items.slice(-1)[0]];
  return (
    otherItems.map((item) => format(item)).join(", ") +
    lastItemSeparator +
    format(lastItem)
  );
};
