/* DON'T EDIT THIS FILE: edit original and run build again */ import { Settings } from "../../framework/settings/schema.ts";
import React, { useContext } from "react";

export const AppStateContext = React.createContext({
  settings: {},
  realmFromUrl: null,
} as { settings: Settings; realmFromUrl: string | null });

export const useSettings = (): Settings => {
  const { settings } = useContext(AppStateContext);
  return settings;
};

export const useRealmFromUrl = (): string => {
  const { realmFromUrl } = useContext(AppStateContext);
  if (!realmFromUrl) {
    throw new Error("noRealmFromUrl");
  }
  return realmFromUrl;
};
