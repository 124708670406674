/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { SgrTenantHandler as SgrCompanyTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { sgrTenant } from "./code.ts";
import { sgrFullLabel } from "./full-label.ts";
import { sgrOnboardingOptions } from "./sgr-onboarding-options.ts";
class SgrTenantHandler extends SgrCompanyTenantHandler {
  // Is this a real tenant?
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // we probably should change this one
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_aptorgan.svg?react")).default;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return sgrOnboardingOptions;
  }

  getCode(): string {
    return sgrTenant;
  }

  getPathLabel(): string | null {
    return "sgr";
  }

  getLabel(): string {
    return "SGR";
  }

  getFullLabel(): string {
    return sgrFullLabel;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  getComplianceEmailAddress(): string {
    return "compliance@example.com";
  }

  getNotificationsFrom(): string {
    return "no-responder@example.com";
  }

  getCuit(): string | null {
    return "30-11111110-1";
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const sgrTenantHandler = new SgrTenantHandler();

export default sgrTenantHandler;
