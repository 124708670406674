/* DON'T EDIT THIS FILE: edit original and run build again */ import { getRealmFromCurrentHostAlone } from "../../framework/realm/get-realm-from-current-host.ts";
import { maybeGetBasenameAndRealmFromGenericUrl } from "../../framework/realm/get-realm-from-generic-url.ts";

export const parseRealmFromUrl = (
  location: Location,
  configGetter?: () => any // FIXME: when config is better typed
): [basename: string, realm: string | undefined] => {
  const basenameAndRealm = maybeGetBasenameAndRealmFromGenericUrl(
    location,
    configGetter
  );
  if (basenameAndRealm) {
    return basenameAndRealm;
  }
  const realmFromHost = getRealmFromCurrentHostAlone(location, configGetter);
  return ["", realmFromHost];
};
