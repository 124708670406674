/* DON'T EDIT THIS FILE: edit original and run build again */ import { WhatsappIcon } from "../../framework/theme-icons/icon.ts";

export const WhatsappButton = ({
  className = "position-fixed bottom-0 end-0 mb-5 me-5",
  iconClassName = "icon-5",
  children,
}: {
  className?: string;
  iconClassName?: string;
  children?: React.ReactNode;
}) => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=5491134550101"
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      <WhatsappIcon className={iconClassName} />
      {children}
    </a>
  );
};
