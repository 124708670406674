/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { facimexTenant } from "./code.ts";
import { facimexOnboardingOptions } from "./facimex-onboarding-options.ts";
import { facimexFullLabel } from "./full-label.ts";

class FacimexTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_facimex.svg?react")).default;
  }

  getOnboardingOptions() {
    return facimexOnboardingOptions;
  }

  getCode() {
    return facimexTenant;
  }

  getPathLabel() {
    return "facimex";
  }

  getLabel() {
    return "Facimex";
  }

  getFullLabel() {
    return facimexFullLabel;
  }

  getNotificationsFrom() {
    return "no-responder@facimex.com.ar";
  }

  getComplianceEmailAddress(): string {
    return "teamcompliance@facimex.com.ar";
  }

  getCuit() {
    return "33-63722149-9";
  }

  getCnvRegistryId(): string | null {
    return "99";
  }
}

const facimexTenantHandler = new FacimexTenantHandler();
export default facimexTenantHandler;
