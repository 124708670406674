/* DON'T EDIT THIS FILE: edit original and run build again */ export const serializePropsSample = (props: Record<string,any>) => {
  const str = JSON.stringify(
    props,
    (_, val) => {
      if (typeof val === "undefined") {
        return "__UNDEFINED__";
      } else if (typeof val === "function") {
        return "__FUNCTION__:" + val.name;
      }
      return val;
    },
    2
  );
  const sampleLength = 20000;
  return str.length > sampleLength ? str.substring(0, sampleLength) + "…" : str;
};
