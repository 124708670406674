/* DON'T EDIT THIS FILE: edit original and run build again */ export const passwordSetterErrorMessages: { [prop: string]: string } = {
  straightRow: "teclas consecutivas en tu teclado son fáciles de adivinar",
  keyPattern: "patrones cortos son fáciles de adivinar",
  simpleRepeat: 'caracteres repetidos como "aaa" son fáciles de adivinar',
  extendedRepeat: 'patrones repetidos como "abcabcabc" son fáciles de adivinar',
  sequences: 'patrones comunes como "abc" son fáciles de adivinar',
  recentYears: "los años recientes son fáciles de adivinar",
  dates: "las fechas son fáciles de adivinar",
  topTen: "es de las contraseñas más usadas",
  topHundred: "es una contraseña usada con mucha frecuencia",
  common: "es una contraseña usada comúnmente",
  similarToCommon: "es similar a una contraseña usada habitualmente",
  wordByItself: "palabras únicas son fáciles de adivinar",
  namesByThemselves: "nombres o apellidos a solas son fáciles de adivinar",
  commonNames: "nombre y apellidos comunes son fáciles de adivinar",
  userInputs:
    "no debería haber datos personales o relacionados con esta página",
  pwned: "esta contraseña fue expuesta por una violación de datos en Internet",
};
