/* DON'T EDIT THIS FILE: edit original and run build again */ import { useEffect, useState } from "react";

export const Countdown = ({
  time,
  onStop,
}: {
  time: number;
  onStop: () => void;
}): React.ReactNode => {
  const [counter, setCounter] = useState(time);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    counter === 0 && onStop();
  }, [counter, onStop]);
  return <>{counter}</>;
};
