/* DON'T EDIT THIS FILE: edit original and run build again */ import { ARS, BRL, EUR, USD } from "./types.ts";

export const currencySymbols = {
  [USD]: {
    prefix: "",
    suffix: " USD",
    name: "USD",
  },
  [ARS]: {
    prefix: "$",
    suffix: "",
    name: "$",
  },
  [EUR]: {
    prefix: "€",
    suffix: "",
    name: "€",
  },
  [BRL]: {
    prefix: "R$",
    suffix: "",
    name: "R$",
  },
};
