/* DON'T EDIT THIS FILE: edit original and run build again */ import { FormControl } from "react-bootstrap";
import { NumberFormatValues, PatternFormat } from "react-number-format";

export const CuitFormControl = ({
  value,
  setValue,
  autoFocus,
  onKeyPress,
  onFocus,
  onBlur,
  onKeyUp,
  size,
  placeholder,
}: {
  value: string;
  setValue: (value: string) => void;
  autoFocus?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  size?: "sm" | "lg" | undefined;
  placeholder?: string;
}) => (
  <FormControl
    value={value}
    placeholder={placeholder}
    autoFocus={autoFocus}
    onKeyPress={onKeyPress}
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyUp={onKeyUp}
    size={size}
    onValueChange={(values: NumberFormatValues) =>
      setValue(values.formattedValue)
    }
    as={PatternFormat}
    format={"##-########-#"}
  />
);
