/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { approveEndClientAccessUsersType } from "../../framework/users/approve-access-requests/type.ts";
import { approveEndClientUsersType } from "../../framework/users/approve-end-client-users/type.ts";
import { approveUsersType } from "../../framework/users/approve-tenant-users/type.ts";
import { disabledUsersType } from "../../framework/users/disabled-users/type.ts";
import { inviteUsersType } from "../../framework/users/invite-users/type.ts";
import { tenantUsersType } from "../../framework/users/tenant-users/type.ts";
import { UserListType } from "./user-list-type.ts";

export const usersListTypes: UserListType[] = [
  approveUsersType,
  approveEndClientUsersType,
  approveEndClientAccessUsersType,
  inviteUsersType,
  tenantUsersType,
  disabledUsersType,
];

const usersListTypesById = mapArrayToObject(usersListTypes, (usersListType) => [
  usersListType.id,
  usersListType,
]);

export const getUsersListType = (typeId: string) => usersListTypesById[typeId];
