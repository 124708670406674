/* DON'T EDIT THIS FILE: edit original and run build again */ import { Loading } from "../../framework/theme/loading.tsx";
import { useEffect, useReducer } from "react";

export const lazyComponent = (
  importCallback: () => Promise<React.ComponentType<any>>
) => {
  let InnerComponent: React.ComponentType<any> | null = null;
  return (params: any) => {
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    useEffect(() => {
      importCallback().then((response) => {
        InnerComponent = response;
        forceUpdate();
      });
    }, []);
    if (!InnerComponent) {
      return <Loading />;
    }
    return <InnerComponent {...params} />;
  };
};
