/* DON'T EDIT THIS FILE: edit original and run build again */ export const getFileExtension = (fileName: string): string =>
  fileName.split(".").pop() as string;

export const getDottedFileExtension = (filePath: string): string | null => {
  const dots = filePath.split(".");
  const len = dots.length;
  if (len > 1) {
    return dots[len - 1];
  }
  return null;
};
