/* DON'T EDIT THIS FILE: edit original and run build again */ export const saCompanyType = "SA";
export const srlCompanyType = "SRL";
export const sasCompanyType = "SAS";
export const shCompanyType = "SH";
export const sauCompanyType = "SAU";
export const scaCompanyType = "SCA";
export const cooperativeCompanyType = "COOPERATIVA";
export const transitoryUnionType = "UNION_TRANSITORIA";
export const trustType = "FIDEICOMISO";
export const financialTrustType = "FIDEICOMISO FINANCIERO";
export const mutualGuaranteeType = "SGR";
export const associationType = "ASOCIACION";
export const publicAgencyType = "ORGANISMO_PUBLICO";
export const capISeccionIvType = "CAP_I_SECCION_IV";
export const beingFormedCompanyType = "EN_FORMACION";
// "cajas" or "colegios"
export const publicNotStateLevelCompanyType = "PUBLICA_NO_ESTATAL";

export const allKnownCompanyTypes = [
  saCompanyType,
  srlCompanyType,
  sasCompanyType,
  shCompanyType,
  sauCompanyType,
  scaCompanyType,
  cooperativeCompanyType,
  transitoryUnionType,
  trustType,
  financialTrustType,
  mutualGuaranteeType,
  associationType,
  publicAgencyType,
  capISeccionIvType,
  beingFormedCompanyType,
  publicNotStateLevelCompanyType,
];

export const companyTypeLabels = {
  [saCompanyType]: "SA",
  [srlCompanyType]: "SRL",
  [sasCompanyType]: "SAS",
  [shCompanyType]: "Sociedad de hecho",
  [sauCompanyType]: "SAU",
  [scaCompanyType]: "SCA",
  [cooperativeCompanyType]: "Cooperativa",
  [transitoryUnionType]: "Unión transitoria",
  [trustType]: "Fideicomiso",
  [financialTrustType]: "Fideicomiso Financiero",
  [mutualGuaranteeType]: "SGR",
  [associationType]: "Asociación",
  [publicAgencyType]: "Organismo público",
  [capISeccionIvType]: "Cap. I sección IV",
  [beingFormedCompanyType]: "En formación",
  [publicNotStateLevelCompanyType]: "Pública no estatal",
};
