/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseFolder } from "../../credit/base-folder/base-folder-type.ts";
import { getEntityInfo } from "../../credit/entity-info/get-entity-info.ts";
import { Gender } from "../../credit/gender/genders.ts";
import { isCompanyCuit } from "../../credit/gov-id-number/cuit.ts";
import { EntityType } from "../../credit/gov-id-number/entity-type.ts";
import { Spinner } from "react-bootstrap";

const validCuitRegex = /^[0-9]{2}-[0-9]{8}-[0-9]{1}$/;
const validDniRegex = /^[MFT0-9][0-9]\.[0-9]{3}\.[0-9]{3}$/;
const emptyIdNumberRegex = /^[_\-,]*$/;

const isValidIdNumber = (idNumber: string, useDni?: boolean) => {
  if (useDni) {
    return validDniRegex.test(idNumber);
  } else {
    return validCuitRegex.test(idNumber);
  }
};

export const getEntityInfoCall = async (
  baseFolder: BaseFolder,
  idNumber: string,
  useDni: boolean,
  gender: Gender
) => {
  if (!isValidIdNumber(idNumber, useDni)) {
    return null;
  }
  try {
    const params: any = { baseFolder };
    if (useDni) {
      params.dni = idNumber;
    } else {
      params.cuit = idNumber;
    }
    if (gender) {
      params.gender = gender;
    }
    const info = await getEntityInfo(params);
    if (info) {
      return info;
    }
  } catch (e) {
    console.error("downloadInfoFromCuitError", e);
    return null;
  }
};

const isEmptyIdNumber = (idNumber: string) => {
  return emptyIdNumberRegex.test(idNumber);
};

const busyError = "Aún se están obteniendo los datos del CUIT";
const invalidCuitError = "El CUIT es inválido";
const invalidDniError = "El DNI es inválido";
const emptyError = "Falta introducir el CUIT";
const noCuitDataReturnedError = "No existe el CUIT u otro error";
const noDniDataReturnedError = "No existe el DNI u otro error";
const onlyHumanEntitiesError = "Sólo se pueden indicar personas humanas";
const onlyCompanyEntitiesError = "Sólo se pueden indicar personas jurídicas";

export const findCurrentIdNumberError = ({
  required,
  idNumber,
  loadingIdNumber,
  hasEntityName,
  allowEntityTypes,
  useDni,
  backendErrorMessage,
}: {
  required?: boolean;
  idNumber: string;
  loadingIdNumber: boolean;
  hasEntityName: boolean;
  allowEntityTypes?: EntityType;
  useDni: boolean;
  backendErrorMessage?: string;
}) => {
  if (backendErrorMessage) {
    return backendErrorMessage;
  } else if (isEmptyIdNumber(idNumber)) {
    if (required) {
      return emptyError;
    } else {
      return null;
    }
  } else if (!isValidIdNumber(idNumber, useDni)) {
    if (useDni) {
      return invalidDniError;
    } else {
      return invalidCuitError;
    }
  } else if (loadingIdNumber) {
    return busyError;
  } else {
    const isCompany = !useDni && isCompanyCuit(idNumber);
    if (allowEntityTypes === EntityType.OnlyHumans && isCompany) {
      return onlyHumanEntitiesError;
    } else if (allowEntityTypes === EntityType.OnlyCompanies && !isCompany) {
      return onlyCompanyEntitiesError;
    } else if (!hasEntityName) {
      return useDni ? noDniDataReturnedError : noCuitDataReturnedError;
    } else {
      return null;
    }
  }
};

const StatusContentContainer = ({
  children,
  isError,
}: {
  children: React.ReactNode;
  isError: boolean;
}) => (
  <div
    className={
      "form-control border-0 col-sm py-2 " +
      (isError ? "bg-orange70" : "bg-gray80")
    }
  >
    {children}
  </div>
);

export const CuitStatusContent = ({
  loadingIdNumber,
  markError,
  error,
  idNumber,
  entityName,
}: {
  loadingIdNumber: boolean;
  markError: boolean;
  error?: string | null;
  idNumber: string;
  entityName?: string;
}) => {
  if (loadingIdNumber) {
    return (
      <StatusContentContainer isError={false}>
        <span>
          <Spinner
            className="mx-1 text-blue50"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Buscando...
        </span>
      </StatusContentContainer>
    );
  } else if (markError) {
    return (
      <StatusContentContainer isError={true}>{error}</StatusContentContainer>
    );
  } else if (idNumber && entityName) {
    return (
      <StatusContentContainer isError={false}>
        {entityName}
      </StatusContentContainer>
    );
  } else {
    return null;
  }
};

export const shouldMarkCuitInputError = ({
  showErrors,
  isFocused,
  error,
  isBackendErrorMessage,
}: {
  showErrors: boolean;
  isFocused: boolean;
  error: string | null;
  isBackendErrorMessage: boolean;
}) => {
  const importantError =
    isBackendErrorMessage ||
    error === noDniDataReturnedError ||
    error === noCuitDataReturnedError ||
    error === onlyCompanyEntitiesError ||
    error === onlyHumanEntitiesError;
  const unimportantError =
    error === invalidDniError ||
    error === invalidCuitError ||
    error === emptyError;
  return importantError || (showErrors && !isFocused && unimportantError);
};
