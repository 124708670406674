/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { fidavalTenant } from "./code.ts";
import { fidavalFullLabel } from "./full-label.ts";
import { fidavalLabel } from "./label.ts";

class FidavalTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return fidavalTenant;
  }

  getPathLabel(): string | null {
    return "fidaval";
  }

  getLabel(): string {
    return fidavalLabel;
  }

  getFullLabel(): string {
    return fidavalFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "33-70900345-9";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const fidavalTenantHandler = new FidavalTenantHandler();

export default fidavalTenantHandler;
