/* DON'T EDIT THIS FILE: edit original and run build again */ /*
Tipos:

20, 23, 24, 25, 26 y 27 para Personas Físicas
30, 33 y 34 para Personas Jurídicas.
 */

const dummyPeopleCuitPrefixNumber = "41";
export const dummyPeopleCuitPrefix = dummyPeopleCuitPrefixNumber + "-";

export const isCompanyCuit = (cuit: string) => {
  return /^(30|33|34|42)/.test(cuit);
};

export const isDummyPersonCuit = (cuit: string) =>
  cuit.startsWith(dummyPeopleCuitPrefixNumber);

const cuitPartsRegex = /^(\d{2})-?(\d{8})-?(\d)$/;

export const isCuit = (cuit: string) => cuitPartsRegex.test(cuit);

export const formatCuit = (cuit: string) => {
  const parts = cuitPartsRegex.exec(cuit);
  if (!parts) {
    throw new Error("invalidCuit: " + cuit);
  }
  return parts[1] + "-" + parts[2] + "-" + parts[3];
};
