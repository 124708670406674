/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { maxcapitalTenant } from "./code.ts";
import { maxcapitalFullLabel } from "./full-label.ts";

class MaxcapitalTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return maxcapitalTenant;
  }

  getPathLabel(): string | null {
    return "max-capital";
  }

  getLabel(): string {
    return "MAX Capital";
  }

  getFullLabel(): string {
    return maxcapitalFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@max.capital";
  }

  getComplianceEmailAddress(): string {
    return "teamcompliance@max.capital";
  }

  getCuit(): string | null {
    return "30-71452205-8";
  }

  getCnvRegistryId(): string | null {
    return "570";
  }

  doEndClientUsersHaveAccessToTheirEndClientLines(): boolean {
    return true;
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const maxcapitalTenantHandler = new MaxcapitalTenantHandler();

export default maxcapitalTenantHandler;
