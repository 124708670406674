/* DON'T EDIT THIS FILE: edit original and run build again */ import { useNavigateInTenant } from "../../framework/routing/tenant-routing.tsx";
import {
  SidebarMenuItem,
  SidebarNotification,
} from "../../framework/sidebar-type/sidebar-types.ts";
import React, { useState } from "react";
import { getMenuOptionStyle } from "./menu-option-style.ts";

const NotificationBadge = ({
  notificationData,
  className = "",
}: {
  notificationData: SidebarNotification | null;
  className: string;
}): React.ReactNode =>
  notificationData?.unread ? (
    <div
      title={notificationData.badge ?? "Notificaciones pendientes"}
      className={"shadow-sm rounded-circle bg-warning p-1 " + className}
      style={{ zIndex: "3" }}
    />
  ) : null;

export const SidebarMenuOption = ({
  path,
  label,
  icon,
  afterLabel = null,
  notificationData = null,
  selected = false,
  showLabel = true,
  showHover = true,
  sidebarOpen = false,
}: {
  path: string;
  label: React.ReactNode;
  icon: SidebarMenuItem["icon"];
  afterLabel?: React.ReactNode | null;
  notificationData?: SidebarNotification | null;
  selected?: boolean;
  showLabel?: boolean;
  showHover?: boolean;
  sidebarOpen?: boolean;
}): React.ReactNode => {
  const navigate = useNavigateInTenant();
  const [hover, setHover] = useState(false);
  const style = getMenuOptionStyle(hover, selected, showHover);
  const itemsColor = hover || selected ? "text-white" : "text-gray50";
  return (
    <div
      className="cursor-pointer d-flex align-items-center my-3"
      onClick={() => navigate(path)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      tabIndex={0}
      style={sidebarOpen ? style : {}}
    >
      <div style={sidebarOpen ? {} : style}>
        <NotificationBadge
          notificationData={notificationData}
          className="position-absolute mx-5 my-1"
        />
        <div className="p-2">{icon("icon-2 " + itemsColor)}</div>
      </div>
      {showLabel && (
        <div
          className={"text-nowrap " + itemsColor}
          style={sidebarOpen ? {} : { visibility: "hidden" }}
        >
          {label}
        </div>
      )}

      {afterLabel}
    </div>
  );
};
