/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../../credit/live-form/ui/text-viewer.tsx";
import { LiveFieldInput, LiveFieldRenderer } from "../../../credit/live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../../credit/live-form/ui/use-live-after-blur-text-input.tsx";
import { TextInput } from "../../../credit/ui/form/text-input.tsx";
import { cbuLiveFieldType } from "./type.ts";

const Input: LiveFieldInput = (fieldInfo) => (
  <TextInput {...useLiveAfterBlurTextInput(fieldInfo)} />
);

export const cbuLiveFieldRenderers: LiveFieldRenderer = {
  type: cbuLiveFieldType,
  Input,
  Viewer: LiveTextViewer,
};
