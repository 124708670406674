/* DON'T EDIT THIS FILE: edit original and run build again */ import { Form } from "react-bootstrap";

export const Checkbox = ({
  id,
  value,
  setValue,
  onChange,
  className = "",
  onBlur,
  type = "checkbox",
  autoFocus,
  style,
  disabled = false,
}: {
  id?: string;
  value: any;
  setValue: (value: any) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  type?: "checkbox" | "radio";
  autoFocus?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}) => (
  <Form.Check.Input
    className={className}
    disabled={disabled}
    style={style}
    id={id}
    autoFocus={autoFocus}
    onBlur={onBlur}
    onChange={(event) => {
      if (setValue) {
        setValue(!value);
      }
      if (onChange) {
        onChange(event);
      }
    }}
    checked={value}
    type={type}
  />
);
