/* DON'T EDIT THIS FILE: edit original and run build again */ import { AtPath, NestedAtPath, NestedReplace } from "../../framework/core/at-path.ts";
import { ElementOf } from "../../framework/core/element-of.ts";
import { pathGet, pathUpdate } from "../../framework/dotpath/path.ts";
import clone from "just-clone";
import { DataPipe } from "./data-pipe.ts";

type CreateItemPipeFunction<TParam, TReturn> = (
  result: TParam
) => DataPipe<TReturn>;
type JoinItemFunction<TParent, TChild, TReturn> = (
  parentValue: TParent,
  childValue: TChild | undefined
) => TReturn | undefined;
type DataCallbackFunction<TParam> = (data: TParam[]) => void;
type ErrorCallbackFunction = (error: Error) => void;

/**
 * call dataCallback with new data once all data is ready and on every subsequent change
 * @param {function} errorCallback - called with error if any child pipe has an error, if not passed, errors are ignored
 */
const joinedResults = <TParent, TChild, TReturn>(
  results: TParent[],
  joinItem: JoinItemFunction<TParent, TChild, TReturn>,
  createItemPipe: CreateItemPipeFunction<TParent, TChild>,
  dataCallback: DataCallbackFunction<TReturn>,
  errorCallback: ErrorCallbackFunction | null
): (() => void) => {
  const resultFetchers: (() => TReturn | undefined)[] = [];
  const childPipes: DataPipe<TChild>[] = [];
  const sendValueIfReady = () => {
    if (childPipes.every((childPipe) => !childPipe.isLoading())) {
      dataCallback(
        resultFetchers
          .map((fetcher) => fetcher())
          .filter<TReturn>((item): item is TReturn => item !== undefined)
      );
    }
  };
  results.forEach((result) => {
    const childPipe = createItemPipe(result);
    if (childPipe) {
      resultFetchers.push(() =>
        joinItem(
          result,
          childPipe.getError() ? undefined : childPipe.getValue()
        )
      );
      childPipes.push(childPipe);
      const update = () => {
        const error = childPipe.getError();
        if (error && errorCallback) {
          errorCallback(error);
        } else {
          sendValueIfReady();
        }
      };
      childPipe.addDataListener(update);
      if (!childPipe.isLoading()) {
        update(); // FIXME?: the childPipes.every() will only wait for elements before in the array, but not after. This might be intentional though
      }
    } else {
      resultFetchers.push(() => joinItem(result, undefined));
    }
  });
  // in case there's no child pipe or the pipe is already loaded
  sendValueIfReady();
  return () => {
    for (const pipe of childPipes) {
      pipe.delete();
    }
  };
};

/**
 * A pipe that joins the results of a parent pipe with the results of a child pipe, using a callback function to join the two sets of results.
 *
 * This function is intended to be used when you have an array of values (such as users) and you need to fetch additional data for each item in the array (such as a user's profile or role).
 *
 * @param {DataPipe} parentPipe - The parent pipe whose results (an array) will be joined.
 * @param {DataPipe} createItemPipe - The child pipe whose results will be used to create new items to be joined with the parent pipe's results.
 * @param {JoinItemFunction} joinItem - A callback function with the signature `(parentValue, childValue) => jointValue`, used to join each parent result with its corresponding child result.
 * @param {string} [path=""] - The path to the array of results on the parent pipe's value object. If `""`, the array is on the root. If a path like `"here.is.my.data"`, the array is found at `root.here.is.my.data`.
 *
 * @returns {DataPipe} A pipe that emits the joined results.
 */
export const joinPipe = <
  TDataArray extends NestedAtPath<unknown[], TPath, ".">,
  TChild,
  TReturn,
  TPath extends string = ""
  // until all is properly typed
>(
  parentPipe: DataPipe<TDataArray>,
  createItemPipe: CreateItemPipeFunction<
    ElementOf<AtPath<TDataArray, TPath, ".">>,
    TChild
  >,
  joinItem: JoinItemFunction<
    ElementOf<AtPath<TDataArray, TPath, ".">>,
    TChild,
    TReturn
  >,
  path: TPath | "" = "",
  ignoreErrors?: boolean
): DataPipe<NestedReplace<TDataArray, TReturn[], TPath, ".">> => {
  let deleteLastChildPipes: () => void = () => null;
  const out = new DataPipe<NestedReplace<TDataArray, TReturn[], TPath, ".">>(
    () => {
      deleteLastChildPipes();
      parentPipe.delete();
    }
  );
  let hadSomeError = false;
  const fullPath = path === "" ? "root" : "root." + path;
  const update = () => {
    deleteLastChildPipes();
    if (hadSomeError) {
      return;
    }
    const parentError = parentPipe.getError();
    if (parentError) {
      hadSomeError = true;
      out.setError(parentError);
      return;
    }
    const parentResults: AtPath<TDataArray, TPath, "."> = pathGet(
      { root: parentPipe.getValue() },
      fullPath,
      []
    );
    deleteLastChildPipes = joinedResults(
      // FIXME: I don't understand this generics or even their names at all, we should revise them
      parentResults as any,
      joinItem,
      createItemPipe,
      (results) => {
        const tmp = { root: clone(parentPipe.getValue() as object) };
        pathUpdate(tmp, fullPath, results);
        out.setValue(
          tmp.root as NestedReplace<TDataArray, TReturn[], TPath, ".">
        ); // pathUpdate implicitly modifies tmp's type
      },
      ignoreErrors
        ? null
        : (error) => {
            if (!hadSomeError) {
              hadSomeError = true;
              out.setError(error);
            }
          }
    );
  };
  parentPipe.addDataListener(update);
  if (!parentPipe.isLoading()) {
    update();
  }
  return out;
};
