/* DON'T EDIT THIS FILE: edit original and run build again */ export const mapArrayToObject = <T, V>(
  iterable: Iterable<T>,
  callback: (_: T) => [string, V]
): Record<string, V> => {
  const out: Record<string, V> = {};
  for (const item of iterable) {
    const [newKey, newValue] = callback(item);
    out[newKey] = newValue;
  }
  return out;
};
