/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { combinePipesAsObject } from "./combine-pipes-as-object.ts";
import { DataPipe } from "./data-pipe.ts";

/**
 * @generic A, B, C: records
 * @param keyA field in type A that holds a common id with a field in type B
 * @param keyB the respective field in type B
 */
export const leftJoin = <A, B, C>(
  arrayA: Array<A>,
  arrayB: Array<B>,
  keyA: keyof A | ((elemA: A) => keyof A),
  keyB: keyof B | ((elemB: B | null) => keyof B),
  combineRecords: (elemA: A, elemB: B | null, keyB?: keyof B) => C
): Array<C> => {
  const fKeyA = keyA instanceof Function ? keyA : () => keyA;
  const fKeyB = keyB instanceof Function ? keyB : () => keyB;
  const tmp = mapArrayToObject(arrayB, (entry) => [
    entry[fKeyB(entry)] as string,
    entry,
  ]);
  return arrayA.map((entryA) => {
    const entryB = tmp[entryA[fKeyA(entryA)] as string];
    return combineRecords(entryA, entryB, fKeyB(entryB));
  });
};

export const manyToOneleftJoin = <A, B, C>(
  arrayA: Array<A>,
  arrayB: Array<B>,
  keyA: keyof A | ((elemA: A) => keyof A),
  keyB: keyof B | ((elemB: B | null) => keyof B),
  combineRecords: (elemA: A, elemB: (B | null)[], keyB?: (keyof B)[]) => C
): Array<C> => {
  const fKeyA = keyA instanceof Function ? keyA : () => keyA;
  const fKeyB = keyB instanceof Function ? keyB : () => keyB;
  const tmp = mapArrayToObject(arrayB, (entry) => [
    entry[fKeyB(entry)] as string,
    entry,
  ]);
  return arrayA.map((entryA) => {
    const keys = entryA[fKeyA(entryA)] as string[] | undefined;
    const entries: B[] = [];
    for (const key of keys ?? []) {
      if (Object.hasOwn(tmp, key)) {
        entries.push(tmp[key]);
      }
    }
    return combineRecords(
      entryA,
      entries,
      entries.map((entry) => fKeyB(entry))
    );
  });
};

export const leftJoinPipe = <A, B, C>(
  pipeA: DataPipe<A[]>,
  pipeB: DataPipe<B[]>,
  keyA: keyof A | ((elemA: A) => keyof A),
  keyB: keyof B | ((elemB: B | null) => keyof B),
  combineRecords: (elemA: A, elemB: B | null, keyB?: keyof B) => C
) =>
  combinePipesAsObject({ arrayA: pipeA, arrayB: pipeB }).pipe(
    ({ arrayA, arrayB }) => leftJoin(arrayA, arrayB, keyA, keyB, combineRecords)
  );
