/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import {
  AskForSupportMessage,
  ErrorMessageDescription,
} from "../../framework/default-error-message/default-error-message.front.tsx";
import { MessageBuilderType } from "../../framework/default-error-message/message-builder-type.front.ts";
import { AuthErrorCodes } from "firebase/auth";

type MessagesType = {
  [prop: string]: MessageBuilderType;
};

const knownFirebaseErrors: MessagesType = {
  // for firebase @ 9.8.1. Should be kept updated. See
  // https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/src/core/errors.ts
  [AuthErrorCodes.CORS_UNSUPPORTED]: {
    message: "El navegador no está soportado.",
  },
  [AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE]: {
    message: <>Ya existe un usuario con estas credenciales.</>,
  },
  [AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN]: {
    message: (
      <>
        No pudimos reautenticar tu usuario. Podés probar volver a conectarte e
        intentar nuevamente. <AskForSupportMessage />
      </>
    ),
  },
  [AuthErrorCodes.USER_DELETED]: {
    // auth/user-not-found
    message: <>El usuario no existe.</>,
  },
  [AuthErrorCodes.EMAIL_EXISTS]: {
    message: <>Ya existe un usuario con este email.</>,
  },
  "auth/email-already-exists": {
    // this error exists but does not show up in AuthErrorCodes
    message: <>Ya existe un usuario con este email.</>,
  },
  [AuthErrorCodes.INVALID_EMAIL]: { message: "El mail provisto es inválido." },
  [AuthErrorCodes.INVALID_PASSWORD]: {
    message: "La contraseña es incorrecta.",
  },
  [AuthErrorCodes.NETWORK_REQUEST_FAILED]: {
    message: (
      <>
        Hubo un error de red, por favor volvé a intentar.{" "}
        <AskForSupportMessage />
      </>
    ),
  },
  [AuthErrorCodes.TIMEOUT]: {
    message: (
      <>
        El servidor tardó mucho en responder, por favor volvé a intentar.{" "}
        <AskForSupportMessage />
      </>
    ),
  },
  [AuthErrorCodes.TOKEN_EXPIRED]: {
    message: <>Tu sesión expiró, por favor volvé a conectarte.</>,
  },
  [AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER]: {
    message: (
      <>
        Se bloqueó la conexión desde este dispositivo por actividad sospechosa.
        Probá de nuevo más tarde.{" "}
        <AskForSupportMessage askConditional="crees que es un error" />
      </>
    ),
  },
  [AuthErrorCodes.UNVERIFIED_EMAIL]: {
    message: (
      <>
        La operación solicitada requiere un mail verificado.{" "}
        <AskForSupportMessage />
      </>
    ),
  },
  [AuthErrorCodes.USER_DISABLED]: {
    message: (
      <>
        El usuario está deshabilitado.{" "}
        <AskForSupportMessage askConditional="crees que es un error" />
      </>
    ),
  },
  [AuthErrorCodes.WEAK_PASSWORD]: {
    message:
      "La contraseña propuesta es muy débil. Por favor, intentá con otra.",
  },
  [AuthErrorCodes.WEB_STORAGE_UNSUPPORTED]: {
    message:
      "El navegador no está soportado o tiene deshabilitado el almacenamiento interno.",
  },
};

let authErrorMessageMap: MessagesType;

const buildAuthErrorMessageMap = () => {
  const allFirebaseErrors = mapArrayToObject(
    Object.values(AuthErrorCodes),
    (code) => [code, {}]
  );
  authErrorMessageMap = Object.assign(allFirebaseErrors, knownFirebaseErrors); // add missing errors
};

export const getAuthErrorMessageProps = ({
  error,
  customErrorMap = {},
  captureUnknownErrors = false,
}: {
  error: { code?: string };
  customErrorMap?: MessagesType;
  captureUnknownErrors?: boolean;
}) => {
  if (!authErrorMessageMap) {
    buildAuthErrorMessageMap();
  }
  const { code } = error;
  if (!code) {
    throw new Error("noCodeInError", { cause: error });
  }
  const authErrorMessage = authErrorMessageMap[code];
  const customErrorMessage = customErrorMap[code];
  if (
    !code &&
    !authErrorMessage &&
    !customErrorMessage &&
    !captureUnknownErrors
  ) {
    throw error;
  }
  const builder = Object.assign(
    authErrorMessageMap[code] ?? {},
    customErrorMap[code] ?? {}
  );
  return { code, builder };
};

export const AuthErrorMessage = ({
  builder,
  code,
}: {
  builder: MessageBuilderType;
  code: string;
}) => <ErrorMessageDescription builder={builder} code={code} />;
