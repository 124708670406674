/* DON'T EDIT THIS FILE: edit original and run build again */ import { AfterLabelRenderer } from "../../../credit/live-form/ui/after-label-renderer.tsx";
import { useLiveImmediateInput } from "../../../credit/live-form/ui/live-immediate-input.tsx";
import {
  LiveFieldInput,
  LiveFieldInputParam,
  LiveFieldRenderer,
  LiveFieldViewer,
  LiveFieldViewerParam,
} from "../../../credit/live-form/ui/types.ts";
import { Checkbox } from "../../../credit/ui/form/checkbox.tsx";
import { NullableCheckbox } from "../../../credit/ui/form/nullable-checkbox.tsx";
import { Div } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { BooleanLiveField } from "./live-field.ts";
import { booleanLiveFieldType } from "./type.ts";

const createCheckboxLabel = (
  liveField: BooleanLiveField,
  fieldInfo: LiveFieldInputParam | LiveFieldViewerParam
) => {
  const { liveCell, Widgets } = fieldInfo;
  const LabelWidget = liveField.getCheckboxLabel();
  if (LabelWidget) {
    return (
      <AfterLabelRenderer
        AfterLabel={LabelWidget}
        Widgets={Widgets}
        liveData={liveCell.getLiveData()}
      />
    );
  }
  return null;
};

const LiveBooleanInput: LiveFieldInput = (fieldInfo) => {
  const { liveCell } = fieldInfo;
  const liveField = liveCell.getLiveField();
  if (!(liveField instanceof BooleanLiveField)) {
    throw new Error(
      "invalidLiveFieldType: " +
        liveField?.constructor?.name +
        " is not a BooleanLiveField"
    );
  }
  const {
    value,
    setValue,
    className = "",
    autoFocus,
  } = useLiveImmediateInput(fieldInfo);
  if (liveField.getVariant() === "simple") {
    return (
      <label
        className={
          "d-flex flex-gap-2 align-items-start mb-4 align-items-center" +
          className
        }
      >
        <Checkbox
          autoFocus={autoFocus}
          value={value ?? false}
          setValue={setValue}
          className="flex-shrink-0 mt-1 mb-3"
        />
        <div>{createCheckboxLabel(liveField, fieldInfo)}</div>
      </label>
    );
  } else {
    return (
      <NullableCheckbox
        autoFocus={autoFocus}
        value={value ?? null}
        setValue={setValue}
        className={className}
        yesLabel={liveField.getYesLabel()}
        noLabel={liveField.getNoLabel()}
      />
    );
  }
};

const Viewer: LiveFieldViewer = (fieldInfo) => {
  const { liveCell } = fieldInfo;
  const value = liveCell.getValue();
  if (value === null) {
    return null;
  }
  const liveField = liveCell.getLiveField() as BooleanLiveField;
  return (
    <Div>
      {createCheckboxLabel(liveField, fieldInfo)}
      {value ? liveField.getYesLabel() : liveField.getNoLabel()}
    </Div>
  );
};

export const booleanLiveFieldRenderers: LiveFieldRenderer = {
  type: booleanLiveFieldType,
  Input: LiveBooleanInput,
  Viewer,
};
