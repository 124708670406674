/* DON'T EDIT THIS FILE: edit original and run build again */ import { Div, Span } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { BaseTextLiveField } from "./base-text-live-field-spec.tsx";
import { LiveFieldViewer } from "./types.ts";

const TextViewer = ({
  value = "",
  suffix = "",
}: {
  value: string;
  suffix: string;
}) => (
  <Div>
    <Span>
      {value}
      {suffix}
    </Span>
  </Div>
);

export const LiveTextViewer: LiveFieldViewer = ({ liveCell }) => {
  const liveField = liveCell.getLiveField();
  if (!(liveField instanceof BaseTextLiveField)) {
    throw new Error("LiveTextViewer: expected BaseTextLiveField");
  }
  return (
    <TextViewer
      value={liveCell.getValue() as string}
      suffix={liveField.getSuffix()}
    />
  );
};
