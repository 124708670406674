/* DON'T EDIT THIS FILE: edit original and run build again */ import { useCallback } from "react";
import { ToggleableRadioButton } from "./toggleable-radio-button.tsx";

const getRegexpMatcher = ({
  source,
  flags,
}: {
  source: string;
  flags: string;
}) => {
  const regexp = new RegExp(source, flags);
  return (str: string) => {
    return regexp.test(str);
  };
};

const getStrMatcher = (str1: string) => (str2: string) => str1 === str2;

const searchOption = (
  options: Record<string, string>,
  selectedLabelOrNull: any
) => {
  const matches =
    selectedLabelOrNull.$jsonType === "regexp"
      ? getRegexpMatcher(selectedLabelOrNull)
      : getStrMatcher(selectedLabelOrNull);
  for (const [id, label] of Object.entries(options)) {
    if (matches(label)) {
      return id;
    }
  }
  return null;
};

export const RadioButtonsInput = ({
  setValue,
  value,
  options,
  className = "",
  disabled = false,
  autoFocus = false,
  nullable,
}: {
  setValue: (value: string | null) => void;
  value: string | null;
  options: Record<string, string>;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  nullable: boolean;
}) => {
  const containerRef = useCallback(
    (node: any) => {
      if (node) {
        // for tests
        node.setInputValueForTests = (selectedLabelOrNull: any) => {
          if (selectedLabelOrNull === null) {
            setValue(null);
            return null;
          } else {
            const id = searchOption(options, selectedLabelOrNull);
            if (!id) {
              return "noLabelMatchesValue";
            }
            setValue(id);
            return null;
          }
        };
      }
    },
    [setValue, options]
  );

  return (
    <div className={className} data-is-input ref={containerRef}>
      {Object.entries(options).map(([id, label], index) => {
        const checked = value === id;
        const toggleId = () =>
          checked ? (nullable ? setValue(null) : null) : setValue(id);
        return (
          <div key={id} className="d-flex">
            <div className="flex-grow-0">
              <ToggleableRadioButton
                value={checked}
                setValue={toggleId}
                disabled={disabled}
                autoFocus={autoFocus && index === 0}
              />
            </div>
            <div
              className="px-2 flex-grow-1 cursor-pointer"
              onClick={() => (disabled ? null : toggleId())}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            >
              {label as string}
            </div>
          </div>
        );
      })}
    </div>
  );
};
