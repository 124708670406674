/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../../framework/html-editor/button/command-button.tsx";
import { RedoIcon } from "../../../framework/theme-icons/icon.ts";
import { useCommands, useHelpers } from "@remirror/react";
import { useCallback } from "react";

export const RedoButton = () => {
  const { redo } = useCommands();
  const { redoDepth } = useHelpers(true);

  const handleAction = useCallback(() => {
    if (redo.enabled()) {
      redo();
    }
  }, [redo]);

  const enabled = redoDepth() > 0;

  return (
    <CommandButton
      commandName="redo"
      active={false}
      enabled={enabled}
      onAction={handleAction}
    >
      <RedoIcon />
    </CommandButton>
  );
};
