/* DON'T EDIT THIS FILE: edit original and run build again */ export const ARS = "ARS";
export const USD = "USD";
export const EUR = "EUR";
export const BRL = "BRL";

export type Currency = typeof ARS | typeof USD | typeof EUR | typeof BRL;

export const localCurrencyTypes = {
  [ARS]: "Pesos",
  [USD]: "Dólares",
};

export const currencyTypes: { [currency: string]: string } = {
  ...localCurrencyTypes,
  [EUR]: "Euros",
  [BRL]: "Reales brasileños",
};

export const getCurrencyLabel = (currency: string) => currencyTypes[currency];
