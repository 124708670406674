/* DON'T EDIT THIS FILE: edit original and run build again */ import { BankTenantHandler } from "../../../framework/tenant-handlers/bank-tenant-handler.ts";
import { bancocorrientesTenant } from "./code.ts";
import { bancocorrientesFullLabel } from "./full-label.ts";
import { bancocorrientesLabel } from "./label.ts";

class BancocorrientesTenantHandler extends BankTenantHandler {
  getCode(): string {
    return bancocorrientesTenant;
  }

  getPathLabel(): string | null {
    return "banco-de-corrientes";
  }

  getLabel(): string {
    return bancocorrientesLabel;
  }

  getFullLabel(): string {
    return bancocorrientesFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-50001060-2";
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const bancocorrientesTenantHandler = new BancocorrientesTenantHandler();

export default bancocorrientesTenantHandler;
