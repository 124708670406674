/* DON'T EDIT THIS FILE: edit original and run build again */ import { dniSeparator } from "../../../credit/gov-id-number/dni.ts";
import { useEffect, useRef } from "react";

const handleChange = ({
  input,
  newValue,
  setValue,
}: {
  input: HTMLInputElement;
  newValue: string;
  setValue: (value: string) => void;
}) => {
  const startPattern = /[MmFfTt0-9]/;
  const restPattern = /[0-9]/;
  const inputSelectionStart = input.selectionStart ?? 0;
  let newInputSelectionStart = 0;
  let validPartialValue = "";
  for (const [i, char] of [...newValue].entries()) {
    if (
      validPartialValue.length < 8 &&
      (validPartialValue.length ? restPattern : startPattern).test(char)
    ) {
      validPartialValue += char;
      if (i < inputSelectionStart) {
        newInputSelectionStart += 1;
      }
    }
  }
  const parts = [
    validPartialValue.substring(0, 2),
    validPartialValue.substring(2, 5),
    validPartialValue.substring(5),
  ].filter((part) => !!part);
  const formattedValue = parts.join(dniSeparator).toUpperCase();
  setValue(formattedValue);
  input.value = formattedValue;
  input.selectionStart =
    newInputSelectionStart +
    (newInputSelectionStart >= 5 ? 2 : newInputSelectionStart >= 2 ? 1 : 0);
  input.selectionEnd = input.selectionStart;
};

export const DniFormControl = ({
  value,
  setValue,
  className = "",
  onKeyPress,
  autoFocus,
  onFocus,
  onBlur,
  id,
}: {
  value: string;
  setValue: (value: string) => void;
  className?: string;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  id?: string;
}) => {
  const inputRef = useRef(null as any);
  useEffect(() => {
    const actualValue = value ?? "";
    if (inputRef.current) {
      inputRef.current.value = actualValue;
    }
  }, [value]);
  return (
    <input
      type="text"
      className={"form-control " + className}
      ref={inputRef}
      onChange={(event) =>
        handleChange({
          newValue: event.target.value,
          setValue,
          input: inputRef.current,
        })
      }
      value={value ?? ""}
      onKeyPress={onKeyPress}
      autoFocus={autoFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id}
    />
  );
};
