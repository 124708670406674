/* DON'T EDIT THIS FILE: edit original and run build again */ import { aboutPagePath } from "../../framework/about/about-page-path.ts";
import { useSettings } from "../../framework/app-state/app-state-context.ts";
import { getConfig } from "../../framework/config/config.ts";
import { useLocation } from "react-router-dom";
import { MaintenancePage } from "./maintenance-page.front.tsx";
import { MustUpdatePage } from "./must-update-page.front.tsx";

export const MaintenanceWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { pathname } = useLocation();
  const settings = useSettings();
  const config = getConfig();

  if (pathname !== aboutPagePath) {
    // about page is not in tenant
    if (
      config.requireUiVersionToMatchDb &&
      settings?.schemaVersion !== config.version
    ) {
      return <MustUpdatePage />;
    }
    if (settings?.maintenance) {
      return <MaintenancePage />;
    }
  }
  return <>{children}</>;
};
