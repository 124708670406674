/* DON'T EDIT THIS FILE: edit original and run build again */ import { ColorPalette } from "../../../framework/tenant-handler-type/color-pallete-type.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { inverstartTenant } from "./code.ts";
import { inverstartFullLabel } from "./full-label.ts";
import { inverstartOnboardingOptions } from "./inverstart-onboarding-options.ts";
import { inverstartLabel } from "./label.ts";

class InverstartTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_inverstart.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return inverstartTenant;
  }

  getPathLabel(): string | null {
    return "inverstart";
  }

  getLabel(): string {
    return inverstartLabel;
  }

  getFullLabel(): string {
    return inverstartFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return inverstartOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@inverstart.com";
  }

  getComplianceEmailAddress(): string | null {
    return "administracion@inverstart.com";
  }

  getCuit(): string {
    return "30-71546684-4";
  }

  getCnvRegistryId(): string | null {
    return "746";
  }

  getColors(): ColorPalette {
    return {
      primary: "#2C5697",
      secondary: "#1a345b",
      tertiary: "#2C5697",
    };
  }
}

const inverstartTenantHandler = new InverstartTenantHandler();

export default inverstartTenantHandler;
