/* DON'T EDIT THIS FILE: edit original and run build again */ import { DataPipe } from "./data-pipe.ts";
import { joinPipe } from "./join-pipe.ts";

/**
 * Combine the pipes of childPipes into a single DataPipe with an array with the values of the pipes
 *
 * @param {Array} childPipes: an array of pipes to combine (or falsy to skip)
 * @returns {DataPipe} a pipe that outputs an array of the values of the pipes
 */
export const combinePipesAsArray = <T = any>( // until properly typed
  childPipes: DataPipe<T>[],
  ignoreErrors: boolean = false
): DataPipe<T[]> => {
  const filteredChildPipes = childPipes.filter((pipe) => pipe);
  return joinPipe(
    DataPipe.withInitialData(
      () => filteredChildPipes.forEach((pipe) => pipe.delete()),
      filteredChildPipes
    ),
    (pipe) => pipe,
    (_, pipeData) => pipeData,
    "",
    ignoreErrors
  );
};
