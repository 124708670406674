/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  LiveFieldConditionCallback,
  LiveFieldConditionParams,
} from "./types.ts";

export const andConditions =
  (...conditions: LiveFieldConditionCallback[]): LiveFieldConditionCallback =>
  (param: LiveFieldConditionParams) => {
    for (const condition of conditions) {
      if (condition) {
        const result = condition(param);
        if (!result) {
          return false;
        }
      }
    }
    return true;
  };

export const orConditions =
  (...conditions: LiveFieldConditionCallback[]): LiveFieldConditionCallback =>
  (param: LiveFieldConditionParams) => {
    for (const condition of conditions) {
      if (condition) {
        const result = condition(param);
        if (result) {
          return true;
        }
      }
    }
    return false;
  };

export const notCondition =
  (condition: LiveFieldConditionCallback) =>
  (param: LiveFieldConditionParams) =>
    !condition(param);
