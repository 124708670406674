/* DON'T EDIT THIS FILE: edit original and run build again */ import { uruguayCountryCode } from "../../../framework/country/country-codes.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { ComponentType, SVGProps } from "react";
import { lamTenant } from "./code.ts";
import { lamFullLabel } from "./full-label.ts";
import { lamOnboardingOptions } from "./lam-onboarding-options.ts";

class LamTenantHandler extends InvestorTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_LAM.svg?react")).default;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return lamOnboardingOptions;
  }

  getCode(): string {
    return lamTenant;
  }

  getPathLabel(): string | null {
    return "lam";
  }

  getLabel(): string {
    return "LAM";
  }

  getFullLabel(): string {
    return lamFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "compliance@lam.com.ar";
  }

  getCuit(): string | null {
    return null;
  }

  getTenantCompanyCountry(): string {
    return uruguayCountryCode;
  }

  getTenantCompanyForeignIdNumber(): string {
    return "215954650019";
  }

  supportsDomesticBankAccounts(): boolean {
    return false;
  }

  supportsBcraBankAccounts(): boolean {
    return false;
  }
}

const lamTenantHandler = new LamTenantHandler();

export default lamTenantHandler;
