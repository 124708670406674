/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";

class CuyoOnboardingOptions extends BaseOnboardingOptions {
  hasSignatura(): boolean {
    return true;
  }

  hasRevenueVolume(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasSwornStatement(): boolean {
    return true;
  }

  useSnailMailViaEmail(): boolean {
    return false;
  }

  getEmailAfterLabelText(): string | null {
    return null;
  }
}

export const cuyoOnboardingOptions = new CuyoOnboardingOptions();
