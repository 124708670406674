/* DON'T EDIT THIS FILE: edit original and run build again */ import { InputElement } from "../../../credit/ui/form/input-element.tsx";
import { EyeIcon } from "../../../framework/theme-icons/icon.ts";
import { gray40, tenantColors } from "../../../framework/theme/custom-colors.ts";
import { useState } from "react";
import { InputGroup } from "react-bootstrap";

const ViewButton = ({
  isHidden,
  onClick,
  className = "",
}: {
  isHidden: boolean;
  className?: string;
  onClick: React.MouseEventHandler<HTMLLabelElement>;
}) => (
  <label
    className={"input-group-text cursor-pointer " + className}
    onClick={onClick}
  >
    <EyeIcon
      size={25}
      style={{
        fill: isHidden ? gray40 : tenantColors.primary,
      }}
    />
  </label>
);

export const PasswordInput = ({
  id,
  value,
  setValue,
  disabled,
  autoFocus,
  className,
  autoComplete,
  placeholder,
  onKeyDown,
}: {
  id?: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
  autoComplete?: string;
  placeholder?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}): React.ReactNode => {
  const [hide, setHide] = useState(true);
  return (
    <>
      <InputGroup>
        <InputElement
          {...{
            type: hide ? "password" : "",
            id,
            value,
            setValue,
            disabled,
            autoFocus,
            className,
            autoComplete,
            placeholder,
            onKeyDown,
          }}
        />
        <ViewButton
          onClick={() => setHide(!hide)}
          isHidden={hide}
          className={className}
        />
      </InputGroup>
    </>
  );
};
