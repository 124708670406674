/* DON'T EDIT THIS FILE: edit original and run build again */ import { pathUpdate } from "../../framework/dotpath/path.ts";
import { LiveFieldset } from "../../credit/live-form/fieldset.ts";
import { LiveRecord } from "../../credit/live-form/live-record.ts";
import { LiveData } from "../../credit/live-form/types.ts";
import { RawLiveForm } from "../../credit/live-form/ui/raw-live-form.tsx";
import { LiveFieldGetLiveFormInputFactory } from "../../credit/live-form/ui/types.ts";
import clone from "just-clone";

const doNothing = () => undefined;

export const EntitionEditor = ({
  getLiveFormInputFactory,
  fieldset,
  entityId,
  liveData,
  showErrors = undefined,
  disabled = undefined,
  Widgets = undefined,
  viewOnly = undefined,
  onAfterEdit = doNothing,
}: {
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
  fieldset: LiveFieldset;
  entityId: string;
  liveData: LiveData;
  showErrors?: boolean;
  disabled?: boolean;
  Widgets?: Record<string, any>;
  viewOnly?: boolean;
  onAfterEdit?: () => void | Promise<void>;
}) => {
  const { baseFolder, specificFolder, inlineEntityHandler } = liveData;
  if (!inlineEntityHandler) {
    throw new Error("inlineEntityHandlerIsRequired");
  }
  const recordValue = inlineEntityHandler.getInlineEntityById(entityId) ?? {};
  return (
    <RawLiveForm
      getLiveFormInputFactory={getLiveFormInputFactory}
      liveRecord={
        new LiveRecord(
          fieldset,
          {
            viewOnly,
            baseFolder,
            specificFolder,
            recordValue,
            inlineEntityHandler,
          },
          showErrors,
          !entityId || disabled
        )
      }
      saveField={async (field, newFieldValue) => {
        const newInlineEntity = clone(recordValue);
        pathUpdate(newInlineEntity, field, newFieldValue);
        await inlineEntityHandler.upsertInlineEntity(newInlineEntity);
        await onAfterEdit();
      }}
      Widgets={Widgets}
      allowDefaultValue={false}
    />
  );
};
