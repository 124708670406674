/* DON'T EDIT THIS FILE: edit original and run build again */ export const passwordSetterDefaultDisallowedValues = ["matchfin"];

export const extendUserInputs = (inputs: string[]) => {
  if (!inputs) {
    return passwordSetterDefaultDisallowedValues;
  }
  const splitInputs = inputs.map((value) => value.split(/\W+/)).flat();
  const joinedInputs = inputs.map((value) => value.replace(/\W+/g, ""));
  return [
    ...new Set([
      ...inputs,
      ...splitInputs,
      ...joinedInputs,
      ...passwordSetterDefaultDisallowedValues,
    ]),
  ];
};
