/* DON'T EDIT THIS FILE: edit original and run build again */ import { Jsonable } from "../../framework/core/jsonable.ts";

type ConfigGetter = () => Jsonable;
let configGetter: ConfigGetter;
let read = false;
let config: Jsonable | null = null;

export const setConfigGetter = (newConfigGetter: ConfigGetter) => {
  resetConfig();
  configGetter = newConfigGetter;
};

export const runWithConfigGetter = async (
  newConfigGetter: ConfigGetter,
  callback: () => void | Promise<void>
) => {
  const oldGetter = configGetter;
  setConfigGetter(newConfigGetter);
  try {
    return await callback();
  } finally {
    setConfigGetter(oldGetter);
  }
};

export const withConfigGetter =
  (newConfigGetter: ConfigGetter, callback: () => void | Promise<void>) => () =>
    runWithConfigGetter(newConfigGetter, callback);

export const getConfig: any = () => {
  if (!read) {
    config = configGetter();
    read = true;
  }
  return config;
};

export const resetConfig = () => {
  read = false;
  config = null;
};
