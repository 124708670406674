/* DON'T EDIT THIS FILE: edit original and run build again */ import firebase from "firebase/compat/app";
import { getLoggedFirebaseUser } from "../../framework/firebase/get-logged-user.ts";

const reauthenticate = async (password: string) => {
  const user = getLoggedFirebaseUser();
  // @ts-expect-error bad types
  const credential = await firebase.auth.EmailAuthProvider.credential(
    user?.email,
    password
  );
  await user.reauthenticateWithCredential(credential);
};

export const changePassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  await reauthenticate(currentPassword);
  const user = getLoggedFirebaseUser();
  await user.updatePassword(newPassword);
};
