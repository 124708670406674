/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../../framework/attachment-type/attachment-ref-type.ts";
import {
  getAttachmentDownloadUrl,
  getAttachmentOriginalName,
} from "../../framework/attachment/attachment-storage.ts";
import {
  DeleteForeverIcon,
  FileBlankOutlineIcon,
} from "../../framework/theme-icons/icon.ts";
import { useState } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";
import { downloadUrl } from "./download-url.ts";
import { getDownloadAttachmentErrorMessage } from "./get-download-attachment-error-message.ts";
import { ImagePreview } from "./image-preview.tsx";
import { SavingFileInputButton } from "./saving-file-input-button.tsx";

export const downloadAttachment = async (attachment: AttachmentRef) => {
  const url = await getAttachmentDownloadUrl(attachment);
  const fileName = getAttachmentOriginalName(attachment);
  await downloadUrl(url, fileName);
};

const assertValidAttachment = (attachment: AttachmentRef) => {
  if (!(attachment.type === "attachmentRef") || !attachment.id) {
    throw new Error("invalidAttachment");
  }
};

export const DownloadFileButton = ({
  attachment,
  children,
  className = "",
}: {
  attachment: AttachmentRef;
  children: React.ReactNode;
  className?: string;
}) => {
  if (attachment) {
    assertValidAttachment(attachment);
  }
  const [busy, setBusy] = useState(false);
  return (
    <Button
      className={"p-0 " + className}
      disabled={busy}
      variant="link"
      onClick={async () => {
        setBusy(true);
        try {
          await downloadAttachment(attachment);
        } catch (e) {
          alert(getDownloadAttachmentErrorMessage(e.code));
        }
        setBusy(false);
      }}
    >
      {busy ? (
        <Spinner
          size="sm"
          animation="border"
          variant="gray40"
          className="me-1"
          children=""
        />
      ) : null}
      {children}
    </Button>
  );
};

export const AttachmentViewer = ({
  value,
  downloadLabel = "Descargar",
  showPreview,
  extraButtons,
}: {
  value: AttachmentRef;
  downloadLabel?: string;
  showPreview: boolean;
  extraButtons?: React.ReactNode;
}) => {
  return (
    <>
      {showPreview ? (
        <ImagePreview
          className="mb-3"
          attachment={value}
          onClick={(event) => event.preventDefault()}
        />
      ) : null}
      <div className="d-flex flex-gap-4">
        <DownloadFileButton attachment={value}>
          <FileBlankOutlineIcon size={18} className="me-2" />
          {downloadLabel}
        </DownloadFileButton>
        {extraButtons}
      </div>
    </>
  );
};

const AttachmentDeleteButton = ({
  deleteLabel,
  onDeleteClick,
}: {
  deleteLabel: React.ReactNode;
  onDeleteClick: () => void;
}) => {
  return (
    <Button variant="link" className="text-warning p-0" onClick={onDeleteClick}>
      <DeleteForeverIcon className="me-2 icon-3" />
      {deleteLabel}
    </Button>
  );
};

export const FileInput = ({
  value = null,
  onChange,
  setValue,
  onUploadingChange,
  uploadLabel,
  canDelete = true,
  deleteLabel = "Eliminar",
  downloadLabel,
  autoFocus = false,
  uid,
  variant = "outline-blue50",
  size,
  showPreview = false,
  className,
}: {
  value?: AttachmentRef | null;
  onChange?: (attachment: AttachmentRef | null) => void;
  setValue?: (attachment: AttachmentRef | null) => void;
  onUploadingChange?: (uploading: boolean) => void;
  uploadLabel: string;
  canDelete?: boolean;
  deleteLabel?: string;
  downloadLabel?: string;
  autoFocus?: boolean;
  uid: string;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  showPreview?: boolean;
  className?: string;
}) => {
  if (value) {
    assertValidAttachment(value);
  }
  const [uploading, setUploading] = useState(false);
  const updateUploading = (uploading: boolean) => {
    if (onUploadingChange) {
      onUploadingChange(uploading);
    }
    setUploading(uploading);
  };
  const [savedAttachment, setSavedAttachment] = useState(value);
  if (!uid) {
    throw new Error("missingUid");
  }
  const setNewAttachment = (newAttachment: AttachmentRef | null) => {
    if (onChange) {
      onChange(newAttachment);
    }
    if (setValue) {
      setValue(newAttachment);
    }
    setSavedAttachment(newAttachment);
  };

  return (
    <div className={className}>
      {(() => {
        if (uploading) {
          return <Spinner animation="border" variant="gray40" children="" />;
        } else if (savedAttachment) {
          return (
            <AttachmentViewer
              value={savedAttachment}
              downloadLabel={downloadLabel}
              showPreview={showPreview}
              extraButtons={
                canDelete ? (
                  <AttachmentDeleteButton
                    deleteLabel={deleteLabel}
                    onDeleteClick={() => setNewAttachment(null)}
                  />
                ) : undefined
              }
            />
          );
        } else {
          return (
            <SavingFileInputButton
              size={size}
              onUpload={(attachments) => {
                setNewAttachment(attachments[0]);
              }}
              onUploadingChange={updateUploading}
              uploadLabel={uploadLabel}
              autoFocus={autoFocus}
              uid={uid}
              variant={variant}
            />
          );
        }
      })()}
    </div>
  );
};
