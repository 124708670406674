/* DON'T EDIT THIS FILE: edit original and run build again */ import { fetchDoc } from "../../framework/firebase/fetch-doc.ts";
import { createFirestoreCollection } from "../../framework/firebase/firestore-wrappers.ts";
import { PrivateProfile, UserPrivateProfile } from "./schema.ts";

export const getPrivateProfileCol = () =>
  createFirestoreCollection<PrivateProfile>("privateProfiles");

export const getPrivateProfileDocument = (uid: string) =>
  getPrivateProfileCol().doc(uid);

export const fetchPrivateProfile = async (
  uid: string
): Promise<UserPrivateProfile> =>
  (await fetchDoc(getPrivateProfileDocument(uid), "uid")) ?? {
    uid,
    emailVerify: false,
  };
