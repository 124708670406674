/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../../framework/attachment-type/attachment-ref-type.ts";
import { getAttachmentDownloadUrl } from "../../framework/attachment/attachment-storage.ts";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getDownloadAttachmentErrorMessage } from "./get-download-attachment-error-message.ts";

const previewHeight = 300;

const ImagePreviewContent = ({
  attachment,
  className,
  onClick,
}: {
  attachment: AttachmentRef;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const [attachmentUrl, setAttachmentUrl] = useState(
    undefined as string | undefined
  );
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getAttachmentDownloadUrl(attachment)
      .then((url) => {
        setAttachmentUrl(url);
      })
      .catch((e) => {
        setError(e?.code);
      });
  }, [attachment]);

  return (
    <div className={className} onClick={onClick}>
      <Modal
        size="xl"
        animation={false}
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <img
            alt=""
            src={attachmentUrl}
            style={{
              width: "100%",
            }}
            className="d-block"
          />
        </Modal.Body>
      </Modal>
      {attachmentUrl && !error ? (
        <img
          alt=""
          src={attachmentUrl}
          onClick={() => setShowModal(true)}
          style={{
            maxWidth: "100%",
            maxHeight: previewHeight + "px",
          }}
          className="d-block cursor-pointer"
        />
      ) : !error ? (
        <Spinner animation="border" variant="gray40" children="" />
      ) : (
        <span>{getDownloadAttachmentErrorMessage(error)}</span>
      )}
    </div>
  );
};

const hasImageExtension = (path: string) =>
  /\.(?:gif|png|jpg|jpeg|webp|svg)$/i.test(path);

export const ImagePreview = ({
  attachment,
  className,
  onClick,
}: {
  attachment: AttachmentRef;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  if (hasImageExtension(attachment.id)) {
    return (
      <ImagePreviewContent
        attachment={attachment}
        className={className}
        onClick={onClick}
      />
    );
  } else {
    return null;
  }
};
