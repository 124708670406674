/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  canAccessReceivedLines,
  canAccessReceivedLinesInNonFciTenants,
} from "../../framework/permission/permissions.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { TenantHandler } from "../../framework/tenant-handler-type/tenant-handler.ts";
import { investorUserCompanyTypeId } from "../../framework/user-company-type-const/user-company-type-const.ts";

const canAccessInboxCondition = (
  roles: RolesMap,
  tenantHandler: TenantHandler
) =>
  userHasPermission(roles, canAccessReceivedLines) ||
  (userHasPermission(roles, canAccessReceivedLinesInNonFciTenants) &&
    tenantHandler.getUserCompanyType() !== investorUserCompanyTypeId);

export default canAccessInboxCondition;
