/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveFieldRenderer } from "../../credit/live-form/ui/types.ts";

export const liveFieldRenderers: Record<string, LiveFieldRenderer> = {};

export const registerLiveFieldRenderer = (renderer: LiveFieldRenderer) => {
  const type = renderer.type;
  if (liveFieldRenderers[type]) {
    throw new Error("liveFormFieldRendererAlreadyDeclared: " + type);
  }
  liveFieldRenderers[type] = renderer;
};
