/* DON'T EDIT THIS FILE: edit original and run build again */ export const concatGetAttachmentsLabel = (
  a: string | undefined,
  b: string | undefined
) => {
  if (a && b) {
    return a + " » " + b;
  } else {
    return (a ?? "") + (b ?? "");
  }
};

export const concatGetAttachmentsKey = (
  a: string | undefined,
  b: string | undefined
) => (a ? a + "." : "") + b;
