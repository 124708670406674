/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseFolder } from "../../credit/base-folder/base-folder-type.ts";
import { useCuitInputState } from "../../credit/cuit-input/cuit-input-state.ts";
import { InlineEntity } from "../../credit/entity/inline-entity-type.ts";
import {
  getInlineEntityError,
  isInlineEntityEmpty,
} from "../../credit/entity/inline-entity.ts";
import { EntityType } from "../../credit/gov-id-number/entity-type.ts";
import deepEqual from "fast-deep-equal";
import { useEffect, useState } from "react";

const buildDefaultForeignValue = (
  allowEntityTypes = EntityType.Any,
  {
    foreignIdNumber = "",
    country = "",
    dateOfBirth = "",
    firstName = "",
    lastName = "",
    entityName = "",
    isCompany = allowEntityTypes === EntityType.OnlyCompanies ? true : false,
  }
): Partial<InlineEntity> => {
  if (allowEntityTypes === EntityType.OnlyHumans && isCompany) {
    throw new Error("inconsistentInput: cannotBeCompany");
  } else if (allowEntityTypes === EntityType.OnlyCompanies && !isCompany) {
    throw new Error("inconsistentInput: cannotBeHuman");
  }
  return {
    foreignIdNumber,
    country,
    dateOfBirth,
    firstName,
    lastName,
    entityName,
    isCompany,
  };
};

export const buildInlineEntityDefaultValue = ({
  isForeign = false,
  allowEntityTypes = EntityType.Any,
  initialValue = {},
}) => {
  if (isForeign) {
    return buildDefaultForeignValue(allowEntityTypes, initialValue);
  } else {
    return buildDefaultDomesticValue(initialValue);
  }
};

const buildDefaultDomesticValue = ({
  cuit = "",
  firstName = "",
  lastName = "",
  entityName = "",
}): Partial<InlineEntity> => {
  return {
    cuit,
    firstName,
    lastName,
    entityName,
  };
};

type InlineEntityOptions = {
  includeName: boolean;
  includeExtraNameFields: boolean;
  includeDateOfBirth: boolean;
  allowEntityTypes: EntityType;
  dateOfBirthRequired: boolean;
  required: boolean;
  validate: boolean;
  allowForeignEntities: boolean;
  allowDomesticEntities: boolean;
  useDni: boolean;
};

export type InlineEntityState = {
  value: Partial<InlineEntity> | undefined;
  setValue: (value: Partial<InlineEntity> | undefined) => void;
  cuitInputState: ReturnType<typeof useCuitInputState>;
  options: InlineEntityOptions;
  baseFolder: BaseFolder | undefined;
  isForeign: boolean;
  setIsForeign: (isForeign: boolean) => void;
  error: string | null;
};

export const useInlineEntityState = ({
  value,
  setValue,
  options: {
    includeName = true,
    includeExtraNameFields = includeName,
    includeDateOfBirth = false,
    allowEntityTypes = EntityType.Any,
    dateOfBirthRequired = false,
    required = false,
    validate = false,
    allowForeignEntities = true,
    allowDomesticEntities = true,
    useDni = false,
  } = {},
  baseFolder,
}: {
  options: Partial<InlineEntityOptions>;
  value: Partial<InlineEntity> | undefined;
  setValue: (value: Partial<InlineEntity> | undefined) => void;
  baseFolder?: BaseFolder;
}): InlineEntityState => {
  const options = {
    includeName,
    includeExtraNameFields,
    includeDateOfBirth,
    allowEntityTypes,
    dateOfBirthRequired,
    required,
    validate,
    allowForeignEntities,
    allowDomesticEntities,
    useDni,
  };
  const [oldValue, setOldValue] = useState(value);
  const cuitInputState = useCuitInputState({
    value,
    setValue,
    required: required,
    allowEntityTypes,
    baseFolder,
    showErrors: validate,
    useDni,
  });
  const valueIsForeign = (value: any) => "foreignIdNumber" in value;
  /* We need an "isForeign" state since:
   * Our value prop is the value of the inline entity, which can be empty.
     The inline entities are saved in a map under an id which is built from:
      * the id number
      * id document type
      * country.
   * This means that an empty "is foreign" / "is domestic" value cannot be saved as an
    inline entity (since we use the id numbers _as the id_ and there's no id yet).
   * An implementation would save an empty value as simply null and a non-empty one as an id
     pointing to the rest of the data. And this new empty entity will come back as the value.
   * Since the value we receive is taken from the inline entity we're editing or just the "empty"
     one this would make it impossible to check or uncheck the "is foreign" checkbox. */
  const [isForeign, setIsForeign] = useState(valueIsForeign(value));

  // In case the value changes from outside the component we need to update the isForeign state from the value itself (if it's not empty)
  useEffect(() => {
    if (!deepEqual(oldValue, value)) {
      setOldValue(value);
      if (!isInlineEntityEmpty(value)) {
        setIsForeign(valueIsForeign(value));
      }
    }
  }, [oldValue, value]);

  return {
    value,
    setValue,
    cuitInputState,
    options,
    baseFolder,
    isForeign,
    setIsForeign: (isForeign) => {
      setIsForeign(isForeign);
      setValue(
        buildInlineEntityDefaultValue({
          isForeign,
          allowEntityTypes,
        })
      );
    },
    error: isForeign
      ? getInlineEntityError(value ?? {}, {
          allowEntityTypes: options.allowEntityTypes,
          required,
        })
      : cuitInputState.error ??
        getInlineEntityError(
          {
            cuit: value?.cuit,
          },
          {
            allowEntityTypes,
            required,
          }
        ),
  };
};
