/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  emailChannelType,
  reutersChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import { TermsOfUseParams } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseSgrOnboardingOptions } from "../../../framework/tenant-handlers/base-sgr-onboarding-options.ts";
import { ComponentType } from "react";
import { onboardingTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";

class FintechsgrOnboardingOptions extends BaseSgrOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  async getTermsOfUse(): Promise<ComponentType<TermsOfUseParams>> {
    return (await import("./onboarding-terms-of-use.tsx")).default;
  }

  getSupportedChannelTypes(): string[] {
    return [reutersChannelType, whatsappChannelType, emailChannelType];
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      { fullName: "NOMBRE EJEMPLO", address: "ejemplo@fintechsgr.com.ar" },
    ];
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }
}

export const fintechsgrOnboardingOptions = new FintechsgrOnboardingOptions();
