/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../../framework/config/config.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import {
  getAllTenantCodes,
  getTenantHandler,
} from "../../framework/tenant-collection/tenant.ts";
import { getUserRealm } from "./user-realm.ts";

const getRealmUrlParts = (
  realm: string,
  configGetter: () => any = getConfig
): {
  protocol: string;
  hostname: string;
  port: string;
  pathname: string | undefined;
} => {
  const {
    tenants,
    domainPatternForTenantUrls,
    firebaseStartPort,
    url,
    genericUrlDefaultRealm,
  } = configGetter();
  const realmConfig = tenants?.[realm];
  if (realmConfig && realmConfig.host) {
    // configs host refers to hostname
    const { host, port = "", secure = true } = realmConfig;
    return {
      protocol: secure ? "https" : "http",
      hostname: host,
      port: port,
      pathname: "",
    };
  }
  if (domainPatternForTenantUrls) {
    // FIXME?: this should be sandbox specific
    return {
      protocol: "http",
      hostname: getRealmHostnameFromPattern(domainPatternForTenantUrls, realm),
      port: firebaseStartPort,
      pathname: "",
    };
  }
  const parsedUrl = new URL(url);
  return {
    protocol: parsedUrl.protocol.replace(/:/g, ""),
    hostname: parsedUrl.hostname,
    port: parsedUrl.port,
    pathname:
      genericUrlDefaultRealm === realm ? "" : getGenericHostRealmPath(realm),
  };
};

export const getGenericHostRealmPath = (realm: string) => {
  const pathLabel = getTenantHandler(realm).getPathLabel();
  if (pathLabel) {
    return "/r/" + pathLabel;
  } else {
    return undefined;
  }
};

export const getRealmHostnameFromPattern = (
  domainPatternForTenantUrls: string,
  realm: string
) => {
  return domainPatternForTenantUrls.replace("%tenant%", realm);
};

export const getRealmUrl = (realm: string, configGetter?: () => any) => {
  const realmUrlParts = getRealmUrlParts(realm, configGetter);
  const { protocol, hostname, port, pathname } = realmUrlParts;
  return (
    protocol +
    "://" +
    hostname +
    (port ? ":" + port : "") +
    (pathname ? pathname : "")
  );
};

export const getRealmHostname = (realm: string, configGetter?: () => any) =>
  getRealmUrlParts(realm, configGetter).hostname;

export const getRealmUrlFromProfileTenant = (
  profileTenant: string,
  roles: RolesMap,
  configGetter?: () => any
) => getRealmUrl(getUserRealm({ tenant: profileTenant }, roles), configGetter);

let realmsByHostAlone: { [host: string]: string };

const buildRealmsByHostAlone = (configGetter?: () => any) => {
  const realmsByHostAlone: { [host: string]: string } = {};
  for (const tenant of getAllTenantCodes()) {
    const { pathname, hostname } = getRealmUrlParts(tenant, configGetter);
    if (pathname === "") {
      realmsByHostAlone[hostname] = tenant;
    }
  }
  return realmsByHostAlone;
};

/** only for tenants that don't use paths */
export const getRealmsByHostAlone = (configGetter?: () => any) => {
  if (configGetter) {
    return buildRealmsByHostAlone(configGetter);
  }
  if (!realmsByHostAlone) {
    realmsByHostAlone = buildRealmsByHostAlone();
  }
  return realmsByHostAlone;
};
