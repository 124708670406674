/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  UserPrivateProfile,
  UserProfile,
} from "../../framework/auth-profile/schema.ts";
import { FirebaseAccount } from "../../framework/auth/firebase-account.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import { Settings } from "../../framework/settings/schema.ts";
import { requiredActionFailure, requiredActionSuccess } from "./status.ts";

export enum SendEmailVerificationMailError {
  alreadyVerified = "alreadyVerified",
  sendFailure = "sendFailure",
}

export enum EmailVerificationStatus {
  start = "start",
  success = requiredActionSuccess,
  failure = requiredActionFailure,
  alreadyVerified = SendEmailVerificationMailError.alreadyVerified,
}

export type RequiredActionCallback = (
  props: {
    privateProfile?: UserPrivateProfile;
    profile?: UserProfile;
    roles?: RolesMap;
    account?: FirebaseAccount;
    currentTenant: string;
  },
  settings?: Settings
) => boolean;

export type RequiredActionType = {
  id: string;
  label: string;
  enforce: RequiredActionCallback;
  condition: RequiredActionCallback;
  Component: React.ComponentType<{ status: EmailVerificationStatus }>;
};
