/* DON'T EDIT THIS FILE: edit original and run build again */ import { ToggleButtonGroup } from "react-bootstrap";

export const EditorButtonGroup = ({
  children,
  name,
}: {
  children: React.ReactNode;
  name: string;
}) => (
  <ToggleButtonGroup className="me-2" name={name} type="checkbox">
    {children}
  </ToggleButtonGroup>
);
