/* DON'T EDIT THIS FILE: edit original and run build again */ import { WithId } from "../../framework/core/with-id.ts";
import { DocumentBaseData } from "./firestore-wrappers-types.ts";
import { FirestoreDocument } from "./firestore-wrappers.ts";

type fetchDocOverloads = {
  <T extends DocumentBaseData>(doc: FirestoreDocument<T>): Promise<
    T | undefined
  >;
  <TKeyType extends string, T extends DocumentBaseData, K extends string>(
    doc: FirestoreDocument<T>,
    idProp: K
  ): Promise<WithId<T, K, TKeyType> | undefined>;
  // we maybe should throw on error and make the caller explicitly handle it
};
export const fetchDoc: fetchDocOverloads = async <
  T extends DocumentBaseData,
  K extends string
>(
  doc: FirestoreDocument<T>,
  idProp?: K
) => {
  const snap = await doc.get();
  const data = snap.data();
  if (!data || !idProp) {
    return data;
  }
  return { ...data, [idProp]: snap.id };
};
