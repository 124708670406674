/* DON'T EDIT THIS FILE: edit original and run build again */ import { ColorPalette } from "../../../framework/tenant-handler-type/color-pallete-type.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { awaOnboardingOptions } from "./awa-onboarding-options.ts";
import { awaTenant } from "./code.ts";
import { awaFullLabel } from "./full-label.ts";
import { awaLabel } from "./label.ts";

class AwaTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_awa.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return awaTenant;
  }

  getPathLabel(): string | null {
    return "awa";
  }

  getLabel(): string {
    return awaLabel;
  }

  getFullLabel(): string {
    return awaFullLabel;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return awaOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@awa-global.com";
  }

  getComplianceEmailAddress(): string {
    return "compliance@awa-global.com";
  }

  getCuit(): string {
    return "30-71425025-2";
  }

  getCnvRegistryId(): string | null {
    return "826";
  }

  hasSemaphoreSettings(): boolean {
    return false;
  }

  supportsRiskMatrix(): boolean {
    return false;
  }

  getColors(): ColorPalette {
    return {
      primary: "#063240",
      secondary: "#06203B",
      tertiary: "#063240",
    };
  }
}

const awaTenantHandler = new AwaTenantHandler();

export default awaTenantHandler;
