/* DON'T EDIT THIS FILE: edit original and run build again */ (function () {
  let callbacks: (() => void)[] = [];

  const openRequests = new Set();

  const isBusy = () => openRequests.size > 0;

  const callWhenIdle = (callback: () => void) => {
    if (isBusy()) {
      callbacks.push(callback);
    } else {
      callback();
    }
  };

  const runCallbacksIfStillIdle = () => {
    if (isBusy()) {
      return;
    }
    const oldCallbacks = callbacks;
    callbacks = [];
    for (const callback of oldCallbacks) {
      callback();
    }
  };

  const addRequest = (request: any) => {
    openRequests.add(request);
  };

  const removeRequest = (request: any) => {
    const wasBusy = isBusy();
    openRequests.delete(request);
    if (wasBusy && !isBusy()) {
      setTimeout(runCallbacksIfStillIdle, 10);
    }
  };

  const shouldIgnore = (url: string) => {
    const should = url.indexOf("google.firestore.v1.Firestore/Listen") !== -1;
    return should;
  };

  let oldOpen = XMLHttpRequest.prototype.open;

  const interceptXmlHttpRequest = () => {
    const track = (request: any) => {
      request.addEventListener(
        "readystatechange",
        () => {
          const readyState = request.readyState;
          const requestIsInactive = readyState === 0 || readyState === 4;
          if (requestIsInactive) {
            removeRequest(request);
          } else {
            addRequest(request);
          }
        },
        false
      );
    };

    // @ts-expect-error not sure why typescript doesn't agree on the signature
    XMLHttpRequest.prototype.open = function (
      method,
      url: string,
      async,
      user,
      pass
    ) {
      const should = shouldIgnore(url);
      if (!should) {
        track(this);
      }
      return oldOpen.call(this, method, url, async, user, pass);
    };
  };

  let oldFetch = window.fetch;

  const interceptFetch = () => {
    window.fetch = async (...args) => {
      const requestObjOrUrl: any = args[0];
      const url = requestObjOrUrl.url ?? requestObjOrUrl + "";
      if (!shouldIgnore(url)) {
        const request = {};
        const markAsDone = () => {
          removeRequest(request);
        };
        const wrapResponseMethod = (response: any, methodName: string) => {
          const old = response[methodName];
          response[methodName] = function (...args2: any[]) {
            const subRequest = {};
            addRequest(subRequest);
            const markSubrequestAsDone = () => {
              removeRequest(subRequest);
            };
            return old.call(this, ...args2).finally(markSubrequestAsDone);
          };
        };
        addRequest(request);
        return oldFetch(...args)
          .then((response) => {
            markAsDone();
            wrapResponseMethod(response, "json");
            wrapResponseMethod(response, "blob");
            wrapResponseMethod(response, "text");
            wrapResponseMethod(response, "formData");
            wrapResponseMethod(response, "arrayBuffer");
            return response;
          })
          .catch((error) => {
            markAsDone();
            throw error;
          });
      } else {
        return oldFetch(...args);
      }
    };
  };

  interceptXmlHttpRequest();
  interceptFetch();

  const replaceFetch = (replacer: any) => {
    oldFetch = replacer(oldFetch);
  };

  const replaceXmlHttpRequestOpen = (replacer: any) => {
    oldOpen = replacer(oldOpen);
  };

  window.idler = {
    addRequest,
    removeRequest,
    callWhenIdle,
    isBusy,
    replaceFetch,
    replaceXmlHttpRequestOpen,
  };
})();
