/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../../framework/core/not-null.ts";
import { fetchDoc } from "../../framework/firebase/fetch-doc.ts";
import { createFirestoreCollection } from "../../framework/firebase/firestore-wrappers.ts";
import { RoleRequest, UserRoleRequest } from "./schema.ts";

export const getRoleRequestsCol = () =>
  createFirestoreCollection<RoleRequest>("roleRequests");

export const getRoleRequestsDoc = (uid: string) =>
  getRoleRequestsCol().doc(uid);

export const fetchRoleRequests = async (
  uid: string
): Promise<UserRoleRequest> =>
  notNull(await fetchDoc(getRoleRequestsDoc(uid), "uid"));
