/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { mapObject } from "../../framework/core/map-object.ts";
import { rolePermissionsAsArrays } from "./permissions.ts";
import { Roles } from "./schema.ts";

const getRolePermissionsAsArrays = (): Record<Roles, string[]> => {
  return rolePermissionsAsArrays as Record<Roles, string[]>;
};

export const getRolePermissions = (): Record<Roles, Record<string, true>> =>
  mapObject(getRolePermissionsAsArrays(), ([role, permissionsArray]) => [
    role,
    mapArrayToObject(permissionsArray, (item) => [item, true]),
  ]);
