/* DON'T EDIT THIS FILE: edit original and run build again */ type AcavaloresOfficeBranch = {
  id: string;
  name: string;
};

export const baseAcavaloresOfficeBranch: AcavaloresOfficeBranch = {
  id: "acavalores",
  name: "ACA Valores",
};
export const bahiaBlancaAcavaloresOfficeBranch: AcavaloresOfficeBranch = {
  id: "bahiaBlanca",
  name: "Bahía Blanca",
};
export const buenosAiresAcavaloresOfficeBranch: AcavaloresOfficeBranch = {
  id: "buenosAires",
  name: "Buenos Aires",
};
export const cordobaAcavaloresOfficeBranch: AcavaloresOfficeBranch = {
  id: "cordoba",
  name: "Córdoba",
};
export const rosarioAcavaloresOfficeBranch: AcavaloresOfficeBranch = {
  id: "rosario",
  name: "Rosario",
};
export const santaFeAcavaloresOfficeBranch: AcavaloresOfficeBranch = {
  id: "santaFe",
  name: "Santa Fe",
};

export const acavaloresOfficeBranches = [
  baseAcavaloresOfficeBranch,
  bahiaBlancaAcavaloresOfficeBranch,
  buenosAiresAcavaloresOfficeBranch,
  cordobaAcavaloresOfficeBranch,
  rosarioAcavaloresOfficeBranch,
  santaFeAcavaloresOfficeBranch,
];
