import * as React from "react";
const SvgLogoGlobalmarketSmall = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 2015 2015", style: {
  shapeRendering: "geometricprecision"
}, xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Layer_x0020_1", transform: "matrix(1, 0, 0, 1, -11218.275390625, -13788.2001953125)" }, /* @__PURE__ */ React.createElement("g", { id: "_2390024145296" }, /* @__PURE__ */ React.createElement("path", { className: "fil2", d: "M 13215.492 14912.348 C 13221.504 14911.356 13214.5 14922.348 13213.508 14928.339 C 13206.505 14979.328 13196.505 15030.317 13181.506 15080.294 C 13161.528 15150.27 13133.533 15216.257 13100.539 15277.226 C 12927.594 15597.126 12588.707 15809.057 12206.827 15800.069 L 12205.835 15800.069 C 11930.932 15794.058 11684.003 15676.109 11508.062 15490.169 C 11333.113 15305.22 11228.159 15052.3 11234.151 14775.393 L 11234.151 14774.401 L 11234.151 14773.389 C 11240.143 14496.482 11356.107 14247.569 11539.052 14070.636 C 11722.989 13892.691 11973.905 13784.722 12247.816 13790.714 L 12248.808 13790.714 L 12242.816 14132.617 L 12241.824 14132.617 C 12061.876 14129.601 11898.93 14199.577 11777.965 14316.553 C 11657.021 14433.51 11580.041 14597.448 11576.034 14779.401 L 11576.034 14781.404 L 11576.034 14782.396 C 11572.046 14965.341 11642.022 15132.275 11757.986 15255.243 C 11872.939 15376.208 12033.881 15454.179 12211.826 15458.167 L 12212.838 15458.167 C 12463.755 15463.166 12684.673 15324.227 12798.634 15115.292 C 12821.629 15073.29 12839.624 15029.305 12852.619 14984.328 L 12868.61 14917.348 L 13215.492 14912.348 Z", style: {
  fill: "rgb(24, 98, 0)",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("polygon", { className: "fil4", points: "13204.499 14912.351 12240.806 14912.351 12240.806 15028.311 13161.525 15028.311", style: {
  fill: "rgb(24, 98, 0)"
} }), /* @__PURE__ */ React.createElement("rect", { className: "fil6", x: 12240.83, y: 14683.422, width: 223.934, height: 223.934, style: {
  fill: "rgb(204, 76, 0)"
} }), /* @__PURE__ */ React.createElement("path", { className: "fil3 str0", d: "M 13049.551 14851.38 C 13019.572 15296.233 12649.695 15639.107 12209.823 15629.108 C 11754.971 15619.129 11395.093 15238.24 11405.092 14777.397 C 11415.092 14317.545 11790.96 13951.676 12245.812 13961.655", style: {
  fill: "none",
  stroke: "rgb(0, 255, 0)",
  strokeWidth: "7.62px"
} }), /* @__PURE__ */ React.createElement("path", { className: "fil3 str3", d: "M 13055.562 14834.377 C 13035.563 15279.23 12672.67 15630.12 12232.817 15630.12 C 11778.977 15630.12 11410.092 15256.235 11410.092 14796.384 C 11410.092 14335.54 11778.977 13961.655 12232.817 13961.655 C 12687.669 13961.655 13056.554 14335.54 13056.554 14796.384 C 13056.554 14808.387 13055.562 14821.382 13055.562 14834.377 Z", style: {
  fill: "none",
  stroke: "rgb(255, 255, 255)",
  strokeWidth: "47.4px"
} }), /* @__PURE__ */ React.createElement("path", { className: "fil3 str4", d: "M 12247.816 13961.655 C 12695.664 13969.671 13055.562 14340.54 13055.562 14795.392 C 13055.562 14808.387 13055.562 14821.382 13054.55 14833.385 C 13053.558 14861.36 13051.554 14889.354 13047.547 14916.356", style: {
  fill: "none",
  stroke: "rgb(187, 205, 199)",
  strokeWidth: "47.4px"
} }))));
export default SvgLogoGlobalmarketSmall;
