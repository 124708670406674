/* DON'T EDIT THIS FILE: edit original and run build again */ import { CuitInputState } from "../../credit/cuit-input/cuit-input-state.ts";
import { CuitInput } from "../../credit/cuit-input/cuit-input.tsx";
import { LiveFieldGetLiveFormInputFactory } from "../../credit/live-form/ui/types.ts";
import { LabeledCheckbox } from "../../credit/ui/form/labeled-checkbox.tsx";
import { ForeignInfo } from "./foreign-info-input.tsx";
import { InlineEntityState } from "./inline-entity-state.ts";

const DomesticInfo = ({
  autoFocus,
  cuitInputState,
}: {
  autoFocus?: boolean;
  cuitInputState: CuitInputState;
}) => {
  return (
    <label className="d-block">
      <div className="fw-bold mb-1">
        {cuitInputState.useDni ? "DNI" : "CUIL/CUIT"}
      </div>
      <CuitInput
        key="cuit"
        autoFocus={autoFocus}
        cuitInputState={cuitInputState}
      />
    </label>
  );
};

export const InlineEntityInput = ({
  inlineEntityState: {
    isForeign,
    setIsForeign,
    value,
    setValue,
    baseFolder,
    options,
    cuitInputState,
  },
  autoFocus = false,
  onBlur,
  className,
  getLiveFormInputFactory,
}: {
  inlineEntityState: InlineEntityState;
  autoFocus?: boolean;
  onBlur?: () => void;
  className?: string;
  getLiveFormInputFactory: LiveFieldGetLiveFormInputFactory;
}) => {
  return (
    <div onBlur={onBlur} className={className}>
      {options.allowForeignEntities && options.allowDomesticEntities ? (
        <LabeledCheckbox
          label={
            cuitInputState.useDni
              ? "No tiene DNI Argentino"
              : "No tiene CUIT/CUIL Argentino"
          }
          className="my-2"
          value={isForeign}
          setValue={setIsForeign}
        />
      ) : null}
      {isForeign || !options.allowDomesticEntities ? (
        <ForeignInfo
          {...{
            value: value ?? {},
            setValue,
            options,
            baseFolder,
            getLiveFormInputFactory,
          }}
        />
      ) : (
        <DomesticInfo {...{ autoFocus, cuitInputState }} />
      )}
    </div>
  );
};
