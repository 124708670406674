/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  LiveFieldInput,
  LiveFieldRenderer,
  LiveFieldViewer,
} from "../../../credit/live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../../credit/live-form/ui/use-live-after-blur-text-input.tsx";
import { DateInput } from "../../../credit/ui/form/date-input.tsx";
import { createDateFromUtcDateOnlyString } from "../../../framework/core/utc-date.ts";
import { formatUtcDate } from "../../../framework/format/format.ts";
import { Div } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { dateLiveFieldType } from "./type.ts";

const Input: LiveFieldInput = (fieldInfo) => (
  <DateInput {...useLiveAfterBlurTextInput(fieldInfo)} />
);

const Viewer: LiveFieldViewer = ({ liveCell }) => {
  const fieldValue = (liveCell.getValue() as string) ?? null;
  if (!fieldValue) {
    return null;
  }
  return (
    <Div>{formatUtcDate(createDateFromUtcDateOnlyString(fieldValue))}</Div>
  );
};

export const dateLiveFieldRenderers: LiveFieldRenderer = {
  type: dateLiveFieldType,
  Input,
  Viewer,
};
