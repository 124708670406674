/* DON'T EDIT THIS FILE: edit original and run build again */ import { getRolePermissions } from "./role-permissions.ts";
import { Roles, RolesMap } from "./schema.ts";

export const userHasPermission = (roles: RolesMap, permission: string) => {
  if (!roles) {
    throw new Error("missingRoles");
  }
  for (const [role, has] of Object.entries(roles)) {
    if (!has) {
      continue;
    }
    if (getRolePermissions()[role as Roles]?.[permission]) {
      return true;
    }
  }
  return false;
};

export const getRolesWithPermission = (permission: string) => {
  const out: Roles[] = [];
  for (const [role, permissions] of Object.entries(getRolePermissions())) {
    if (permissions[permission]) {
      out.push(role as Roles);
    }
  }
  return out;
};

export const getRolesWithAndWithoutPermissions = (
  withPermissions: string[],
  withoutPermissions: string[]
) => {
  const out: Roles[] = [];
  for (const [role, permissions] of Object.entries(getRolePermissions())) {
    if (
      withPermissions.every((permission) => !!permissions[permission]) &&
      withoutPermissions.every((permission) => !permissions[permission])
    ) {
      out.push(role as Roles);
    }
  }
  return out;
};
