/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { petriniValoresTenant } from "./code.ts";
import { petriniValoresFullLabel } from "./full-label.ts";
import { petriniValoresLabel } from "./label.ts";

class PetriniValoresTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return petriniValoresTenant;
  }

  getPathLabel(): string | null {
    return "petrini-valores";
  }

  getLabel(): string {
    return petriniValoresLabel;
  }

  getFullLabel(): string {
    return petriniValoresFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-69424636-9";
  }

  getCnvRegistryId(): string | null {
    return "85";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const petriniValoresTenantHandler = new PetriniValoresTenantHandler();

export default petriniValoresTenantHandler;
