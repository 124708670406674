/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../../../framework/core/not-null.ts";
import { WithId } from "../../../framework/core/with-id.ts";
import { fetchDoc } from "../../../framework/firebase/fetch-doc.ts";
import {
  createFirestoreCollection,
  FirestoreCollection,
  FirestoreDocument,
} from "../../../framework/firebase/firestore-wrappers.ts";
import { InviteLink } from "./schema.ts";

export const getInviteLinksCol = (): FirestoreCollection<InviteLink> =>
  createFirestoreCollection<InviteLink>("inviteLinks");

export const getInviteLinkDoc = (
  inviteId: string
): FirestoreDocument<InviteLink> => getInviteLinksCol().doc(inviteId);

export const maybeFetchInviteLinkDoc = async (
  inviteId: string
): Promise<WithId<InviteLink, "id"> | undefined> =>
  fetchDoc(getInviteLinkDoc(inviteId), "id");

export const fetchInviteLinkDoc = async (
  inviteId: string
): Promise<WithId<InviteLink, "id">> =>
  notNull(await maybeFetchInviteLinkDoc(inviteId));
