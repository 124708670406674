/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserProfile } from "../../framework/auth-profile/schema.ts";
import { useNavigateInTenant } from "../../framework/routing/tenant-routing.tsx";

export const UserInfo = ({
  profile,
  open,
}: {
  profile: UserProfile;
  open: boolean;
}) => {
  return (
    <div className="d-flex align-items-center mb-5">
      <UserNameCircle profile={profile} />
      <div
        className="d-flex flex-column ms-3"
        style={open ? {} : { visibility: "hidden" }}
      >
        <div className="text-gray40 text-s text-nowrap mb-1">
          Inicio de sesión como
        </div>
        <div
          className="text-white text-s"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "180px",
          }}
        >
          {profile.email}
        </div>
      </div>
    </div>
  );
};

export const UserNameCircle = ({ profile }: { profile: UserProfile }) => {
  const navigate = useNavigateInTenant();
  return (
    <div
      className="d-flex align-items-center justify-content-center text-white fw-bold rounded-circle bg-blue50 border border-gray40 border-4 cursor-pointer"
      style={{
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      }}
      onClick={async () => {
        navigate("/mi-perfil");
      }}
    >
      {profile.firstName[0]}
      {profile.lastName[0]}
    </div>
  );
};
