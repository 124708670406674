/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { neixTenant } from "./code.ts";
import { neixFullLabel } from "./full-label.ts";
import { neixLabel } from "./label.ts";

class NeixTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return neixTenant;
  }

  getLabel(): string {
    return neixLabel;
  }

  getPathLabel(): string | null {
    return "neix";
  }

  getFullLabel(): string {
    return neixFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71071863-2";
  }

  getCnvRegistryId(): string | null {
    return "145";
  }
}

const neixTenantHandler = new NeixTenantHandler();

export default neixTenantHandler;
