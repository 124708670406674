/* DON'T EDIT THIS FILE: edit original and run build again */ import { LineOptions } from "../../../framework/tenant-handler-type/line-options.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { laAnonimaTenant } from "./code.ts";
import { laAnonimaFullLabel } from "./full-label.ts";
import { laAnonimaLineOptions } from "./la-anonima-line-options.ts";
import { laAnonimaLabel } from "./label.ts";

class LaAnonimaTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return laAnonimaTenant;
  }

  getPathLabel(): string | null {
    return "laanonima";
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return null;
  }

  getLineOptions(): LineOptions {
    return laAnonimaLineOptions;
  }

  getLabel(): string {
    return laAnonimaLabel;
  }

  getFullLabel(): string {
    return laAnonimaFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-50673003-8";
  }
}

const laAnonimaTenantHandler = new LaAnonimaTenantHandler();
export default laAnonimaTenantHandler;
