/* DON'T EDIT THIS FILE: edit original and run build again */ import { AtPath } from "../../framework/core/at-path.ts";
import { WithId } from "../../framework/core/with-id.ts";
import { combinePipesAsArray } from "../../framework/data-pipe/combine-pipes-as-array.ts";
import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { colPipe } from "../../framework/firebase/firestore-pipe.ts";
import {
  FieldPath,
  WhereFilterOpArrayLike,
  firestoreDisjunctionLimit,
} from "../../framework/firebase/firestore-wrappers-types.ts";
import { JsonArray } from "@remirror/core";
import { FirestoreCollection } from "./firestore-wrappers.ts";

type NonUndefined<T> = Exclude<T, undefined>;

/**
 * creates a pipe that batches possibly large array-like collection queries
 * @param values is expected to be non-empty and set-like (all elements should be unique)
 * */
type BatchColPipeOverloads = {
  <T extends Record<any, any>, K extends string, Id extends string>(
    col: FirestoreCollection<T>,
    field: K,
    operator: WhereFilterOpArrayLike,
    values: NonUndefined<AtPath<T, K, ".">> extends JsonArray
      ? NonUndefined<AtPath<T, K, ".">>
      : NonUndefined<AtPath<T, K, ".">[]>,
    idProp: Id,
    description: string,
    limit?: number
  ): DataPipe<WithId<T, Id>[]>;
  <T extends Record<any, any>, Id extends string>(
    col: FirestoreCollection<T>,
    field: FieldPath,
    operator: WhereFilterOpArrayLike,
    values: JsonArray,
    idProp: Id,
    description: string,
    limit?: number
  ): DataPipe<WithId<T, Id>[]>;
};
export const batchColPipe: BatchColPipeOverloads = <
  T extends Record<any, any>,
  Id extends string
>(
  col: FirestoreCollection<T>,
  field: FieldPath,
  operator: WhereFilterOpArrayLike,
  values: any,
  idProp: Id,
  description: string,
  limit: number = firestoreDisjunctionLimit
) => {
  const queryLimit = Math.floor(
    Math.min(limit, firestoreDisjunctionLimit) / col.disjunctions()
  );
  const batches: any[] = [];
  for (let i = 0; i < values.length; i += queryLimit) {
    batches.push(values.slice(i, i + queryLimit));
  }
  const pipes = batches.map((batch) =>
    colPipe(
      col.where(field, operator, batch),
      idProp,
      "batchDocPipe:" + description
    )
  );
  return combinePipesAsArray(pipes).pipe((cols) => {
    if (operator === "in") {
      // each doc holds only one value. Thus, given values is set-like,
      // we can guarantee each col has distinct docs.
      return cols.flat();
    } else {
      // filter unique
      const map: { [prop: string]: WithId<T, Id> } = {};
      for (const elem of cols.flat()) {
        if (!Object.hasOwn(map, elem[idProp])) {
          map[elem[idProp]] = elem;
        }
      }
      return Object.values(map);
    }
  });
};
