/* DON'T EDIT THIS FILE: edit original and run build again */ import { WithId } from "../../../framework/core/with-id.ts";
import { OrderRoutingOrder } from "./order.ts";
import { OrderRoutingOrderStatus } from "./status.ts";

export const orderRoutingListingPath = "/ordenes";
export const orderRoutingLiveView = "/ordenes-live";
export const orderRoutingHistoricalView = "/ordenes-historico";
export const orderRoutingEditOrderPath = "/editar-orden/:orderId";
export const orderRoutingCreatePath = "/crear-orden";
export const orderRoutingValidatePath = "/validar-orden/:orderId/:statusLabel";

export const parseOrderRoutingEditOrderPath = (
  order: WithId<OrderRoutingOrder, "id">
) => {
  return orderRoutingEditOrderPath.replace(":orderId", order.id);
};

export const parseOrderRoutingValidatePath = (
  order: WithId<OrderRoutingOrder, "id">,
  status: OrderRoutingOrderStatus
) => {
  return orderRoutingValidatePath
    .replace(":orderId", order.id)
    .replace(":statusLabel", status);
};
