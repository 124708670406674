/* DON'T EDIT THIS FILE: edit original and run build again */ import { logoutPath } from "../../framework/auth-ui/logout-path.ts";
import { PowerIcon } from "../../framework/theme-icons/icon.ts";
import { Button } from "react-bootstrap";

export const SignOutButton = () => {
  return (
    <Button
      size="sm"
      className="p-2 d-flex align-items-center bg-white rounded"
      onClick={async () => {
        document.location = logoutPath;
      }}
    >
      <PowerIcon className="ms-1 icon-2 text-white bg-white" fill="white" />
      <div className="ms-2 me-3 text-gray10">Salir</div>
    </Button>
  );
};
