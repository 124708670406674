/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import {
  emailChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import { EMAIL } from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";
import onboardingTermsOfUseTitle from "./onboarding-terms-of-use-title.ts";

class InverstartOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  async getTermsOfUse(): Promise<ComponentType<TermsOfUseParams>> {
    return (await import("./onboarding-terms-of-use.tsx")).default;
  }

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  hasAune(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [whatsappChannelType, emailChannelType];
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }

  async getWebHomeHeader(): Promise<ComponentType<WebHomeHeaderParams>> {
    return (await import("./web-home-header.tsx")).default;
  }

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "automatic";
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "full";
  }
}

export const inverstartOnboardingOptions = new InverstartOnboardingOptions();
