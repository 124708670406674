/* DON'T EDIT THIS FILE: edit original and run build again */ import { useCallback } from "react";

const focusAndUpdateValue = (manager: any, html: string) => {
  manager.store.commands.focus();
  const state = manager.createState({
    content: html,
    stringHandler: "html",
  });
  manager.view.updateState(state);
  manager.onStateUpdate({ previousState: null, state });
};

export const useSetInputValueForTests = (
  manager: any,
  onChange: (value: string) => void
) =>
  useCallback(
    (node: any) => {
      if (node) {
        // for tests
        node.setInputValueForTests = (html: string) => {
          focusAndUpdateValue(manager, html);
          onChange(html);
          return null;
        };
      }
    },
    [manager, onChange]
  );
