/* DON'T EDIT THIS FILE: edit original and run build again */ import React from "react";
import { isRequiredText } from "./is-required.ts";

export const ValidationFeedback = ({
  error,
}: {
  error?: string;
}): React.ReactNode =>
  error ? <div className="text-warning">{error}</div> : null;

export const RequiredValidationFeedback = (): React.ReactNode =>
  ValidationFeedback({ error: isRequiredText });
