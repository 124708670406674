/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../../framework/core/not-null.ts";
import { fetchDoc } from "../../framework/firebase/fetch-doc.ts";
import {
  createFirestoreCollection,
  FirestoreCollection,
  FirestoreDocument,
} from "../../framework/firebase/firestore-wrappers.ts";
import { userCompaniesColName } from "./col-name.ts";
import { favoriteLinesColName } from "./favorite-lines-col-name.ts";
import {
  UserCompanyFavoriteLines,
  UserCompanyType,
} from "./user-company-type.ts";

export const getUserCompaniesCol = (): FirestoreCollection<UserCompanyType> =>
  createFirestoreCollection<UserCompanyType>(userCompaniesColName);

export const getUserCompanyDoc = (
  entityId: string
): FirestoreDocument<UserCompanyType> => getUserCompaniesCol().doc(entityId);

export const maybeFetchUserCompany = async (
  entityId: string
): Promise<UserCompanyType | undefined> =>
  fetchDoc(getUserCompanyDoc(entityId));

export const fetchUserCompany = async (
  entityId: string
): Promise<UserCompanyType> => notNull(await maybeFetchUserCompany(entityId));

const getUserCompanyFavoriteLinesCol =
  (): FirestoreCollection<UserCompanyFavoriteLines> =>
    createFirestoreCollection<UserCompanyFavoriteLines>(favoriteLinesColName);

export const findFavoriteLinesForUserCompany = (
  userCompanyId: string
): FirestoreCollection<UserCompanyFavoriteLines> => {
  if (!userCompanyId) {
    throw new Error("userCompanyIdIsRequired");
  }
  return getUserCompanyFavoriteLinesCol().where(
    "userCompanyId",
    "==",
    userCompanyId
  );
};

export const getUserCompanyFavoriteLinesDoc = (
  userCompanyId: string,
  lineId: string
): FirestoreDocument<UserCompanyFavoriteLines> =>
  getUserCompanyFavoriteLinesCol().doc(userCompanyId + "-" + lineId);

export const isUserCompanyForeign = (userCompany: UserCompanyType): boolean =>
  userCompany?.cuit === null;
