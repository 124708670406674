/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { affidavitTenant } from "./code.ts";
import { affidavitFullLabel } from "./full-label.ts";
import { affidavitLabel } from "./label.ts";

class AffidavitTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return affidavitTenant;
  }

  getPathLabel(): string | null {
    return "affidavit";
  }

  getLabel(): string {
    return affidavitLabel;
  }

  getFullLabel(): string | null {
    return affidavitFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-70939942-6";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const affidavitTenantHandler = new AffidavitTenantHandler();

export default affidavitTenantHandler;
