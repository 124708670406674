/* DON'T EDIT THIS FILE: edit original and run build again */ import { entityIdRegex } from "../../credit/entity/get-entity-id.ts";
import { UserProfile } from "../../framework/auth-profile/schema.ts";
import { notNull } from "../../framework/core/not-null.ts";
import { fetchDoc } from "../../framework/firebase/fetch-doc.ts";
import {
  FirestoreCollection,
  FirestoreDocument,
  createFirestoreCollection,
} from "../../framework/firebase/firestore-wrappers.ts";
import { getTenantHandler } from "../../framework/tenant-collection/tenant.ts";
import { EndClient } from "./schema.ts";

export const getEndClientId = (tenant: string, entityId: string): string =>
  tenant + "-" + entityId;

export const getEndClientIdParts = (
  endClientId: string
): [tenant: string, entityId: string] => {
  const split = endClientId.split("-");
  if (split.length != 2) {
    throw new Error("invalidEndClientId: " + endClientId);
  }
  return split as [string, string];
};

export const getEndClientEntityIds = (profile: UserProfile, tenant: string) => {
  const entityIds: string[] = [];
  for (const endClientId of profile.endClientIds || []) {
    const [endClientTenant, entityId] = getEndClientIdParts(endClientId);
    if (endClientTenant === tenant) {
      entityIds.push(entityId);
    }
  }
  return entityIds;
};

export const getUserCounterpartyEntityId = (
  currentTenant: string,
  profile: UserProfile
): [entityId: string, userHasNoUserCompany: boolean] => {
  if (profile.entityIdCompany) {
    return [profile.entityIdCompany, false];
  }
  const entityId = getTenantHandler(currentTenant).getEntityId();
  return [entityId, true];
};

export const getEndClientsCol = (): FirestoreCollection<EndClient> =>
  createFirestoreCollection<EndClient>("endClients");

export const getEndClientDoc = (
  endClientId: string
): FirestoreDocument<EndClient> => getEndClientsCol().doc(endClientId);

export const fetchEndClient = async (endClientId: string): Promise<EndClient> =>
  notNull(await fetchDoc(getEndClientDoc(endClientId)));

export const getEntityIdFromEndClientId = (endClientId: string): string => {
  const match = endClientId.match(entityIdRegex);
  if (!match) {
    throw new Error("invalidEndClientId: " + endClientId);
  }
  return match[0];
};
