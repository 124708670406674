/* DON'T EDIT THIS FILE: edit original and run build again */ import { InfoCircleOutlineIcon } from "../../framework/theme-icons/icon.ts";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const HelpButton = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => (
  <span className="ms-1 cursor-help link-blue50" onClick={onClick}>
    <InfoCircleOutlineIcon />
  </span>
);

export const HelpModal = ({
  children,
  size = "lg",
  TogglePopupButton = HelpButton,
}: {
  children?: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  TogglePopupButton?: React.ComponentType<{
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
  }>;
}) => {
  const [visible, setVisible] = useState(false);
  const onCancel = () => setVisible(false);
  return (
    <>
      <TogglePopupButton
        onClick={(event) => {
          event.preventDefault();
          setVisible(!visible);
        }}
      />
      <Modal
        animation={false}
        centered
        show={visible}
        onEscapeKeyDown={onCancel}
        onHide={onCancel}
        size={size}
      >
        <Modal.Body>
          <div className="mx-4 p-3">{children}</div>
          <div className="d-flex justify-content-end">
            <Button
              className="me-2"
              onClick={onCancel}
              variant="outline-blue50"
            >
              Aceptar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
