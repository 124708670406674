/* DON'T EDIT THIS FILE: edit original and run build again */ import { argentinaCountryCode } from "../../../framework/country/country-codes.ts";
import { cabaProvinceCode } from "../../../credit/form/argentina-provinces.ts";
import { useDeepMemo } from "../../../framework/react/use-deep-memo.ts";
import { ArgentineProvinceDropdown } from "../../../credit/ui/form/argentine-province-dropdown.tsx";
import { CountryDropdown } from "../../../credit/ui/form/country-dropdown.tsx";
import { Labeled } from "../../../credit/ui/form/labeled.tsx";
import { RequiredValidationFeedback } from "../../../credit/ui/form/validation-feedback.tsx";
import { useChangeEffect } from "@ehynds/use-change-effect";
import { useState } from "react";
import { useDelayedChangeTextInputState } from "./delayed-change-text-input.tsx";
import { TextInput } from "./text-input.tsx";

const DelayedChangeTextInput = ({
  value,
  setValue,
  className,
  autoFocus,
}: {
  value: any;
  setValue: any;
  className: string;
  autoFocus: boolean;
}) => {
  return (
    <TextInput
      {...useDelayedChangeTextInputState({
        value,
        setValue,
      })}
      className={className}
      autoFocus={autoFocus}
    />
  );
};

export const useLocationState = ({
  detailed,
  defaultValue: {
    country: defaultCountry = "",
    argentineProvince: defaultArgentineProvince = "",
    otherProvince: defaultOtherProvince = "",
    locality: defaultLocality = "",
    streetLine: defaultStreetLine = "",
    zipCode: defaultZipCode = "",
  } = {},
  onChange,
}: {
  detailed: boolean;
  defaultValue?: {
    country?: string;
    argentineProvince?: string;
    otherProvince?: string;
    locality?: string;
    streetLine?: string;
    zipCode?: string;
  };
  onChange: (value: any) => void;
}) => {
  const [country, setCountry] = useState(defaultCountry);
  const [argentineProvince, setArgentineProvince] = useState(
    defaultArgentineProvince
  );
  const [otherProvince, setOtherProvince] = useState(defaultOtherProvince);
  const [locality, setLocality] = useState(defaultLocality);
  const [streetLine, setStreetLine] = useState(defaultStreetLine ?? "");
  const [zipCode, setZipCode] = useState(defaultZipCode ?? "");
  const getValue = () => {
    const baseValue = {
      country,
      argentineProvince,
      otherProvince,
      locality:
        country === argentinaCountryCode &&
        argentineProvince === cabaProvinceCode
          ? ""
          : locality,
    };
    if (detailed) {
      return {
        ...baseValue,
        streetLine,
        zipCode,
      };
    } else {
      return baseValue;
    }
  };
  const value = useDeepMemo(getValue());
  useChangeEffect(
    (prevValue) => {
      if (value !== prevValue && onChange) {
        onChange(value);
      }
    },
    [value, onChange]
  );
  return {
    detailed,
    country,
    setCountry,
    argentineProvince,
    setArgentineProvince,
    otherProvince,
    setOtherProvince,
    locality,
    setLocality,
    streetLine,
    setStreetLine,
    zipCode,
    setZipCode,
    getValue,
  };
};

export const LocationInput = ({
  locationState,
  className = "",
  autoFocus,
  required,
  options = {},
}: {
  locationState: ReturnType<typeof useLocationState>;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;
  options?: {
    country?: {
      label?: string;
      required?: boolean;
    };
    argentineProvince?: {
      label?: string;
      required?: boolean;
    };
    otherProvince?: {
      label?: string;
      required?: boolean;
    };
    locality?: {
      label?: string;
      required?: boolean;
    };
    zipCode?: {
      label?: string;
      required?: boolean;
    };
    streetLine?: {
      label?: string;
      required?: boolean;
    };
  };
}) => {
  const createInput = (
    Input: React.ComponentType<any>,
    value: any,
    setValue: any,
    required: boolean | undefined,
    autoFocus = false
  ) => {
    const invalid = !value && required;
    return (
      <>
        <Input
          value={value}
          setValue={setValue}
          className={invalid ? "is-invalid" : null}
          autoFocus={autoFocus}
        />
        {invalid ? <RequiredValidationFeedback /> : null}
      </>
    );
  };
  return (
    <div className={className}>
      <Labeled
        label={options.country?.label || "País"}
        input={createInput(
          CountryDropdown,
          locationState.country,
          locationState.setCountry,
          options.country?.required ?? required,
          autoFocus
        )}
      />
      {locationState.country ? (
        <>
          {locationState.country === argentinaCountryCode ? (
            <Labeled
              label={
                options.argentineProvince?.label ||
                "Provincia / Estado / Departamento"
              }
              input={createInput(
                ArgentineProvinceDropdown,
                locationState.argentineProvince,
                locationState.setArgentineProvince,
                options.argentineProvince?.required ?? required
              )}
            />
          ) : (
            <Labeled
              label={
                options.otherProvince?.label ||
                "Provincia / Estado / Departamento"
              }
              input={createInput(
                DelayedChangeTextInput,
                locationState.otherProvince,
                locationState.setOtherProvince,
                options.otherProvince?.required ?? required
              )}
            />
          )}
          {locationState.country !== argentinaCountryCode ||
          locationState.argentineProvince !== cabaProvinceCode ? (
            <Labeled
              label={options.locality?.label || "Localidad"}
              input={createInput(
                DelayedChangeTextInput,
                locationState.locality,
                locationState.setLocality,
                options.locality?.required ?? required
              )}
            />
          ) : null}
        </>
      ) : null}
      {locationState.detailed ? (
        <>
          <Labeled
            label={options.zipCode?.label || "Código postal"}
            input={createInput(
              DelayedChangeTextInput,
              locationState.zipCode,
              locationState.setZipCode,
              options.zipCode?.required ?? required
            )}
          />
          <Labeled
            label={options.streetLine?.label || "Calle, nº, piso, dto."}
            input={createInput(
              DelayedChangeTextInput,
              locationState.streetLine,
              locationState.setStreetLine,
              options.streetLine?.required ?? required
            )}
          />
        </>
      ) : null}
    </div>
  );
};
