/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { ppiTenant } from "./code.ts";
import { ppiFullLabel } from "./full-label.ts";

class PpiTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return ppiTenant;
  }

  getPathLabel(): string | null {
    return "ppi";
  }

  getLabel(): string {
    return "PPI";
  }

  getFullLabel(): string {
    return ppiFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "33-70090697-9";
  }

  getCnvRegistryId(): string | null {
    return "686";
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const ppiTenantHandler = new PpiTenantHandler();

export default ppiTenantHandler;
