/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { megaqmTenant } from "./code.ts";
import { megaqmFullLabel } from "./full-label.ts";
import { megaqmLabel } from "./label.ts";

class MegaqmTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return megaqmTenant;
  }

  getPathLabel(): string | null {
    return "megaqm";
  }

  getLabel(): string {
    return megaqmLabel;
  }

  getFullLabel(): string {
    return megaqmFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71212645-7";
  }
}

const megaqmTenantHandler = new MegaqmTenantHandler();

export default megaqmTenantHandler;
