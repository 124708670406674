/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  DocumentBaseData,
  firestoreDelete,
} from "./firestore-wrappers-types.ts";
import { FirestoreDocument } from "./firestore-wrappers.ts";

export const updateDocProps = async (
  document: FirestoreDocument<DocumentBaseData>,
  update: Record<string, any> // FIXME: use generics and UpdateData<T>
) => {
  await document.update(
    Object.fromEntries(
      Object.entries(update).map(([key, value]) => [
        key,
        value === undefined ? firestoreDelete() : value,
      ])
    )
  );
};
