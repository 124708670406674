/* DON'T EDIT THIS FILE: edit original and run build again */ export const multiValidator =
  (...validators: (() => string | null)[]) =>
  () => {
    for (const validator of validators) {
      if (validator) {
        const tmp = validator();
        if (tmp) {
          return tmp;
        }
      }
    }
    return null;
  };
