/* DON'T EDIT THIS FILE: edit original and run build again */ import { generateId } from "../../../framework/core/id.ts";
import { useCallback, useState } from "react";
import { ToggleButton } from "react-bootstrap";

export const CommandButton = ({
  commandName,
  enabled,
  onAction,
  children,
  active,
}: {
  commandName: string;
  enabled: boolean;
  onAction: (e: React.MouseEvent<HTMLButtonElement>, checked: boolean) => void;
  children: React.ReactNode;
  active?: boolean;
}) => {
  const handleChange = useCallback(
    (e: any) => {
      onAction?.(e, e.currentTarget.checked);
    },
    [onAction]
  );
  const [generatedId] = useState(() => generateId());
  return (
    <ToggleButton
      id={generatedId}
      disabled={!enabled}
      variant="gray80"
      size="sm"
      active={active}
      value={commandName}
      onClick={handleChange}
    >
      {children}
    </ToggleButton>
  );
};
