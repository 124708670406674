/* DON'T EDIT THIS FILE: edit original and run build again */ import { SectionContext } from "./section-context.ts";
import { Section, SectionCollection } from "./section.tsx";

export class SectionCollectionUtil<TSectionContext extends SectionContext> {
  constructor(private sectionCol: SectionCollection<TSectionContext>) {}

  private findSectionIndex(filter: {
    sectionId?: string;
    path?: string;
  }): number;

  private findSectionIndex(
    filter: { sectionId?: string; path?: string },
    errorIfNotFound: true
  ): number;

  private findSectionIndex(
    filter: { sectionId?: string; path?: string },
    errorIfNotFound: boolean
  ): number | undefined;

  private findSectionIndex(
    { sectionId, path }: { sectionId?: string; path?: string },
    errorIfNotFound = true
  ) {
    if (!sectionId && !path) {
      throw new Error("missingSelector");
    }
    const comparator = sectionId
      ? (element: Section<TSectionContext>) => element.getId() === sectionId
      : (element: Section<TSectionContext>) => element.getPath() === path;
    const index = this.sectionCol.sections.findIndex(comparator);
    if (index === -1) {
      if (errorIfNotFound) {
        throw new Error("idNotFound: " + sectionId);
      } else {
        return undefined;
      }
    }
    return index;
  }

  findAnySection(filter: {
    sectionId?: string;
    path?: string;
  }): Section<TSectionContext>;

  findAnySection(
    filter: { sectionId?: string; path?: string },
    errorIfNotFound: true
  ): Section<TSectionContext>;

  findAnySection(
    filter: { sectionId?: string; path?: string },
    errorIfNotFound: boolean
  ): Section<TSectionContext> | undefined;

  findAnySection(
    { sectionId, path }: { sectionId?: string; path?: string },
    errorIfNotFound = true
  ) {
    const index = this.findSectionIndex({ sectionId, path }, errorIfNotFound);
    if (index === undefined) {
      return undefined;
    }
    return this.sectionCol.sections[index];
  }

  getSectionPath(sectionId: string, editing = false) {
    if (!sectionId) {
      throw new Error("missingSectionId");
    }
    const item = this.findAnySection({ sectionId });
    return item.getFullPath(editing);
  }

  getPrevSectionId(sectionId: string) {
    const prevIndex = this.findSectionIndex({ sectionId }) - 1;
    if (prevIndex >= 0) {
      return this.sectionCol.sections[prevIndex].getId();
    }
    return null;
  }

  getNextSectionId(sectionId: string) {
    const nextIndex = this.findSectionIndex({ sectionId }) + 1;
    const lastIndex = this.sectionCol.sections.length - 1;
    if (nextIndex <= lastIndex) {
      return this.sectionCol.sections[nextIndex].getId();
    }
    return null;
  }
}
