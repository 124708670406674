/* DON'T EDIT THIS FILE: edit original and run build again */ import { BasicFormattingButtonGroup } from "../../framework/html-editor/button-group/basic-formatting-button-group.tsx";
import { DataTransferButtonGroup } from "../../framework/html-editor/button-group/data-transfer-button-group.tsx";
import { HeadingLevelButtonGroup } from "../../framework/html-editor/button-group/heading-level-button-group.tsx";
import { HistoryButtonGroup } from "../../framework/html-editor/button-group/history-button-group.tsx";
import { ImageButtonGroup } from "../../framework/html-editor/button-group/image-button-group.tsx";
import { LinkButtonGroup } from "../../framework/html-editor/button-group/link-button-group.tsx";
import { ListButtonGroup } from "../../framework/html-editor/button-group/list-button-group.tsx";
import { TableButtonGroup } from "../../framework/html-editor/button-group/table-button-group.tsx";
import { ButtonToolbar } from "react-bootstrap";

export const EditorToolbar = () => (
  <ButtonToolbar className="mb-1">
    <HistoryButtonGroup />
    <DataTransferButtonGroup />
    <HeadingLevelButtonGroup />
    <BasicFormattingButtonGroup />
    <LinkButtonGroup />
    <ListButtonGroup />
    <TableButtonGroup enabled={false} />
    <ImageButtonGroup />
  </ButtonToolbar>
);
