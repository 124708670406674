/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  ProofOfFciRulebookParams,
  RulebookValueOption,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import {
  emailChannelType,
  webHomeChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import {
  AFIP,
  EMAIL,
} from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";
import onboardingTermsOfUseTitle from "./onboarding-terms-of-use-title.ts";
import rulebookValueOptions from "./rulebook-value-options.tsx";

class ZofingenOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  async getTermsOfUse(): Promise<ComponentType<TermsOfUseParams>> {
    return (await import("./onboarding-terms-of-use.tsx")).default;
  }

  hasSignatura(): boolean {
    return true;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL, AFIP];
  }

  hasAune(): boolean {
    return true;
  }

  hasInvestmentProfile(): boolean {
    return false;
  }

  hasScoreInvestmentProfileSection(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [whatsappChannelType, emailChannelType, webHomeChannelType];
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }

  async getWebHomeHeader(): Promise<ComponentType<WebHomeHeaderParams>> {
    return (await import("./web-home-header.tsx")).default;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  getOpenMatbaRofexAccountDefaultValue(): undefined | "yes" | "no" {
    return "yes";
  }

  getSrgCommissionsDefaultValue(): undefined | "yes" | "no" {
    return "yes";
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  requiresAcceptingTermsAndConditions(): boolean {
    return true;
  }

  requiresAcceptingCommissions(): boolean {
    return true;
  }

  hasProofOfFciRulebookSection(): boolean {
    return true;
  }

  async getProofOfFciRulebookAfterLabel(): Promise<
    ComponentType<ProofOfFciRulebookParams>
  > {
    return (await import("./proof-of-fci-rulebook-after-label.tsx")).default;
  }

  hasRulebookValuePdfs(): boolean {
    return true;
  }

  getRulebookValueOptions(): Record<string, RulebookValueOption> {
    return rulebookValueOptions;
  }

  hasPymeTypesField(): boolean {
    return true;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "full";
  }
}

export const zofingenOnboardingOptions = new ZofingenOnboardingOptions();
