/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserPrivateProfile, UserProfile } from "../../framework/auth-profile/schema.ts";
import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import { TenantHandler } from "../../framework/tenant-handler-type/tenant-handler.ts";
import { canViewUserProfile } from "../../framework/users-profile-permissions/permissions.ts";
import { disabledUsersTypeId } from "../../framework/users/disabled-users/type.ts";
import { tenantUsersTypeId } from "../../framework/users/tenant-users/type.ts";
import { usersListTypes } from "./list-types.ts";

export const canLinkToUsersProfile = (
  userTypeId: string,
  {
    userProfile,
    loggedInProfile,
    loggedInPrivateProfile,
    loggedInRoles,
  }: {
    userProfile: UserProfile;
    loggedInProfile: UserProfile;
    loggedInPrivateProfile?: UserPrivateProfile;
    loggedInRoles: RolesMap;
  }
) =>
  [tenantUsersTypeId, disabledUsersTypeId].includes(userTypeId) &&
  canViewUserProfile(
    userProfile,
    loggedInProfile,
    loggedInPrivateProfile,
    loggedInRoles
  );

export const getPermissionsToListUsers = ({
  roles,
  tenantHandler,
}: {
  roles: RolesMap;
  tenantHandler: TenantHandler;
}) =>
  mapArrayToObject(usersListTypes, (usersListType) => [
    usersListType.id,
    usersListType.hasPermission({ roles, tenantHandler }),
  ]);

export const hasPermissionToListUsers = ({
  roles,
  tenantHandler,
}: {
  roles: RolesMap;
  tenantHandler: TenantHandler;
}) => {
  const permissions = getPermissionsToListUsers({ roles, tenantHandler });
  return Object.keys(permissions).some((key) => permissions[key]);
};
