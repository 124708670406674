/* DON'T EDIT THIS FILE: edit original and run build again */ import { getDisabledProfilesByTenantPipe } from "../../../framework/auth-profile/profile.ts";
import { DataPipe } from "../../../framework/data-pipe/data-pipe.ts";
import { joinPipe } from "../../../framework/data-pipe/join-pipe.ts";
import { Roles, RolesMap } from "../../../framework/permission/schema.ts";
import { getFilteredUserRolesListPipe } from "../../../framework/users-data-pipes/data-pipes.ts";
import { UsersListPipeFilters } from "../../../framework/users/filter-types.ts";

export const createListDisabledUsersPipe = ({
  tenant,
  filters,
  possibleRoles,
}: {
  tenant: string;
  filters: UsersListPipeFilters;
  possibleRoles: RolesMap;
}) => {
  if (!filters.showDisabled) {
    return DataPipe.withInitialData(null, []);
  }
  return joinPipe(
    getDisabledProfilesByTenantPipe(tenant),
    ({ uid }) => getFilteredUserRolesListPipe(uid, possibleRoles),
    (profile, roles) => ({ ...profile, roles })
  ).pipe((users) =>
    users.filter(({ roles }) => {
      if (!roles) {
        throw new Error("rolesRequired");
      }
      return (
        !roles.includes(Roles.pendingApproval) &&
        !roles.includes(Roles.tenantRealmPendingApproval)
      );
    })
  );
};
