/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../../framework/config/config.ts";
import { getTenantCodeFromPathLabel } from "../../framework/tenant-collection/tenant.ts";
import { getRealmHostname } from "./realm-url.ts";

const realmRegex = /^(\/r\/([a-z-]+))(\/|$)/;

export const maybeGetBasenameAndRealmFromGenericUrl = (
  location: Location,
  configGetter: () => any = getConfig
): [basename: string, realm: string] | null => {
  const { url } = configGetter();
  const genericUrl = new URL(url);
  const genericHostname = genericUrl.hostname;
  if (location.hostname === genericHostname) {
    const path = location.pathname;
    const match = realmRegex.exec(path);
    if (match) {
      const realmFromPath = getTenantCodeFromPathLabel(match[2]);
      if (
        realmFromPath &&
        getRealmHostname(realmFromPath, configGetter) === genericHostname
      ) {
        return [match[1], realmFromPath];
      }
    }
  }
  return null;
};
