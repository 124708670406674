/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserPrivateProfile } from "../../framework/auth-profile/schema.ts";
import { RequiredActionCallback } from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { verifyEmailRequiredActionId } from "../../framework/auth-verify-email-typing/required-action-type.ts";

export const isNotEmailVerified = ({
  privateProfile,
}: {
  privateProfile?: UserPrivateProfile;
}): boolean => !privateProfile?.[verifyEmailRequiredActionId];

export const shouldEnforceEmailVerify: RequiredActionCallback = (
  _props,
  _settings
) => {
  return true;
};
