/* DON'T EDIT THIS FILE: edit original and run build again */ import { ChangeEvent } from "react";
import { FormControl, FormControlProps } from "react-bootstrap";

export interface InputElementProps
  extends Omit<FormControlProps, "onChange" | "value"> {
  value?: string | null;
  setValue?: (value: string) => void;
  onChange?: (e: ChangeEvent) => void;
}
export const InputElement = ({
  as = undefined,
  type = undefined,
  value = null,
  setValue = undefined,
  onChange = undefined,
  onKeyDown = undefined,
  ...props
}: InputElementProps) => (
  <FormControl
    {...props}
    as={as}
    type={type}
    value={value ?? undefined}
    onChange={(e) => {
      if (setValue) {
        setValue(e.target.value);
      }
      if (onChange) {
        onChange(e);
      }
    }}
    onKeyDown={(e) => {
      if (onKeyDown) {
        onKeyDown(e);
      }
    }}
  />
);
