/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../../framework/html-editor/button/command-button.tsx";
import { UndoIcon } from "../../../framework/theme-icons/icon.ts";
import { useCommands, useHelpers } from "@remirror/react";
import { useCallback } from "react";

export const UndoButton = () => {
  const { undo } = useCommands();
  const { undoDepth } = useHelpers(true);

  const handleAction = useCallback(() => {
    if (undo.enabled()) {
      undo();
    }
  }, [undo]);

  const enabled = undoDepth() > 0;

  return (
    <CommandButton
      commandName="undo"
      active={false}
      enabled={enabled}
      onAction={handleAction}
    >
      <UndoIcon />
    </CommandButton>
  );
};
