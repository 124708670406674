/* DON'T EDIT THIS FILE: edit original and run build again */ export enum DeployEnvironment {
  sandbox = "sandbox",
  prod = "prod",
  dev = "dev",
  demo = "demo",
  staging = "staging",
}

export const sandboxEnvironment = DeployEnvironment.sandbox;
export const prodEnvironment = DeployEnvironment.prod;
export const devEnvironment = DeployEnvironment.dev;
export const demoEnvironment = DeployEnvironment.demo;
export const stagingEnvironment = DeployEnvironment.staging;
