/* DON'T EDIT THIS FILE: edit original and run build again */ import { Nullable } from "../../framework/core/nullable.ts";

const defaultOnDelete = () => {};

export class DataPipe<TData> {
  private _value: TData | null;
  private _listeners: Set<() => void> = new Set();
  private _loading = true;
  private _deleted = false;
  private _error: any | null = null;
  private _onDelete: () => void;
  private _description: string;
  private _creationError: Error;

  constructor(onDelete: Nullable<() => void> = null, description: string = "") {
    this._value = null;
    this._onDelete = onDelete ?? defaultOnDelete;
    this._description = description;
    // this is to identity where the pipe was created, which should help build your async stack
    this._creationError = new Error("dataPipeCreation");
  }

  static withInitialData<TData>(
    onDelete: Nullable<() => void> = null,
    initialData: TData,
    description: string = ""
  ): DataPipe<TData> {
    const pipe = new DataPipe<TData>(onDelete, description);
    pipe.setValue(initialData);
    return pipe;
  }

  static emptyPipe(description: string = ""): DataPipe<undefined> {
    const pipe = new DataPipe<undefined>(null, description);
    pipe.setValue(undefined);
    return pipe;
  }

  isLoading(): boolean {
    return this._loading;
  }

  _throwAlreadyDeleted(method: string): void {
    const error = new Error(
      "alreadyDeleted: " +
        method +
        " cannot be called for " +
        (this._description ?? "unknown")
    );
    error.cause = this._creationError;
    throw error;
  }

  isDeleted(): boolean {
    return this._deleted;
  }

  getError(): any | null {
    if (this._deleted) {
      this._throwAlreadyDeleted("getError");
    }
    return this._error;
  }

  setError(error: any): void {
    if (this._deleted) {
      this._throwAlreadyDeleted("setError");
    }
    if (this._error !== error) {
      this._error = error;
      if (this._loading) {
        this._loading = false;
      }
      this._notifyEvent();
    }
  }

  setValue(value: TData): void {
    if (this._deleted) {
      this._throwAlreadyDeleted("setValue");
    }
    if (this._value !== value || this._loading) {
      this._value = value;
      if (this._loading) {
        this._loading = false;
      }
      this._notifyEvent();
    }
  }

  getValue(): TData {
    if (this._deleted) {
      this._throwAlreadyDeleted("getValue");
    }
    // FIXME?: this class is extensively used, we should not trick possible nulls
    // as long as you waited for the loading to finish, this should be safe
    return this._value as TData;
  }

  addDataListener(callback: () => void): void {
    if (this._deleted) {
      this._throwAlreadyDeleted("addDataListener");
    }
    this._listeners.add(callback);
  }

  removeDataListener(callback: () => void): void {
    this._listeners.delete(callback);
  }

  _notifyEvent(): void {
    const copy = new Set(this._listeners);
    copy.forEach((listener) => listener());
  }

  pipe<TOtherData>(
    callback: (result: TData) => TOtherData
  ): DataPipe<TOtherData> {
    const out = new DataPipe<TOtherData>(() => this.delete());
    const update = async () => {
      if (this._error) {
        out.setError(this._error);
      } else {
        // FIXME?: this class is extensively used, we should not trick possible nulls
        out.setValue(callback(this._value as TData));
      }
    };
    this.addDataListener(update);
    if (!this._loading) {
      update();
    }
    return out;
  }

  /**
   * @param {function} errorCallback:
   *    will be called with the error and should return [newError, newValue]
   *    if newError === null then the pipe will be set to newValue instead
   */
  pipeCatch<TOtherData>(
    errorCallback: (e: any) => [any, TOtherData]
  ): DataPipe<TData | TOtherData> {
    const out = new DataPipe<TData | TOtherData>(() => this.delete());
    const update = async () => {
      if (this._error) {
        const [newError, newValue] = errorCallback(this._error);
        if (newError) {
          out.setError(newError);
        } else {
          out.setValue(newValue);
        }
      } else {
        // FIXME?: this class is extensively used, we should not trick possible nulls
        out.setValue(this._value as TData);
      }
    };
    this.addDataListener(update);
    if (!this._loading) {
      update();
    }
    return out;
  }

  pipeToPipe<TOtherData>(
    createPipeCallback: (result: TData) => DataPipe<TOtherData>,
    propagateDelete = true
  ): DataPipe<TOtherData> {
    let subPipeListener: (() => void) | null = null,
      listeningSubPipe: DataPipe<TOtherData> | null = null;
    const cleanupOldPipe = () => {
      if (subPipeListener && listeningSubPipe) {
        listeningSubPipe.removeDataListener(subPipeListener);
        listeningSubPipe.delete();
        subPipeListener = null;
        listeningSubPipe = null;
      }
    };
    const out = new DataPipe<TOtherData>(() => {
      cleanupOldPipe();
      if (propagateDelete) {
        this.delete();
      }
    });
    const update = async () => {
      if (this._error) {
        out.setError(this._error);
      } else {
        cleanupOldPipe();
        // FIXME?: this class is extensively used, we should not trick possible nulls
        const subPipe = createPipeCallback(this._value as TData);
        listeningSubPipe = subPipe;
        subPipeListener = () => {
          if (subPipe.getError()) {
            out.setError(subPipe.getError());
          } else {
            out.setValue(subPipe.getValue());
          }
        };
        subPipe.addDataListener(subPipeListener);
        if (!subPipe.isLoading()) {
          const subPipeError = subPipe.getError();
          if (subPipeError) {
            out.setError(subPipeError);
          } else {
            out.setValue(subPipe.getValue());
          }
        }
      }
    };
    this.addDataListener(update);
    if (!this._loading) {
      update();
    }
    return out;
  }

  // delete must be callable without this. (to be able to easily unsubscribe)
  delete = (): void => {
    this._deleted = true;
    if (this._onDelete) {
      this._onDelete();
    }
  };

  getDescription = (): string => {
    return this._description;
  };
}
