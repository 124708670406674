/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveField } from "./live-field.ts";
import { LiveData } from "./types.ts";

export class LiveFieldset {
  constructor(private liveFields: LiveField[]) {}

  filterOnlyVisible(liveData: LiveData): LiveFieldset {
    return this.filter((liveField) => liveField.isVisible(liveData));
  }

  filter(condition: (liveField: LiveField) => boolean): LiveFieldset {
    const filteredFieldWrappers = this.liveFields.filter((liveField) =>
      condition(liveField)
    );
    return new LiveFieldset(filteredFieldWrappers);
  }

  getLiveFields() {
    return this.liveFields;
  }

  createDefaultRecord() {
    const recordValue = {};
    for (const liveField of this.liveFields) {
      liveField.setDefaultValueToRecord(recordValue);
    }
    return recordValue;
  }
}
