/* DON'T EDIT THIS FILE: edit original and run build again */ import { TenantMatricesInput } from "../../../credit/quant-analysis-types/schema.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";

class ArgenfundsLineOptions extends BaseLineOptions {
  hasCustomQuantAnalysis(): boolean {
    return true;
  }

  async getQuantAnalysisMatrices(): Promise<TenantMatricesInput> {
    return (await import("./quant-analysis-matrices.ts")).default;
  }
}

export const argenfundsLineOptions = new ArgenfundsLineOptions();
