/* DON'T EDIT THIS FILE: edit original and run build again */ import { Sidebar } from "../../framework/theme-pages/sidebar.front.tsx";
import { Container } from "react-bootstrap";

export const PageBorder = ({
  children = null,
  footer = null,
  infoSidebar = null,
  extraHeader = null,
  current = undefined,
}: {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  infoSidebar?: React.ReactNode;
  extraHeader?: React.ReactNode;
  current?: string;
}) => {
  return (
    <div className="d-flex flex-row">
      <div className="d-print-none bg-gray20">
        <Sidebar current={current} />
      </div>
      <div className="flex-grow-1 ">
        <RawPageBorder extraHeader={extraHeader} minHeight="100vh">
          <div className="d-flex bg-gray80 align-items-stretch flex-grow-1 pb-2 overflow-x-auto">
            {infoSidebar}

            <div className="flex-grow-1 width-without-sidebar">
              <div className="py-4">
                <Container className="d-flex flex-column align-items-center">
                  {children}
                </Container>
              </div>
            </div>
          </div>
          {footer}
        </RawPageBorder>
      </div>
    </div>
  );
};

export const SimplePageBorder = ({
  children,
  extraHeader,
}: {
  children: React.ReactNode;
  extraHeader?: React.ReactNode;
}) => (
  <div className="d-flex flex-row">
    <div className="flex-grow-1 ">
      <RawPageBorder extraHeader={extraHeader} minHeight="100vh">
        <div className="d-flex bg-gray80 align-items-stretch flex-grow-1 pb-2 overflow-x-auto">
          <div className="flex-grow-1">
            <div className="py-4">
              <Container className="d-flex flex-column align-items-center">
                {children}
              </Container>
            </div>
          </div>
        </div>
      </RawPageBorder>
    </div>
  </div>
);

export const RawPageBorder = ({
  children,
  extraHeader,
  height = undefined,
  minHeight = undefined,
}: {
  children: React.ReactNode;
  extraHeader?: React.ReactNode;
  height?: string;
  minHeight?: string;
}) => {
  return (
    <div
      className="d-flex flex-column text-gray10 align-items-stretch"
      style={{ height, minHeight }}
    >
      <div
        className="d-print-none"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 999,
        }}
      >
        {extraHeader ? extraHeader : null}
      </div>
      {children}
    </div>
  );
};

export const FixedHeightPageBorder = ({
  children,
  extraHeader,
  current,
}: {
  children: React.ReactNode;
  extraHeader: React.ReactNode;
  current: string;
}) => (
  <div className="d-flex flex-row">
    <div className="d-print-none bg-gray20">
      <Sidebar current={current} />
    </div>

    <div className="flex-grow-1">
      <RawPageBorder extraHeader={extraHeader} height="100vh">
        {children}
      </RawPageBorder>
    </div>
  </div>
);
