/* DON'T EDIT THIS FILE: edit original and run build again */ import { RoleRequestStatus } from "../../../framework/auth-role-requests/schema.ts";
import { descSorter } from "../../../framework/core/sort-by-many.ts";
import { RolesMap } from "../../../framework/permission/schema.ts";
import { usersListDefaultSorters } from "../../../framework/users/list-sorters.ts";
import { createApproveEndClientUsersPipe } from "./data-pipe.ts";
import { userHasPermissionToListApproveEndClientsUsers } from "./permissions.ts";

export const approveEndClientUsersTypeId = "approveEndClients";

export const approveEndClientUsersType = {
  id: approveEndClientUsersTypeId,
  hasPermission: ({ roles }: { roles: RolesMap }) =>
    userHasPermissionToListApproveEndClientsUsers(roles),
  dataPipe: createApproveEndClientUsersPipe,
  sorters: [
    ...Object.values(RoleRequestStatus).map((status) =>
      descSorter((user: any) => (user.roleRequest.status === status ? 1 : 0))
    ),
    ...usersListDefaultSorters,
  ],
};
