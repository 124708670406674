/* DON'T EDIT THIS FILE: edit original and run build again */ import { RolesMap } from "../../../framework/permission/schema.ts";
import { UserListType } from "../../../framework/users/user-list-type.ts";
import { createListDisabledUsersPipe } from "./data-pipe.ts";
import { userHasPermissionToListDisabledUsers } from "./permissions.ts";

export const disabledUsersTypeId = "disabledUsers";

export const disabledUsersType: UserListType = {
  id: disabledUsersTypeId,
  hasPermission: ({ roles }: { roles: RolesMap }) =>
    userHasPermissionToListDisabledUsers(roles),
  dataPipe: createListDisabledUsersPipe as any,
};
