/* DON'T EDIT THIS FILE: edit original and run build again */ import { FormControl } from "react-bootstrap";
import { NumberFormatValues, NumericFormat } from "react-number-format";

export type NumberFormControlProps = {
  setValue: (value: number | null) => void;
  value: number | null;
  disabled?: boolean;
  autoComplete?: string;
  "data-name"?: string;
  className?: string;
  decimalScale?: number;
  isAllowed?: (values: NumberFormatValues) => boolean;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  size?: "sm" | "lg" | undefined;
  prefix?: string;
  suffix?: string;
  getInputRef?: ((el: HTMLInputElement) => void) | React.Ref<any>;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const NumberFormControl = ({
  setValue,
  value,
  onKeyDown,
  ...props
}: NumberFormControlProps) => (
  <FormControl
    {...props}
    as={NumericFormat}
    thousandSeparator="."
    decimalSeparator=","
    value={value ?? ""}
    onValueChange={({ floatValue }: { floatValue: number | undefined }) => {
      // if the value is not a number, pass null
      const numberToPass =
        floatValue === undefined || isNaN(floatValue) ? null : floatValue;
      if (setValue) {
        setValue(numberToPass);
      }
    }}
    onKeyDown={(e) => {
      if (onKeyDown) {
        onKeyDown(e);
      }
    }}
  />
);
