/* DON'T EDIT THIS FILE: edit original and run build again */ import { WithId } from "../../framework/core/with-id.ts";
import { FirestoreDocumentData } from "../../framework/firebase/firestore-wrappers-types.ts";
import {
  FirestoreCollection,
  FirestoreDocument,
  createFirestoreCollection,
} from "../../framework/firebase/firestore-wrappers.ts";

export const firstTimePopupsColName = "firstTimePopups";

export type FirstTimePopups = FirestoreDocumentData<{
  dontShowAgain: { [modalId: string]: boolean };
}>;

export type UserFirstTimePopups = WithId<FirstTimePopups, "uid">;

const getFirstTimePopupsCol = (): FirestoreCollection<FirstTimePopups> =>
  createFirestoreCollection<FirstTimePopups>(firstTimePopupsColName);

export const getFirstTimePopupsDoc = (
  uid: string
): FirestoreDocument<FirstTimePopups> => getFirstTimePopupsCol().doc(uid);
