/* DON'T EDIT THIS FILE: edit original and run build again */ import { RequiredActionCallback } from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { requiredActionsSettingId } from "../../framework/auth-required-actions-typing/settings-id.ts";
import {
  enforceVerifyConformanceToCurrentPolicyId,
  verifyConformanceToCurrentPolicyRequiredActionId,
} from "../../framework/auth-verify-conformance-typing/required-action-type.ts";

export const isNotConformanceVerified: RequiredActionCallback = ({
  privateProfile,
}) => !!privateProfile?.[verifyConformanceToCurrentPolicyRequiredActionId];

export const shouldEnforceConformanceVerify: RequiredActionCallback = (
  { currentTenant },
  settings
) =>
  settings?.[requiredActionsSettingId]?.[currentTenant]?.[
    enforceVerifyConformanceToCurrentPolicyId
  ];
