/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  canApproveAssignedFoldersEndClientRoleRequests,
  canApproveEndClientRoleRequests,
  canApproveProducerFolderEndClientRoleRequests,
} from "../../../framework/permission/permissions.ts";
import { RolesMap } from "../../../framework/permission/schema.ts";
import { userHasPermission } from "../../../framework/permission/user-has-permission.ts";

export const userHasPermissionToListApproveEndClientsUsers = (
  roles: RolesMap
) =>
  userHasPermission(roles, canApproveEndClientRoleRequests) ||
  userHasPermission(roles, canApproveProducerFolderEndClientRoleRequests) ||
  userHasPermission(roles, canApproveAssignedFoldersEndClientRoleRequests);
