/* DON'T EDIT THIS FILE: edit original and run build again */ import { BankTenantHandler } from "../../../framework/tenant-handlers/bank-tenant-handler.ts";
import { bancocomafiTenant } from "./code.ts";
import { bancocomafiFullLabel } from "./full-label.ts";
import { bancocomafiLabel } from "./label.ts";

class BancococomafiTenantHandler extends BankTenantHandler {
  getCode(): string {
    return bancocomafiTenant;
  }

  getPathLabel(): string | null {
    return "banco-comafi";
  }

  getLabel(): string {
    return bancocomafiLabel;
  }

  getFullLabel(): string {
    return bancocomafiFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-60473101-8";
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const bancocomafiTenantHandler = new BancococomafiTenantHandler();

export default bancocomafiTenantHandler;
