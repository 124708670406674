/* DON'T EDIT THIS FILE: edit original and run build again */ import { entityFieldDateOfBirth } from "../../credit/entity/inline-entity-type.ts";
import { DateLiveField } from "../../credit/live-form-core-types/date/live-field.ts";
import { LiveField } from "../../credit/live-form/live-field.ts";
import { onlyPeople } from "./condition.ts";

const createDateOfBirthStructure = (isRequired: boolean): LiveField[] => [
  new DateLiveField({
    label: "Fecha de nacimiento",
    field: entityFieldDateOfBirth,
    required: isRequired,
    condition: onlyPeople,
  }),
];

export const optionalDateOfBirthStructure = createDateOfBirthStructure(false);
export const requiredDateOfBirthStructure = createDateOfBirthStructure(true);
