/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { dracmaTenant } from "./code.ts";
import { dracmaFullLabel } from "./full-label.ts";
import { dracmaLabel } from "./label.ts";

class DracmaTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return dracmaTenant;
  }

  getPathLabel(): string | null {
    return "dracma";
  }

  getLabel(): string {
    return dracmaLabel;
  }

  getFullLabel(): string {
    return dracmaFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "compliance@dracma.com.ar";
  }

  getCuit(): string {
    return "30-70887190-3";
  }

  getCnvRegistryId(): string | null {
    return "201";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const dracmaTenantHandler = new DracmaTenantHandler();

export default dracmaTenantHandler;
