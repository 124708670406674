/* DON'T EDIT THIS FILE: edit original and run build again */ import { useCallback, useEffect, useState } from "react";

type UseDelayedChangeTextInputParam = {
  value: string;
  onEnterPress?: () => void;
  setValue: (value: string) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

export const useDelayedChangeTextInputState = ({
  value,
  setValue: originalSetValue,
  onEnterPress = undefined,
  onBlur: originalOnBlur = undefined,
  onFocus: originalOnFocus = undefined,
  onKeyDown: originalOnKeyDown = undefined,
}: UseDelayedChangeTextInputParam) => {
  const [focused, setFocused] = useState(false);
  const [editingValue, setEditingValue] = useState({
    oldValue: value,
    editingValue: value,
  });
  const [valueWhenEnterWasPressed, setValueWhenEnterWasPressed] = useState(
    null as null | string
  );

  const maybePropagateChange = useCallback(
    (ignoreFocus = false) => {
      if (
        (!focused || ignoreFocus) &&
        editingValue.editingValue !== editingValue.oldValue
      ) {
        originalSetValue(editingValue.editingValue);
      }
    },
    [
      focused,
      editingValue.editingValue,
      editingValue.oldValue,
      originalSetValue,
    ]
  );

  // this is complicated because we don't have an easy way to track when was the state change
  //   finally applied, and we shouldn't dispatch the event before the value is already propagated
  useEffect(() => {
    if (
      onEnterPress &&
      valueWhenEnterWasPressed !== null &&
      valueWhenEnterWasPressed === editingValue.oldValue
    ) {
      setValueWhenEnterWasPressed(null);
      onEnterPress();
    }
  }, [onEnterPress, valueWhenEnterWasPressed, editingValue.oldValue]);

  const onFocus = (event: React.FocusEvent) => {
    setFocused(true);
    if (originalOnFocus) {
      originalOnFocus(event);
    }
  };

  const onBlur = (event: React.FocusEvent) => {
    setFocused(false);
    if (originalOnBlur) {
      originalOnBlur(event);
    }
  };

  const setValue = (newValue: string) => {
    setEditingValue({ oldValue: value, editingValue: newValue });
  };

  useEffect(() => {
    if (editingValue.oldValue !== value) {
      setEditingValue({ oldValue: value, editingValue: value });
    }
  }, [editingValue.oldValue, value]);

  useEffect(maybePropagateChange, [maybePropagateChange]);

  return {
    value: editingValue.editingValue,
    onBlur,
    setValue,
    onFocus,
    onKeyDown: (event: React.KeyboardEvent) => {
      if (event.code === "Enter") {
        maybePropagateChange(true);
        setValueWhenEnterWasPressed(editingValue.editingValue);
      }
      if (originalOnKeyDown) {
        originalOnKeyDown(event);
      }
    },
  };
};
