/* DON'T EDIT THIS FILE: edit original and run build again */ import { Jsonable } from "../../framework/core/jsonable.ts";
import { deletePropertyDeleter } from "./delete-property-deleter.ts";
import { pathUpdate } from "./path.ts";

export const updateObjectProps = (
  object: any,
  update: Record<string, Jsonable>,
  deleter: (
    current: Record<string, Jsonable>,
    path: string
  ) => void = deletePropertyDeleter
) => {
  for (const [path, value] of Object.entries(update)) {
    pathUpdate(object, path, value, deleter);
  }
};
