/* DON'T EDIT THIS FILE: edit original and run build again */ import { ColorPalette } from "../../../framework/tenant-handler-type/color-pallete-type.ts";
import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { ugTenant } from "./code.ts";
import { ugFullLabel } from "./full-label.ts";
import { ugLabel } from "./label.ts";
import { ugOnboardingOptions } from "./ug-valores-onboarding-options.ts";

class UgTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_ugvalores.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return ugTenant;
  }

  getPathLabel(): string | null {
    return "ugvalores";
  }

  getLabel(): string {
    return ugLabel;
  }

  getFullLabel(): string {
    return ugFullLabel;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return ugOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@ugvalores.com.ar";
  }

  getComplianceEmailAddress(): string {
    return "info@ugvalores.com.ar";
  }

  getCuit(): string {
    return "30-71631517-3";
  }

  getCnvRegistryId(): string | null {
    return "1039";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  getColors(): ColorPalette {
    return { primary: "#262773", secondary: "#26274F", tertiary: "#262773" };
  }
}

const ugTenantHandler = new UgTenantHandler();

export default ugTenantHandler;
