/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../../framework/config/config.ts";

const getFirebaseStartPort = () => {
  const { firebaseStartPort } = getConfig();
  return firebaseStartPort as number;
};

export const getFirebaseHostingPort = () => getFirebaseStartPort();

export const getFirebaseUiPort = () => getFirebaseStartPort() + 1;

export const getFirebaseFirestorePort = () => getFirebaseStartPort() + 2;

export const getFirebaseFirestoreWebsocketPort = () =>
  getFirebaseStartPort() + 3;

export const getFirebaseStoragePort = () => getFirebaseStartPort() + 4;

export const getFirebaseFunctionsPort = () => getFirebaseStartPort() + 5;

export const getFirebaseAuthPort = () => getFirebaseStartPort() + 6;

export const getFirebaseHubPort = () => getFirebaseStartPort() + 7;

export const getFirebaseLoggingPort = () => getFirebaseStartPort() + 8;

export const getFirebaseEventarcPort = () => getFirebaseStartPort() + 9;
