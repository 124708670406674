/* DON'T EDIT THIS FILE: edit original and run build again */ export const insertInString = (
  str: string,
  idx: number,
  value: string
): string => {
  if (idx > str.length) {
    return str;
  }
  return str.substring(0, idx) + value + str.substring(idx + value.length);
};
