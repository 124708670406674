/* DON'T EDIT THIS FILE: edit original and run build again */ export function mapObject<T, U>(
  obj: Record<string, T>,
  callback: ([key, value]: [string, T]) => [string, U]
): Record<string, U> {
  const out: Record<string, U> = {};
  for (const key in obj) {
    const [newKey, newValue] = callback([key, obj[key]]);
    out[newKey] = newValue;
  }
  return out;
}
