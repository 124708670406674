/* DON'T EDIT THIS FILE: edit original and run build again */ import { Jsonable } from "../../framework/core/jsonable.ts";
import { updateObjectProps } from "../../framework/dotpath/update-object-props.ts";
import { DocumentBaseData } from "./firestore-wrappers-types.ts";
import { FirestoreDocument } from "./firestore-wrappers.ts";
import { updateDocProps } from "./update-doc-props.ts";

export enum SaveMode {
  SaveToDatabase = "saveToDatabaseMode",
  SaveToObject = "saveToObjectMode",
}

export type GetDoc = (id: string) => FirestoreDocument<DocumentBaseData>;
export type GetDocId = (object: any) => string;

export function updateDocOrObjectProps(
  object: any,
  update: Record<string, Jsonable>,
  getDoc: GetDoc,
  mode: SaveMode.SaveToDatabase,
  getDocId?: GetDocId
): Promise<void>;

export function updateDocOrObjectProps(
  object: any,
  update: Record<string, Jsonable>,
  getDoc: GetDoc,
  mode: SaveMode.SaveToObject,
  getDocId?: GetDocId
): void;

export function updateDocOrObjectProps(
  object: any,
  update: Record<string, Jsonable>,
  getDoc: GetDoc,
  mode?: SaveMode,
  getDocId?: GetDocId
): Promise<void> | void;

export function updateDocOrObjectProps(
  object: any,
  update: Record<string, Jsonable>,
  getDoc: GetDoc,
  mode = SaveMode.SaveToDatabase,
  getDocId: GetDocId = (object) => object.id
) {
  if (mode === SaveMode.SaveToDatabase) {
    const id = getDocId(object);
    if (!id) {
      throw new Error("missingIdForUpdateDocOrObjectProps");
    }
    return updateDocProps(getDoc(id), update);
  } else if (mode === SaveMode.SaveToObject) {
    return updateObjectProps(object, update);
  } else {
    throw new Error("unknownSaveMode");
  }
}
