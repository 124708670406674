/* DON'T EDIT THIS FILE: edit original and run build again */ // @ts-expect-error the hook is auto generated
import hookRenderers from "../../hook/live-form-renderer-collect.js";
import { LiveFieldRenderer } from "../../credit/live-form/ui/types.ts";
import {
  liveFieldRenderers,
  registerLiveFieldRenderer,
} from "./type-renderers.ts";

let collected = false;

const collectLiveFormRenderers = () => {
  if (collected) {
    return;
  }
  collected = true;
  for (const renderers of hookRenderers) {
    for (const renderer of renderers) {
      registerLiveFieldRenderer(renderer);
    }
  }
};

export const getLiveFormInputFactory = (
  fieldType: string
): LiveFieldRenderer => {
  collectLiveFormRenderers();
  if (!(fieldType in liveFieldRenderers)) {
    throw new Error("unknownFieldType: " + fieldType);
  }
  return liveFieldRenderers[fieldType];
};
