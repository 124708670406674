/* DON'T EDIT THIS FILE: edit original and run build again */ /* eslint-disable max-lines */
import { EntityType } from "../../credit/gov-id-number/entity-type.ts";
import {
  OnboardingOptions,
  ProofOfFciRulebookParams,
  RulebookValueOption,
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../framework/tenant-handler-type/onboarding-options.ts";
import { EMAIL } from "../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";

export class BaseOnboardingOptions implements OnboardingOptions {
  hasSignatura(): boolean {
    return false;
  }

  hasEsco(): boolean {
    return false;
  }

  hasAune(): boolean {
    return false;
  }

  hasRevenueVolume(): boolean {
    return true;
  }

  hasGenderField(): boolean {
    return true;
  }

  hasVideoSelfie(): boolean {
    return true;
  }

  hasSnailMail(): boolean {
    return true;
  }

  hasFullGeneralInfoSection(): boolean {
    return true;
  }

  hasShareholders(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return true;
  }

  hasOwnedEntityField(): boolean {
    return true;
  }

  finalBeneficiariesPercentage(): number {
    return 10;
  }

  usesUruguayLaw(): boolean {
    return false;
  }

  hasAccounts(): boolean {
    return true;
  }

  hasInvestmentProfile(): boolean {
    return true;
  }

  hasScoreInvestmentProfileSection(): boolean {
    return false;
  }

  canSeeDetailAtScoreInvestmentProfile(): boolean {
    return false;
  }

  hasRelatedEntitiesSection(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  getSupportedChannelTypes(): string[] {
    return [];
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este Mail será detallado en Caja de Valores - Inversores (consulta online de " +
      "saldos y movimientos de la cuenta corriente) y para enviar información sobre " +
      "movimientos y/o resúmenes de cuenta (régimen informativo)"
    );
  }

  useSnailMailViaEmail(): boolean {
    return true;
  }

  getSnailMailSignatureAddress(): string | null {
    return null;
  }

  supportsChannelType(channelType: string): boolean {
    return this.getSupportedChannelTypes().includes(channelType);
  }

  supportsWebHomeChannelAccessType(): boolean {
    return false;
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [];
  }

  async getEmailHeader(): Promise<ComponentType<Record<string, never>>> {
    return () => null;
  }

  async getWebHomeHeader(): Promise<ComponentType<WebHomeHeaderParams>> {
    if (this.supportsWebHomeChannelAccessType()) {
      throw new Error("onboardingOptionsMissingWebHomeHeader");
    }
    return () => null;
  }

  hasSwornStatementAboutLegality(): boolean {
    return false;
  }

  hasProofOfFciRulebookSection(): boolean {
    return false;
  }

  hasProofOfFciRulebookContent(): boolean {
    return false;
  }

  async getProofOfFciRulebookContent(): Promise<
    ComponentType<ProofOfFciRulebookParams>
  > {
    if (
      this.hasProofOfFciRulebookSection() ||
      this.hasProofOfFciRulebookContent()
    ) {
      throw new Error("onboardingOptionsMissingProofOfFciRulebook");
    }
    return () => null;
  }

  async getProofOfFciRulebookAfterLabel(): Promise<
    ComponentType<ProofOfFciRulebookParams>
  > {
    if (this.hasProofOfFciRulebookSection()) {
      throw new Error("onboardingOptionsMissingProofOfFciRulebook");
    }
    return () => null;
  }

  getRulebookValueOptions(): Record<string, RulebookValueOption> {
    if (this.hasProofOfFciRulebookSection()) {
      throw new Error("onboardingOptionsMissingProofOfFciRulebook");
    }
    return {};
  }

  hasEstimatedAmountToInvest(): boolean {
    return false;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "simple";
  }

  hasMatbaRofex(): boolean {
    return true;
  }

  getOpenMatbaRofexAccountDefaultValue(): undefined | "yes" | "no" {
    return undefined;
  }

  hasSgrComissions(): boolean {
    return true;
  }

  getSrgCommissionsDefaultValue(): undefined | "yes" | "no" {
    return undefined;
  }

  getBeneficiaryEntityType(): string {
    return EntityType.Any;
  }

  hasCorporateAdminSection(): boolean {
    return false;
  }

  hasOnboardingCoholdersSection(): boolean {
    return true;
  }

  hasTaxStatusOfRepresentatives(): boolean {
    return true;
  }

  hasOptionToNotUploadDocuments(): boolean {
    return true;
  }

  hasMutualGuaranteeContract(): boolean {
    return false;
  }

  hasTerms(): boolean {
    return true;
  }

  async getTermsOfUse(): Promise<ComponentType<TermsOfUseParams>> {
    if (this.hasTerms()) {
      throw new Error("onboardingOptionsMissingTermsOfUse");
    }
    return () => null;
  }

  getTermsOfUseTitle(): string {
    if (this.hasTerms()) {
      throw new Error("onboardingOptionsMissingTermsOfUse");
    }
    return "";
  }

  hasSwornStatement(): boolean {
    return false;
  }

  hasBeneficiaryCanBePublic(): boolean {
    return true;
  }

  hasBeneficiaryDoc(): boolean {
    return true;
  }

  hasIsQualifiedInvestorText(): boolean {
    return false;
  }

  allowPersonalAccount(): boolean {
    return true;
  }

  allowForeignCompanies(): boolean {
    return true;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return () => null;
  }

  usesFinancialGuarantee(): boolean {
    return false;
  }

  getOpenAccountAgreementLabel(): string {
    return "Convenio de apertura";
  }

  getOnboardingLabel(): string {
    return "Apertura de Cuenta";
  }

  getOnboardingMailLabel(): string {
    return "apertura de cuenta comitente";
  }

  canBackupOnboardings(): boolean {
    return false;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return false;
  }

  hasPositionFieldRequired(): boolean {
    return false;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return true;
  }

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "automatic";
  }

  requiresAcceptingTermsAndConditions(): boolean {
    return false;
  }

  requiresAcceptingCommissions(): boolean {
    return false;
  }

  hasPymeTypesField(): boolean {
    return false;
  }

  hasOperationsInUsField(): boolean {
    return true;
  }
}
