/* DON'T EDIT THIS FILE: edit original and run build again */ import { assignedEntitiesColName } from "../../credit/assigned-entities/col-name.ts";
import { AssignedEntities } from "../../credit/assigned-entities/schema.ts";
import { WithId } from "../../framework/core/with-id.ts";
import { fetchDoc } from "../../framework/firebase/fetch-doc.ts";
import {
  FirestoreCollection,
  FirestoreDocument,
  createFirestoreCollection,
} from "../../framework/firebase/firestore-wrappers.ts";

export const getAssignedEntitiesCol =
  (): FirestoreCollection<AssignedEntities> =>
    createFirestoreCollection<AssignedEntities>(assignedEntitiesColName);

export const getAssignedEntitiesDoc = (
  uid: string
): FirestoreDocument<AssignedEntities> => getAssignedEntitiesCol().doc(uid);

export const fetchAssignedEntities = async (
  uid: string
): Promise<WithId<AssignedEntities, "uid"> | undefined> =>
  fetchDoc(getAssignedEntitiesDoc(uid), "uid");
