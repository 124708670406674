/* DON'T EDIT THIS FILE: edit original and run build again */ export const linesPath = "/carpetas";
export const lineFullInfoPath = "/info-completa";

export const getRelativeLineUrl = (lineId: string): string => {
  return linesPath + "/" + lineId;
};

export const getRelativeLineFullInfoPageUrl = (lineId: string): string => {
  return lineFullInfoPath + "/" + lineId;
};
