/* DON'T EDIT THIS FILE: edit original and run build again */ import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

type UserDialogOptions = {
  type: "alert" | "confirm";
  okLabel?: React.ReactNode;
  onResult?: CallbackFunction;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  text?: React.ReactNode;
  cancelLabel?: React.ReactNode;
};

type UserDialogOptionsOrNull = UserDialogOptions | null;

type DialogOptions = UserDialogOptions & {
  resolve: CallbackFunction;
};

type DialogOptionsOrNull = DialogOptions | null;

// keep a set just not to depend on the specific order of useEffect
const popupProviders: Set<(dialogOptions: DialogOptionsOrNull) => void> =
  new Set();

type CallbackFunction = (param: any) => void;

export const AlertProvider = () => {
  const [popup, setPopup] = useState(null as DialogOptionsOrNull);
  const okButtonRef = useRef(null as HTMLButtonElement | null);
  useEffect(() => {
    popupProviders.add(setPopup);
    return () => {
      popupProviders.delete(setPopup);
    };
  }, [setPopup]);
  const resolve = (result: any) => {
    setPopup(null);
    if (popup) {
      if (popup.onResult) {
        popup.onResult(result);
      }
      popup.resolve(result);
    }
  };

  return (
    <Modal
      animation={true}
      centered
      onEntered={() => {
        if (okButtonRef.current) {
          okButtonRef.current.focus();
        }
      }}
      show={!!popup}
      onEscapeKeyDown={
        popup
          ? () => {
              resolve(false);
            }
          : undefined
      }
      onHide={
        popup
          ? () => {
              resolve(false);
            }
          : undefined
      }
    >
      {popup ? (
        <div className={popup.className}>
          {popup.title ? (
            <Modal.Header>
              <Modal.Title>{popup.title}</Modal.Title>
            </Modal.Header>
          ) : null}
          <Modal.Body>
            <div className="mb-3 text-pre-wrap">{popup.text}</div>
            <div className="d-flex justify-content-end flex-gap-2">
              {popup.type === "confirm" ? (
                <Button
                  className="me-2"
                  onClick={() => {
                    resolve(false);
                  }}
                  variant="outline-blue50"
                >
                  {popup.cancelLabel}
                </Button>
              ) : null}

              <Button
                className="me-2"
                ref={okButtonRef}
                onClick={() => {
                  resolve(true);
                }}
                variant="blue50"
              >
                {popup.okLabel}
              </Button>
            </div>
            {popup.footer}
          </Modal.Body>
        </div>
      ) : null}
    </Modal>
  );
};

const setAlert = (value: UserDialogOptionsOrNull) =>
  new Promise((resolve) => {
    for (const provider of popupProviders) {
      if (value) {
        provider({ ...value, resolve });
      } else {
        provider(null);
      }
      return;
    }
    throw new Error("noPopupProvider");
  });

export const alerter = {
  alert: (
    text: React.ReactNode,
    {
      okLabel = "Aceptar",
      onResult = undefined,
      title,
      footer,
      className = "",
    }: {
      okLabel?: React.ReactNode;
      onResult?: CallbackFunction;
      title?: React.ReactNode;
      footer?: React.ReactNode;
      className?: string;
    } = {}
  ) =>
    setAlert({
      type: "alert",
      text,
      okLabel,
      onResult,
      title,
      footer,
      className,
    }),
  // warning: this won't resolve the promise
  abort: () => setAlert(null),
  confirm: (
    text: React.ReactNode,
    {
      okLabel = "Aceptar",
      cancelLabel = "Cancelar",
      title = null,
      className = "",
      onResult = undefined,
    }: {
      okLabel?: React.ReactNode;
      cancelLabel?: React.ReactNode;
      title?: React.ReactNode;
      className?: string;
      onResult?: CallbackFunction;
    } = {}
  ) =>
    setAlert({
      type: "confirm",
      text,
      okLabel,
      cancelLabel,
      title,
      className,
      onResult,
    }),
};
