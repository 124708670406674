/* DON'T EDIT THIS FILE: edit original and run build again */ import { DataPipe } from "./data-pipe.ts";

function findReturnValue<TArrayElem, TCallBackReturn>(
  arr: TArrayElem[],
  callback: (elem: TArrayElem) => TCallBackReturn
): TCallBackReturn | null {
  for (const item of arr) {
    const result = callback(item);
    if (result !== null && result !== undefined) {
      return result;
    }
  }
  return null;
}

/**
 * Combine the pipes of pipesByProp into a single DataPipe with same keys as the map, but with the values of the pipes
 *
 * @param {Object} pipesByProp: an object with prop names as keys and pipes as values (or falsy to skip)
 * @returns {DataPipe} a pipe that outputs an object with the same keys as pipesByProp, but with the values of the pipes
 */
export const combinePipesAsObject = <T = { [k: string]: any }>(pipesByProp: {
  [K in keyof T]: DataPipe<T[K]>;
}): DataPipe<T> => {
  const validPipes = Object.values<DataPipe<T[keyof T]>>(pipesByProp);
  const out = new DataPipe<T>(() =>
    validPipes.forEach((pipe) => pipe.delete())
  );
  const someIsLoading = () => validPipes.some((pipe) => pipe.isLoading());
  const getSomeError = () =>
    findReturnValue(validPipes, (pipe: DataPipe<T[keyof T]>) =>
      pipe.getError()
    );
  const update = () => {
    if (someIsLoading()) {
      return;
    }
    const error = getSomeError();
    if (error) {
      out.setError(error);
    } else {
      const value = {} as T;
      for (const [prop, pipe] of Object.entries<DataPipe<T[keyof T]>>(
        pipesByProp
      )) {
        value[prop as keyof T] = pipe.getValue();
      }
      out.setValue(value);
    }
  };
  for (const pipe of validPipes) {
    pipe.addDataListener(update);
  }
  if (!someIsLoading()) {
    update();
  }
  return out;
};
