/* DON'T EDIT THIS FILE: edit original and run build again */ import { Form } from "react-bootstrap";

// to avoid warning of no onchange (we're tracking the change with the onClick anyway)
const nothing = () => null;

export const ToggleableRadioButton = ({
  value,
  setValue,
  autoFocus,
  disabled,
}: {
  value: boolean;
  setValue: (value: boolean) => void;
  autoFocus?: boolean;
  disabled?: boolean;
}) => (
  <Form.Check.Input
    autoFocus={autoFocus}
    onClick={() => {
      setValue(!value);
    }}
    onChange={nothing}
    checked={value}
    disabled={disabled}
    type="radio"
  />
);
