/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserProfile } from "../../framework/auth-profile/schema.ts";
import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { colPipe, docPipe } from "../../framework/firebase/firestore-pipe.ts";
import {
  canListAllAssignedEntities,
  canListProducerAssignedEntities,
  canViewOwnAssignedEntities,
} from "../../framework/permission/permissions.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import {
  getAssignedEntitiesCol,
  getAssignedEntitiesDoc,
} from "./assigned-entities.ts";
import { AssignedEntitiesEntityIdTypes } from "./schema.ts";

export enum AssignedEntitiesListTypes {
  all,
  partial,
  none,
}

export type AssignedEntitiesListPartial = {
  type: AssignedEntitiesListTypes.partial;
  entityIds: string[]; // unique
};
export type AssignedEntitiesList =
  | AssignedEntitiesListPartial
  | { type: AssignedEntitiesListTypes.all | AssignedEntitiesListTypes.none };

const createAllAssignedEntitiesListPipe = (): DataPipe<AssignedEntitiesList> =>
  DataPipe.withInitialData(null, { type: AssignedEntitiesListTypes.all });

const createProducerAssignedEntitiesListPipe = (
  tenant: string
): DataPipe<AssignedEntitiesList> =>
  colPipe(
    getAssignedEntitiesCol()
      .where("tenant", "==", tenant)
      .where("assignedAsProducerCount", ">", 0),
    "uid",
    "producerAssignedEntitiesPipe"
  ).pipe((collection) => {
    const map: Record<string, AssignedEntitiesEntityIdTypes> = {};
    for (const doc of collection) {
      Object.assign(map, doc.entityIds);
    }
    return {
      type: AssignedEntitiesListTypes.partial,
      entityIds: Object.keys(map).filter(
        (key) => map[key] === AssignedEntitiesEntityIdTypes.producer
      ),
    };
  });

const createOwnAssignedEntitiesListPipe = (
  uid: string
): DataPipe<AssignedEntitiesList> =>
  docPipe(getAssignedEntitiesDoc(uid), "uid").pipe((assigned) => ({
    type: AssignedEntitiesListTypes.partial,
    entityIds: Object.keys(assigned?.entityIds ?? {}),
  }));

const createNoneAssignedEntitiesListPipe = (): DataPipe<AssignedEntitiesList> =>
  DataPipe.withInitialData(null, { type: AssignedEntitiesListTypes.none });

export const createAssignedEntitiesListPipe = (
  profile: UserProfile,
  roles: RolesMap,
  tenant: string
): DataPipe<AssignedEntitiesList> => {
  if (userHasPermission(roles, canListAllAssignedEntities)) {
    return createAllAssignedEntitiesListPipe();
  } else if (userHasPermission(roles, canListProducerAssignedEntities)) {
    return createProducerAssignedEntitiesListPipe(tenant);
  } else if (userHasPermission(roles, canViewOwnAssignedEntities)) {
    return createOwnAssignedEntitiesListPipe(profile.uid);
  } else {
    return createNoneAssignedEntitiesListPipe();
  }
};
