/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveData } from "../../../credit/live-form/types.ts";
import {
  BaseTextLiveField,
  BaseTextLiveFieldSpec,
} from "../../../credit/live-form/ui/base-text-live-field-spec.tsx";
import { passwordLiveFieldType } from "./type.ts";

export class PasswordLiveField extends BaseTextLiveField {
  constructor(spec: BaseTextLiveFieldSpec) {
    super(spec, passwordLiveFieldType);
  }

  isEmpty(liveData: LiveData) {
    return !this.getValue(liveData);
  }
}
