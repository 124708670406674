/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../../framework/core/not-null.ts";
import { getEndClientId } from "../../framework/end-client/data.ts";
import { EndClientEntity } from "../../framework/end-client/schema.ts";
import { fetchDoc } from "../../framework/firebase/fetch-doc.ts";
import { firestoreArrayUnion } from "../../framework/firebase/firestore-wrappers-types.ts";
import {
  FirestoreCollection,
  FirestoreDocument,
  createFirestoreCollection,
} from "../../framework/firebase/firestore-wrappers.ts";
import { now } from "../../framework/now/now.ts";
import {
  endClientAccessRequestsCol,
  endClientAccessRequestsSummaryCol,
} from "./col-name.ts";
import {
  EndClientAccessRequest,
  EndClientAccessRequestStatus,
  EndClientAccessRequestSummary,
} from "./schema.ts";

export const getEndClientAccessRequestId = (
  tenant: string,
  entityId: string,
  uid: string
): string => uid + "-" + getEndClientId(tenant, entityId);

export const getEndClientAccessRequestsCol =
  (): FirestoreCollection<EndClientAccessRequest> =>
    createFirestoreCollection<EndClientAccessRequest>(
      endClientAccessRequestsCol
    );

export const getEndClientAccessRequestSummaryCol =
  (): FirestoreCollection<EndClientAccessRequestSummary> =>
    createFirestoreCollection<EndClientAccessRequestSummary>(
      endClientAccessRequestsSummaryCol
    );

export const getEndClientAccessRequestsDoc = (
  endClientAndUserId: string
): FirestoreDocument<EndClientAccessRequest> =>
  getEndClientAccessRequestsCol().doc(endClientAndUserId);

export const getEndClientAccessRequestSummaryDoc = (
  uid: string
): FirestoreDocument<EndClientAccessRequestSummary> =>
  getEndClientAccessRequestSummaryCol().doc(uid);

export const fetchEndClientAccessRequest = async (
  uid: string
): Promise<EndClientAccessRequest> =>
  notNull(await fetchDoc(getEndClientAccessRequestsDoc(uid)));

export const setEndClientAccessRequest = async (
  uid: string,
  tenant: string,
  entityId: string,
  endClient: EndClientEntity
): Promise<void> => {
  const endClientId = getEndClientId(tenant, entityId);
  await Promise.all([
    getEndClientAccessRequestsDoc(
      getEndClientAccessRequestId(tenant, entityId, uid)
    ).set({
      endClient,
      entityId: endClientId,
      requesterUid: uid,
      status: EndClientAccessRequestStatus.pending,
      tenant,
      time: now().toISOString(),
    }),
    getEndClientAccessRequestSummaryDoc(uid).set(
      {
        endClientIds: firestoreArrayUnion(endClientId),
        tenant,
      },
      { merge: true }
    ),
  ]);
};

export const endClientAccessRequestExists = async (
  uid: string,
  tenant: string,
  entityId: string
): Promise<boolean> => {
  const snap = await getEndClientAccessRequestsDoc(
    getEndClientAccessRequestId(tenant, entityId, uid)
  ).get();
  return snap.exists;
};
