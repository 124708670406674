/* DON'T EDIT THIS FILE: edit original and run build again */ import { UserProfile } from "../../framework/auth-profile/schema.ts";
import { getUserFullName } from "../../framework/auth-user-full-name/get-user-full-name.ts";
import { ascSorter, descSorter } from "../../framework/core/sort-by-many.ts";
import { allRoles } from "../../framework/permission/role-extras.ts";

export const usersListDefaultSorters = [
  ...allRoles.map((role) =>
    descSorter((user: any) => (user.roles.includes(role) ? 1 : 0))
  ),
  ascSorter((user: UserProfile) => getUserFullName(user)),
];
