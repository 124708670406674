/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveTextViewer } from "../../../credit/live-form/ui/text-viewer.tsx";
import {
  LiveFieldInput,
  LiveFieldRenderer,
} from "../../../credit/live-form/ui/types.ts";
import { useLiveAfterBlurTextInput } from "../../../credit/live-form/ui/use-live-after-blur-text-input.tsx";
import { InputElement } from "../../../credit/ui/form/input-element.tsx";
import { emailLiveFieldType } from "./type.ts";

const EmailLiveInput: LiveFieldInput = (fieldInfo) => (
  <InputElement type="email" {...useLiveAfterBlurTextInput(fieldInfo)} />
);

export const emailLiveFieldRenderers: LiveFieldRenderer = {
  type: emailLiveFieldType,
  Input: EmailLiveInput,
  Viewer: LiveTextViewer,
};
