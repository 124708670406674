/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../../../framework/core/not-null.ts";
import { LiveFieldInputParam } from "./types.ts";

export const useLiveImmediateInput = ({
  liveCell,
  saveField,
}: LiveFieldInputParam) => {
  const value = liveCell.getValue();
  const liveField = liveCell.getLiveField();
  const field = notNull(liveField.getField());
  return {
    value,
    autoFocus: liveField.getAutoFocus(),
    setValue: (value: any) => saveField(field, value),
    className:
      (liveCell.getError() ? "is-invalid" : "") +
      " " +
      (liveField.getClassName() ?? ""),
    disabled: liveField.isDisabled(),
  };
};
