/* DON'T EDIT THIS FILE: edit original and run build again */ const rulebookValueOptions = {
  zofingenPesosYa: {
    label:
      "01. ZOFINGEN PESOS YA F.C.I. - Aprobado por Res. CNV N° 22.862 fecha 11 de septiembre de 2024",
    pdfUrl: "/zofingen-pesos-ya.pdf",
  },
  zofingenInfraestructura: {
    label:
      "02. ZOFINGEN INFRAESTRUCTURA F.C.I. - Aprobado por Res. CNV N° 22.251 fecha 19 de abril de 2023",
    pdfUrl: "/zofingen-infraestructura.pdf",
  },
  zofingenFactoringPyme: {
    label:
      "03. ZOFINGEN FACTORING PYME F.C.I. - Aprobado por Res. CNV N° 20.357 fecha 24 de julio de 2019",
    pdfUrl: "/zofingen-factoring-pyme.pdf",
  },
  zofingenPesos24: {
    label:
      "04. ZOFINGEN PESOS 24 F.C.I. - Aprobado por Res. CNV N° 20.740 fecha 5 de agosto del 2020",
    pdfUrl: "/zofingen-pesos-24.pdf",
  },
};

export default rulebookValueOptions;
