/* DON'T EDIT THIS FILE: edit original and run build again */ import { useRealmFromUrl } from "../../framework/app-state/app-state-context.ts";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import { tenantColors } from "../../framework/theme/custom-colors.ts";
import { WhatsappButton } from "../../framework/theme/whatsapp-button.tsx";
import { Container } from "react-bootstrap";
import { TenantLogo } from "./logo-tenant.tsx";
// @ts-expect-error this is a string to the path of the image but typescript doesn't know it
import matchfinBackgroundImagePath from "./matchfin_background.jpg";

export const AuthenticationPage = ({
  children,
  right,
  footer,
}: {
  children?: React.ReactNode;
  right?: React.ReactNode;
  footer?: React.ReactNode;
}) => {
  const realm = useRealmFromUrl();
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          overflow: "auto",
          overflowX: "hidden",
          backgroundImage:
            realm === matchfinTenant
              ? "url(" + matchfinBackgroundImagePath + ")"
              : undefined,
          backgroundColor:
            realm !== matchfinTenant ? tenantColors.tertiary : undefined,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{ position: "absolute", width: "100vw", height: "100vh" }}
          className="d-flex flex-column"
        >
          <div className="d-flex justify-content-end px-7 pt-6">
            <div className="me-2">{right}</div>
          </div>
          <div className="d-flex justify-content-center pb-3">
            <TenantLogo />
          </div>
          <Container className="flex-grow-1">{children}</Container>
          {footer}

          {realm === matchfinTenant && (
            <WhatsappButton iconClassName="icon-6" />
          )}
        </div>
      </div>
    </div>
  );
};
