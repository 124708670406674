/* DON'T EDIT THIS FILE: edit original and run build again */ import { SidebarMenuItem } from "../../framework/sidebar-type/sidebar-types.ts";
import { UsersIcon } from "../../framework/theme-icons/icon.ts";
import { usersWebPath } from "../../framework/users-web-path/web-path.ts";
import { canAccessUsersPage } from "./can-access-users-tab.ts";
import { getUsersHasNotificationPipe } from "./notifications-pipe.ts";

export const usersTabId = "users";
export const usersTabLabel = "Usuarios";

const usersMenuItem: SidebarMenuItem = {
  id: usersTabId,
  getPath: () => usersWebPath,
  label: usersTabLabel,
  condition: canAccessUsersPage,
  getHasNotificationsPipe: getUsersHasNotificationPipe,
  order: 6,
  hasPriority: false,
  icon: (className) => <UsersIcon className={className} />,
};

export default usersMenuItem;
