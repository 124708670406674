/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";

class LaAnonimaLineOptions extends BaseLineOptions {
  getAmountOptionValuesInMillionsOfPesos(): number[] {
    return [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200];
  }

  getUsdAmountOptionValues(): number[] {
    return [
      25000, 35000, 50000, 100000, 150000, 250000, 500000, 1000000, 2000000,
      3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000,
    ];
  }

  getLastAmountIsEqualOrGreater(): boolean {
    return false;
  }

  getPostBalanceMonthCount(): number {
    return 24;
  }

  hasFinancingTermInMonths(): boolean {
    return true;
  }

  canSeePreApprovedStatus(): boolean {
    return true;
  }
}

export const laAnonimaLineOptions = new LaAnonimaLineOptions();
