/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseLiveField, LiveFieldSpec } from "../../../credit/live-form/field-spec.ts";
import { LiveData } from "../../../credit/live-form/types.ts";
import { SimpleDropdownValueOptions } from "../../../credit/ui/form/simple-dropdown.tsx";
import { singleOptionLiveFieldType } from "./type.ts";

type SingleOptionVariant = "dropdown" | "radioButtons" | "comboBox";

type SingleOptionLiveFieldSpec = LiveFieldSpec & {
  valueOptions: SimpleDropdownValueOptions;
  variant?: SingleOptionVariant;
};

export class SingleOptionLiveField extends BaseLiveField {
  private valueOptions: SimpleDropdownValueOptions;
  private variant: SingleOptionVariant;

  constructor(spec: SingleOptionLiveFieldSpec) {
    super(spec, singleOptionLiveFieldType);
    this.valueOptions = spec.valueOptions;
    this.variant = spec.variant ?? "dropdown";
  }

  isEmpty(liveData: LiveData) {
    return !this.getValue(liveData);
  }

  dontUseLabelTag() {
    return this.variant === "radioButtons";
  }

  getValueOptions() {
    return this.valueOptions;
  }

  getVariant() {
    return this.variant;
  }
}
