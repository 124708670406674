/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../../framework/config/config.ts";
import {
  getFirebaseAuthPort,
  getFirebaseFirestorePort,
  getFirebaseFunctionsPort,
  getFirebaseStoragePort,
} from "./firebase-port.ts";

export const getAuthUrl = () => {
  const config = getConfig();
  const host = config.firebaseEmulatorsHost;

  return "http://" + host + ":" + getFirebaseAuthPort() + "/";
};

export const rewriteEmulatorUrl = (url: string) => {
  const config = getConfig();
  const host = config.firebaseEmulatorsHost;
  const storageEmulatorPort = getFirebaseStoragePort();
  const replacements = [
    ["_auth", getAuthUrl()],
    ["_functions", "http://" + host + ":" + getFirebaseFunctionsPort() + "/"],
    [
      "_storage",
      "http://" + host + ":" + storageEmulatorPort + "/",
      // firebase storage doesn't always respect the host parameter
      "http://localhost:" + storageEmulatorPort + "/",
      // and sometimes even returns IPs
      "http://127.0.0.1:" + storageEmulatorPort + "/",
    ],
    ["_firestore", "http://" + host + ":" + getFirebaseFirestorePort() + "/"],
  ];

  for (const [dstPath, ...srcUrlStarts] of replacements) {
    for (const srcUrlStart of srcUrlStarts) {
      if (url.startsWith(srcUrlStart)) {
        return (
          "https://" +
          host +
          "/" +
          dstPath +
          "/" +
          url.substring(srcUrlStart.length)
        );
      }
    }
  }
  return url;
};
