/* DON'T EDIT THIS FILE: edit original and run build again */ import { useRemirrorWrapper } from "../../framework/html-editor/use-remirror-wrapper.ts";
import { useChangeEffect } from "@ehynds/use-change-effect";
import { EditorComponent, Remirror } from "@remirror/react";
import { useReducer } from "react";
import { HtmlEditorState } from "./use-html-editor-state.ts";

const InternalViewer = ({
  htmlEditorState,
}: {
  htmlEditorState: HtmlEditorState;
}) => {
  const { manager, state } = useRemirrorWrapper({
    htmlEditorState,
    editable: false,
  });
  return (
    <div className="remirror-theme">
      <Remirror manager={manager} initialContent={state} editable={false}>
        <EditorComponent />
      </Remirror>
    </div>
  );
};

export const Viewer = ({
  htmlEditorState,
}: {
  htmlEditorState: HtmlEditorState;
}) => {
  const [x, forceUpdate] = useReducer((x) => x + 1, 0);
  useChangeEffect(() => {
    forceUpdate();
  }, [htmlEditorState?.html]);
  return <InternalViewer key={x} htmlEditorState={htmlEditorState} />;
};
