/* DON'T EDIT THIS FILE: edit original and run build again */ import { ChangePasswordForm } from "../../framework/auth-edit-profile-page/change-password-form.front.tsx";
import { RequiredActionType } from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { verifyConformanceToCurrentPolicyRequiredActionId } from "../../framework/auth-verify-conformance-typing/required-action-type.ts";
import { matchfinSupportEmail } from "../../framework/contact-information/matchfin-support.ts";
import { callFirebaseFunction } from "../../framework/firebase/call-firebase-function.ts";
import { usePasswordSetterScorers } from "../../credit/live-form-password-setter-type/scorer.ts";
import { FormSection, TitledFormBorder } from "../../framework/theme/form-body.tsx";
import { Loading } from "../../framework/theme/loading.tsx";
import { useState } from "react";
import {
  isNotConformanceVerified,
  shouldEnforceConformanceVerify,
} from "./required-actions.ts";
import { setConformedToCurrentPolicyCallName } from "./set-conformed-to-current-policy-call-name.ts";
import { SetConformedToCurrentPolicyCall } from "./set-conformed-to-current-policy-call-signature.ts";

const setConformedToCurrentPolicy = async () =>
  await callFirebaseFunction<SetConformedToCurrentPolicyCall>(
    setConformedToCurrentPolicyCallName,
    {}
  );

const ErrorLoadingPrompt = () => (
  <TitledFormBorder title="Cambiar la contraseña">
    <FormSection>
      <>
        Hubo un error. Por favor, refrescá la página e intentá nuevamente. Si el
        error persiste, comunicate con{" "}
        <a href={"mailto:" + matchfinSupportEmail}>{matchfinSupportEmail}</a>.
      </>
    </FormSection>
  </TitledFormBorder>
);

const ConformanceVerificationPrompt = () => {
  const [loading, scorers, error] = usePasswordSetterScorers();
  const [state, setState] = useState("action");
  if (loading) {
    return <Loading />;
  }
  if (error || state === "error") {
    return <ErrorLoadingPrompt />;
  }
  return (
    <TitledFormBorder title="Cambiar la contraseña">
      <FormSection className="mb-5">
        <div className="mb-3">
          Para mejorar la seguridad del sitio, hemos decidido fortalecer los
          requerimientos de complejidad para las contraseñas. Por ello, le
          estamos pidiendo a todos los usuarios que las actualicen.
          <br />
          Si considerás que tu contraseña ya es lo suficientemente segura, podés
          intentar cambiarla por sí misma.
          <br />
          <br />
          <span className="fst-italic">
            Sugerencia: probá una contraseña con dos mayusculas y dos números,
            que no esté relacionada a datos personales tuyos ni del sitio, y que
            no contenga palabras fáciles de adivinar.
          </span>
        </div>
      </FormSection>
      <ChangePasswordForm
        scorers={scorers}
        onSave={async () => {
          const { ok } = await setConformedToCurrentPolicy();
          if (!ok) {
            setState("error");
          } else {
            await new Promise(() => {}); // block, privateProfile update will cause an implicit resolution
          }
        }}
      />
    </TitledFormBorder>
  );
};

export const verifyConformanceToCurrentPolicyRequiredAction: RequiredActionType =
  {
    id: verifyConformanceToCurrentPolicyRequiredActionId,
    label: "Actualizar la contraseña.",
    condition: isNotConformanceVerified,
    enforce: shouldEnforceConformanceVerify,
    Component: ConformanceVerificationPrompt,
  };
