/* DON'T EDIT THIS FILE: edit original and run build again */ import { runFirestoreTransaction } from "../../framework/firebase/firestore-run-transaction.ts";
import { FirestoreTransaction } from "../../framework/firebase/firestore-wrappers-transaction.ts";
import {
  FirestoreDocumentSnapshot,
  firestoreIncrement,
  FirestoreWriteResult,
} from "../../framework/firebase/firestore-wrappers-types.ts";
import {
  createFirestoreCollection,
  FirestoreCollection,
  FirestoreDocument,
} from "../../framework/firebase/firestore-wrappers.ts";
import { chatSubscriptionsColName } from "./col-names.ts";
import { ChatConversationId, ChatSubscriptionDoc } from "./schema.ts";

export const getChatSubscriptionCol =
  (): FirestoreCollection<ChatSubscriptionDoc> =>
    createFirestoreCollection<ChatSubscriptionDoc>(chatSubscriptionsColName);

export const getChatSubscriptionId = ({
  conversationId,
  userId,
}: {
  conversationId: ChatConversationId;
  userId: string;
}): string => conversationId + "-" + userId;

export const findChatSubscriptions = ({
  conversationId,
  userId,
  unread,
}: {
  conversationId: ChatConversationId | null;
  userId: string;
  unread: boolean;
}): FirestoreCollection<ChatSubscriptionDoc> => {
  let query = getChatSubscriptionCol();
  if (conversationId) {
    query = query.where("conversationId", "==", conversationId);
  }
  if (userId) {
    query = query.where("userId", "==", userId);
  }
  if (unread) {
    query = query.where("hasUnread", "==", true);
  }
  return query;
};

export const getChatSubscriptionDoc = (
  chatSubscriptionId: string
): FirestoreDocument<ChatSubscriptionDoc> =>
  getChatSubscriptionCol().doc(chatSubscriptionId);

export const upsertChatSubscription = async ({
  conversationId,
  userId,
  time,
  increaseUnread = true,
}: {
  conversationId: ChatConversationId;
  userId: string;
  time: string;
  increaseUnread: boolean;
}): Promise<void> => {
  const unreadCountIncrement = increaseUnread ? 1 : 0;
  await runFirestoreTransaction(async (transaction: FirestoreTransaction) => {
    const doc = getChatSubscriptionDoc(
      getChatSubscriptionId({ conversationId, userId })
    );
    return transaction
      .get(doc)
      .then((snap: FirestoreDocumentSnapshot<ChatSubscriptionDoc>) => {
        if (snap.exists) {
          transaction.update(doc, {
            unreadCount: firestoreIncrement(unreadCountIncrement),
            hasUnread: increaseUnread || !!snap.data()?.hasUnread,
          });
        } else {
          transaction.set(doc, {
            conversationId,
            userId,
            firstUnreadTime: time,
            unreadCount: unreadCountIncrement,
            hasUnread: increaseUnread,
          });
        }
      });
  });
};

export const cleanUnread = ({
  userId,
  conversationId,
  time,
}: {
  userId: string;
  conversationId: ChatConversationId;
  time: string;
}): Promise<FirestoreWriteResult> =>
  getChatSubscriptionDoc(
    getChatSubscriptionId({ userId, conversationId })
  ).update({
    unreadCount: 0,
    hasUnread: false,
    lastReadTime: time,
  });
