/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { argsecuritiesTenant } from "./code.ts";
import { argsecuritiesFullLabel } from "./full-label.ts";
import { argsecuritiesLabel } from "./label.ts";

class ArgsecuritiesTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return argsecuritiesTenant;
  }

  getPathLabel(): string | null {
    return "arg-securities";
  }

  getLabel(): string {
    return argsecuritiesLabel;
  }

  getFullLabel(): string {
    return argsecuritiesFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71493189-6";
  }

  getCnvRegistryId(): string | null {
    return "719";
  }
}

const argsecuritiesTenantHandler = new ArgsecuritiesTenantHandler();

export default argsecuritiesTenantHandler;
