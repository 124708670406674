/* DON'T EDIT THIS FILE: edit original and run build again */ import { deletePropertyDeleter } from "./delete-property-deleter.ts";

export class DotPath {
  readonly pathArray: string[];

  constructor(...pathArray: string[]) {
    this.pathArray = pathArray;
  }

  toString() {
    return getDotPathStr(...this.pathArray);
  }

  child(...subpathArray: string[]) {
    return new DotPath(...[...this.pathArray, ...subpathArray]);
  }

  prefix(props: Record<string, any>) {
    const out: Record<string, any> = {};
    for (const [prop, value] of Object.entries(props)) {
      out[this.child(prop).toString()] = value;
    }
    return out;
  }
}

type DotPathOrString = DotPath | string | number;

const getPathArray = (path: DotPathOrString) =>
  path instanceof DotPath ? path.pathArray : path.toString().split(".");

// pass undefined to remove value
// by default we remove it, but you can pass a custom deleter to for example set it a special "delete" value
export const pathUpdate = (
  data: Record<string, any>,
  path: DotPathOrString,
  value: any,
  deleter = deletePropertyDeleter
) => {
  let current = data;
  const pathArray = getPathArray(path);
  const len = pathArray.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pathArray[i];
    if (!current[elem]) {
      current[elem] = {};
    }
    current = current[elem];
  }
  if (value === undefined) {
    deleter(current, pathArray[len - 1]);
  } else {
    current[pathArray[len - 1]] = value;
  }
};

export const pathGet = (
  data: any,
  path: DotPathOrString,
  defaultValue?: any
) => {
  let current = data;
  const pathArray = getPathArray(path);
  const len = pathArray.length;
  for (let i = 0; i < len; i++) {
    const elem = pathArray[i];
    if (!current || typeof current !== "object" || !(elem in current)) {
      return defaultValue;
    }
    current = current[elem];
  }
  return current;
};

export const pathGetter =
  (path: DotPathOrString, defaultValue: any) => (data: any) =>
    pathGet(data, path, defaultValue);

const getDotPathStr = (...pathArray: string[]) => pathArray.join(".");

export const pickPaths = (
  src: Record<string, any>,
  paths: DotPathOrString[]
): Record<string, any> => {
  const out = {};
  for (const path of paths) {
    const value = pathGet(src, path);
    if (value !== undefined) {
      pathUpdate(out, path, value);
    }
  }
  return out;
};
