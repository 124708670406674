/* DON'T EDIT THIS FILE: edit original and run build again */ import { fetchCol } from "../../framework/firebase/fetch-col.ts";
import {
  createFirestoreCollection,
  FirestoreCollection,
  FirestoreDocument,
} from "../../framework/firebase/firestore-wrappers.ts";
import { getAllTenantCodes } from "../../framework/tenant-collection/tenant.ts";
import { maybeFetchUserCompany } from "../../framework/user-company/data.ts";
import { UserCompanyType } from "../../framework/user-company/user-company-type.ts";
import { tenantLimitsColName } from "./col-name.ts";
import { ObjectPermissionLimitsData } from "./permission-limits.ts";

export const getTenantUserCompanyLimitsCol =
  (): FirestoreCollection<ObjectPermissionLimitsData> =>
    createFirestoreCollection<ObjectPermissionLimitsData>(tenantLimitsColName);

export const doesUserCompanyHaveTenantLimits = (
  userCompany: UserCompanyType
): boolean =>
  userCompany.companyType === undefined || userCompany.companyType !== null;

const doesUserCompanyHaveTenantLimitsById = async (
  userCompanyId: string
): Promise<boolean> => {
  const userCompany = await maybeFetchUserCompany(userCompanyId);
  if (!userCompany) {
    return false;
  }
  return doesUserCompanyHaveTenantLimits(userCompany);
};

export const getTenantUserCompanyLimitsDoc = (
  userCompanyId: string,
  grantorTenant: string
): FirestoreDocument<ObjectPermissionLimitsData> =>
  getTenantUserCompanyLimitsCol().doc(userCompanyId + "-" + grantorTenant);

export const getAvailableTenantsForUserCompany = async (
  userCompanyId: string
): Promise<string[]> => {
  if (!(await doesUserCompanyHaveTenantLimitsById(userCompanyId))) {
    return getAllTenantCodes();
  }
  return (
    await fetchCol(
      getTenantUserCompanyLimitsCol()
        .where("entityIdCompany", "==", userCompanyId)
        .where("hideLines", "==", false),
      "id"
    )
  ).map(({ grantorTenant }) => grantorTenant);
};
