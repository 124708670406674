/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { avalfertilTenant } from "./code.ts";
import { avalfertilFullLabel } from "./full-label.ts";

const AvalfertilTenantHandler = class extends SgrTenantHandler {
  getCode(): string {
    return avalfertilTenant;
  }

  getPathLabel(): string | null {
    return "aval-fertil";
  }

  getLabel(): string {
    return "Aval Fertil";
  }

  getFullLabel(): string {
    return avalfertilFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71482463-1";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
};

const avalfertilTenantHandler = new AvalfertilTenantHandler();

export default avalfertilTenantHandler;
