/* DON'T EDIT THIS FILE: edit original and run build again */ import { LiveData, LiveFieldAfterLabel } from "../../../credit/live-form/types.ts";
import { Div } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { LiveFormWidgets } from "./types.ts";

export const AfterLabelRenderer = ({
  AfterLabel,
  Widgets,
  liveData,
}: {
  AfterLabel: LiveFieldAfterLabel | null;
  Widgets: LiveFormWidgets;
  liveData: LiveData;
}) => {
  if (!AfterLabel) {
    return null;
  }
  if (typeof AfterLabel === "string") {
    if (!(AfterLabel in Widgets)) {
      throw new Error("missingAfterLabelWidget: " + AfterLabel);
    }
    const AfterLabelWidget = Widgets[AfterLabel];
    return <AfterLabelWidget {...liveData} />;
  } else if (typeof AfterLabel === "function") {
    // functions that return strings to be used as labels
    return <Div className="w-100 flex-shrink-0 mt-1 mb-3">{AfterLabel()}</Div>;
  } else {
    throw new Error("unknownAfterLabelType: " + typeof AfterLabel);
  }
};
