/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  entityFieldCommercialName,
  entityFieldFirstName,
  entityFieldLastName,
} from "../../credit/entity/inline-entity-type.ts";
import { TextLiveField } from "../../credit/live-form-core-types/text/live-field.ts";
import { LiveFieldset } from "../../credit/live-form/fieldset.ts";
import { LiveField } from "../../credit/live-form/live-field.ts";
import { getBusinessEntityTypeField } from "./business-entity-type-field.ts";
import { onlyPeople, onlyUruguayCompanies } from "./condition.ts";
import { entityNameField } from "./entity-name.ts";

export const extraNameFieldset = new LiveFieldset([
  getBusinessEntityTypeField(
    "Tipo de estructura jurídica",
    onlyUruguayCompanies
  ),
  new TextLiveField({
    label: "Nombre comercial",
    field: entityFieldCommercialName,
    required: true,
    condition: onlyUruguayCompanies,
  }),
]);

export const nameFields: LiveField[] = [
  new TextLiveField({
    label: "Nombre",
    field: entityFieldFirstName,
    required: true,
    condition: onlyPeople,
  }),
  new TextLiveField({
    label: "Apellido(s)",
    field: entityFieldLastName,
    required: true,
    condition: onlyPeople,
  }),
  entityNameField,
];
