/* DON'T EDIT THIS FILE: edit original and run build again */ import { alerter } from "../../framework/alert/alert.tsx";
import { RequiredActionType } from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { verifyTermsAndConditionsRequiredActionId } from "../../framework/auth-verify-terms-typing/required-action-type.ts";
import { matchfinSupportEmail } from "../../framework/contact-information/matchfin-support.ts";
import { callFirebaseFunction } from "../../framework/firebase/call-firebase-function.ts";
import { BooleanLiveField } from "../../credit/live-form-core-types/boolean/live-field.ts";
import { LiveForm } from "../../credit/live-form-simple/live-form.tsx";
import { LiveFieldset } from "../../credit/live-form/fieldset.ts";
import { LiveRecord } from "../../credit/live-form/live-record.ts";
import { useLiveFormState } from "../../credit/live-form/ui/use-live-form-stte.ts";
import { FormSection, TitledFormBorder } from "../../framework/theme/form-body.tsx";
import { LoadingButton } from "../../framework/theme/loading-button.tsx";
import { Link } from "react-router-dom";
import { acceptCurrentTermsAndConditionsCallName } from "./accept-current-terms-of-use-call-name.ts";
import { AcceptCurrentTermsAndConditionsCall } from "./accept-current-terms-of-use-call-signature.ts";
import {
  isNotTermsAndConditionsVerified,
  shouldEnforceTermsAndConditionsVerify,
} from "./required-actions.ts";

const acceptCurrentTermsAndConditions = async () =>
  await callFirebaseFunction<AcceptCurrentTermsAndConditionsCall>(
    acceptCurrentTermsAndConditionsCallName
  );

const fieldset = new LiveFieldset([
  new BooleanLiveField({
    field: "acceptedTermsAndConditions",
    required: true,
    getError: ({ value }) => {
      if (!value) {
        return "tenés que aceptar las condiciones para poder acceder al sitio.";
      }
      return null;
    },
    variant: "simple",
    CheckboxLabel: "TermsAndConditions",
  }),
]);

const TermsAndConditions = () => (
  <>
    Acepto las{" "}
    <Link
      to={{ pathname: "/condiciones-de-uso", search: "static=true" }}
      target="_blank"
    >
      condiciones generales de uso
    </Link>{" "}
    de la plataforma.
  </>
);

const TermsAndConditionsVerificationPrompt = () => {
  const { recordValue, saveField, showErrors, setShowErrors, getError } =
    useLiveFormState({
      fieldset,
      defaultRecordValue: () => fieldset.createDefaultRecord(),
      defaultShowErrors: false,
    });

  const validateAndSave = async () => {
    {
      const error = getError();
      if (error) {
        setShowErrors(true);
        await alerter.alert(error);
        return;
      }
    }
    const { ok } = await acceptCurrentTermsAndConditions();
    if (!ok) {
      await alerter.alert(
        <>
          Hubo un error. Por favor, intentá nuevamente. Si el error persiste,
          comunicate con{" "}
          <a href={"mailto:" + matchfinSupportEmail}>{matchfinSupportEmail}</a>.
        </>
      );
    } else {
      await new Promise(() => {}); // block, privateProfile update will cause an implicit resolution
    }
  };

  return (
    <TitledFormBorder title="Aceptar las condiciones de uso">
      <FormSection>
        <div className="mb-3">
          Actualizamos las condiciones generales de uso de la plataforma. Para
          continuar, te pedimos que las aceptes.
        </div>
        <LiveForm
          liveRecord={new LiveRecord(fieldset, { recordValue }, showErrors)}
          saveField={saveField}
          onEnterPress={validateAndSave}
          Widgets={{ TermsAndConditions }}
        />
        <div className="d-flex mt-3 justify-content-end">
          <LoadingButton onClick={validateAndSave}>Continuar</LoadingButton>
        </div>
      </FormSection>
    </TitledFormBorder>
  );
};

export const verifyTermsAndConditionsRequiredAction: RequiredActionType = {
  id: verifyTermsAndConditionsRequiredActionId,
  label: "Aceptar las condiciones de uso de la plataforma.",
  condition: isNotTermsAndConditionsVerified,
  enforce: shouldEnforceTermsAndConditionsVerify,
  Component: TermsAndConditionsVerificationPrompt,
};
