/* DON'T EDIT THIS FILE: edit original and run build again */ import { RulebookValueOption } from "../../../framework/tenant-handler-type/onboarding-options.ts";

const rulebookValueOptions: Record<string, RulebookValueOption> = {
  argenfundsPesosSaving: {
    label:
      "01. ARGENFUNDS AHORRO PESOS F.C.I. - Aprobado por Res. CNV N° 16.668 fecha 11 de octubre de 2011 y modificado por Res. CNV: N° 17.566 fecha 4 de diciembre de 2014",
  },
  argenfundsFixedIncome: {
    label:
      "02. ARGENFUNDS RENTA FIJA F.C.I. - Aprobado por Res. CNV N° 16.668 fecha 11 de octubre de 2011, Modificado por Res. CNV: N° 17.566 de fecha 4 de diciembre de 2014 y Modificado por Res. CNV N° 2041 de fecha 22/01/2020",
  },
  argenfundsOpenPymes: {
    label:
      "03. ARGENFUNDS ABIERTO PYMES F.C.I. - Aprobado por Res. CNV N° 17.120 fecha 04 de julio de 2013",
  },
  argenfundsPesosIncome: {
    label:
      "04. ARGENFUNDS RENTA PESOS F.C.I. - Aprobado por Res. CNV N° 17.171 fecha 22 de agosto del 2013",
  },
  argenfundsArgentinaIncome: {
    label:
      "05. ARGENFUNDS RENTA ARGENTINA F.C.I. - Aprobado por Res. CNV N° 17.171 fecha 22 de agosto de 2013",
  },
  argenfundsBalancedIncome: {
    label:
      "06. ARGENFUNDS RENTA BALANCEADA F.C.I. - Aprobado por Res. CNV N°18.107 fecha 29 de junio de 2016",
  },
  argenfundsFlexibleIncome: {
    label:
      "07. ARGENFUNDS RENTA FLEXIBLE F.C.I. - Aprobado por Res. CNV N°18.107 fecha 29 de junio de 2016",
  },
  argenfundsMixedIncome: {
    label:
      "08. ARGENFUNDS RENTA MIXTA F.C.I. - Aprobado por Res. CNV N°18.107 fecha 29 de junio de 2016",
  },
  argenfundsTotalIncome: {
    label:
      "09. ARGENFUNDS RENTA TOTAL F.C.I. - Aprobado por Res. CNV N°18.107 fecha 29 de junio de 2016",
  },
  argenfundsDynamicIncome: {
    label:
      "10. ARGENFUNDS RENTA DINAMICA F.C.I. - Aprobado por Res. CNV N°18.107 fecha 01 de febrero de 2016",
  },
  argenfundsGlobalIncome: {
    label:
      "11. ARGENFUNDS RENTA GLOBAL F.C.I. - Aprobado por Res. CNV N°18.107 fecha 29 de junio de 2016",
  },
  argenfundsCapitalIncome: {
    label:
      "12. ARGENFUNDS RENTA CAPITAL F.C.I. - Aprobado por Res. CNV N° 18.446 fecha 12 de enero de 2017",
  },
  argenfundsVariableIncome: {
    label:
      "13. ARGENFUNDS RENTA VARIABLE F.C.I. - Aprobado por Res. CNV N°19.873 fecha 8 de noviembre de 2018",
  },
  argenfundsMixedPlusIncome: {
    label:
      "14. ARGENFUNDS RENTA MIXTA PLUS F.C.I. - Aprobado por Res. CNV N°19.873 fecha 8 de noviembre de 2018",
  },
  argenfundsAbsoluteReturnIncome: {
    label:
      "15. ARGENFUNDS RETORNO ABSOLUTO F.C.I. - Aprobado por Res. CNV N° 19.873 fecha 9 de noviembre de 2018",
  },
  argenfundsGrowthIncome: {
    label:
      "16. ARGENFUNDS RENTA CRECIMIENTO F.C.I. - Aprobado por Res. CNV N° 18.446 fecha 12 de enero de 2018",
  },
  argenfundsLiquidityIncome: {
    label:
      "17. ARGENFUNDS RENTA LIQUIDEZ F.C.I. - Aprobado por Res. CNV N° 20.209 fecha 24 de abril de 2019",
  },
  argenfundsInfrastructureAndRealEconomy: {
    label:
      "18. ARGENFUNDS F.C.I. ABIERTO PARA EL FINANCIAMIENTO DE LA INFRAESTRUCTURA Y ECONOMÍA REAL - Aprobado por Res CNV No 21.494 fecha 11 de noviembre de 2021",
  },
};

export default rulebookValueOptions;
