/* DON'T EDIT THIS FILE: edit original and run build again */ import { getCountryOptions } from "../../../framework/country/countries.ts";
import { argentinaCountryCode } from "../../../framework/country/country-codes.ts";
import { argentineProvinces } from "../../../credit/form/argentina-provinces.ts";
import { Div } from "../../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { StreetAddress } from "../../../credit/street-address-type/street-address-type.ts";

const renderLocationOption = (value: any, valueOptions: any) =>
  value ? valueOptions[value] : "";

export const LocationViewer = ({
  value,
  className = "",
  detailed = true,
}: {
  value: any;
  className?: string;
  detailed?: boolean;
}) => {
  return (
    <Div className={className}>
      {detailed ? (
        <>
          {value.streetLine ? <Div>{value.streetLine}</Div> : null}
          {value.zipCode ? <Div>{"CP. " + value.zipCode}</Div> : null}
        </>
      ) : null}
      {value.locality ? <Div>{value.locality}</Div> : null}
      {value.country === argentinaCountryCode ? (
        value.argentineProvince ? (
          renderLocationOption(value.argentineProvince, argentineProvinces)
        ) : null
      ) : value.otherProvince ? (
        <Div>{value.otherProvince}</Div>
      ) : null}
      {value.country ? (
        <Div>{renderLocationOption(value.country, getCountryOptions())}</Div>
      ) : null}
    </Div>
  );
};

export const getLocationAsText = (value: StreetAddress, detailed = true) => {
  const out: string[] = [];
  if (detailed) {
    if (value.streetLine) {
      out.push(value.streetLine);
    }
    if (value.zipCode) {
      out.push("CP. " + value.zipCode);
    }
  }
  if (value.locality) {
    out.push(value.locality);
  }
  if (value.country === argentinaCountryCode) {
    if (value.argentineProvince) {
      out.push(
        renderLocationOption(value.argentineProvince, argentineProvinces)
      );
    }
  } else if (value.otherProvince) {
    out.push(value.otherProvince);
  }
  if (value.country) {
    out.push(renderLocationOption(value.country, getCountryOptions()));
  }
  return out.join(", ");
};
