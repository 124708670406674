/* DON'T EDIT THIS FILE: edit original and run build again */ import { EditorButtonGroup } from "../../../framework/html-editor/button-group/editor-button-group.tsx";
import { HeadingButton } from "../../../framework/html-editor/button/heading-button.tsx";
import {
  HeadingH1Icon,
  HeadingH2Icon,
  HeadingH3Icon,
} from "../../../framework/theme-icons/icon.ts";

export const HeadingLevelButtonGroup = () => (
  <EditorButtonGroup name="heading-level">
    <HeadingButton level={1}>
      <HeadingH1Icon />
    </HeadingButton>
    <HeadingButton level={2}>
      <HeadingH2Icon />
    </HeadingButton>
    <HeadingButton level={3}>
      <HeadingH3Icon />
    </HeadingButton>
  </EditorButtonGroup>
);
