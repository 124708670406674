/* DON'T EDIT THIS FILE: edit original and run build again */ const reportErrorMessageHandlers = [
  (message: string) => {
    console.error(message);
  },
];
const reportExceptionHandlers = [
  (where: string, error: Error) => {
    console.error(where, error);
  },
];

export const reportErrorMessage = (message: string) => {
  for (const handler of reportErrorMessageHandlers) {
    handler(message);
  }
};

export const reportException = (where: string, error: Error) => {
  for (const handler of reportExceptionHandlers) {
    handler(where, error);
  }
};

export const addReportErrorMessageHandler = (
  handler: (message: string) => void
) => {
  reportErrorMessageHandlers.push(handler);
};

export const addReportExceptionHandler = (
  handler: (where: string, error: Error) => void
) => {
  reportExceptionHandlers.push(handler);
};
