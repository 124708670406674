/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  TermsOfUseParams,
  WebHomeHeaderParams,
} from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import {
  emailChannelType,
  reutersChannelType,
  webHomeChannelType,
  whatsappChannelType,
} from "../../../onboarding/channel/channel-types.ts";
import { ComponentType } from "react";
import { onboardingTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";

class PortfolioOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  async getTermsOfUse(): Promise<ComponentType<TermsOfUseParams>> {
    return (await import("./onboarding-terms-of-use.tsx")).default;
  }

  hasSignatura(): boolean {
    return true;
  }

  hasEsco(): boolean {
    return true;
  }

  hasRevenueVolume(): boolean {
    return false;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  hasOnboardingCoholdersSection(): boolean {
    return false;
  }

  getSupportedChannelTypes(): string[] {
    return [
      reutersChannelType,
      webHomeChannelType,
      whatsappChannelType,
      emailChannelType,
    ];
  }

  supportsWebHomeChannelAccessType(): boolean {
    return true;
  }

  async getWebHomeHeader(): Promise<ComponentType<WebHomeHeaderParams>> {
    return (await import("./web-home-header.tsx")).default;
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este mail será detallado en el PUC de Caja de Valores " +
      "(Consulta Online de saldos y movimientos de la cuenta corriente)."
    );
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  getReutersUserDestinations(): { fullName: string; address: string }[] {
    return [
      { fullName: "ALEJANDRO TERAN", address: "at@portfolioinvestment.com.ar" },
      { fullName: "ANDRES JARDON", address: "aj@portfolioinvestment.com.ar" },
      { fullName: "CLAUDIO COLOMBO", address: "cc@portfolioinvestment.com.ar" },
      {
        fullName: "NICOLAS ROSENFELD",
        address: "nr@portfolioinvestment.com.ar",
      },
      {
        fullName: "JUAN AGUSTIN CORDOBA",
        address: "jac@portfolioinvestment.com.ar",
      },
      {
        fullName: "AGUSTIN EZEQUIEL HELOU",
        address: "ah@portfolioinvestment.com.ar",
      },
      {
        fullName: "FELIPE GASTON ALEMAN MONCH",
        address: "fam@portfolioinvestment.com.ar",
      },
      { fullName: "SANTIAGO CANEDA", address: "sc@portfolioinvestment.com.ar" },
    ];
  }

  async getEmailHeader(): Promise<ComponentType<Record<string, never>>> {
    return (await import("./email-header.tsx")).default;
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }

  canBackupOnboardings(): boolean {
    return true;
  }
}

export const portfolioOnboardingOptions = new PortfolioOnboardingOptions();
