/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { sycTenant } from "./code.ts";
import { sycFullLabel } from "./full-label.ts";

class SycTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return sycTenant;
  }

  getPathLabel(): string | null {
    return "syc";
  }

  getLabel(): string {
    return "SYC";
  }

  getFullLabel(): string {
    return sycFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-70847222-7";
  }

  getCnvRegistryId(): string | null {
    return "105";
  }

  publishesInMarketplace(): boolean {
    return true;
  }
}

const sycTenantHandler = new SycTenantHandler();
export default sycTenantHandler;
