/* DON'T EDIT THIS FILE: edit original and run build again */ import { ChatConversationId } from "../../framework/chat-data/schema.ts";
import { findChatSubscriptions } from "../../framework/chat-data/subscription-data.ts";
import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { colPipe } from "../../framework/firebase/firestore-pipe.ts";

export const createUnreadCountPipe = (
  loggedUid: string,
  conversationId: ChatConversationId | null = null
): DataPipe<number> =>
  colPipe(
    findChatSubscriptions({ userId: loggedUid, unread: true, conversationId }),
    "id",
    "unreadCountPipe"
  ).pipe((subscriptions) =>
    subscriptions
      .map(({ unreadCount }) => unreadCount)
      .reduce((a, b) => a + b, 0)
  ); // future@chat: consider a global unread doc to avoid this computation
