import * as React from "react";
const SvgLogoStcSmall = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "165.2852 156.318 117.1058 67.4", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 168.991 201.518 L 168.091 196.018 C 184.091 196.018 212.691 189.418 218.191 183.218 C 218.411 182.988 218.581 182.716 218.691 182.418 C 218.291 182.218 216.491 181.618 210.391 182.718 C 204.291 183.818 199.391 185.318 193.591 186.918 C 179.291 190.818 170.991 192.918 167.191 190.318 C 166.047 189.51 165.345 188.216 165.291 186.818 C 165.191 184.418 166.391 182.118 168.991 180.018 C 175.191 175.018 189.091 171.418 196.791 169.818 C 204.491 168.218 215.091 165.918 218.291 166.918 C 218.291 166.918 219.491 171.818 219.491 171.818 C 217.091 171.218 180.591 177.118 171.991 184.718 C 171.552 185.085 171.18 185.525 170.891 186.018 C 174.091 186.618 184.791 183.618 192.091 181.618 C 207.591 177.318 217.391 174.918 221.791 177.918 C 223.087 178.777 223.931 180.171 224.091 181.718 C 224.264 183.648 223.567 185.554 222.191 186.918 C 214.991 194.918 185.191 201.518 168.991 201.518 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "260.791 156.318 202.191 156.318 202.191 161.818 228.791 161.818 228.791 180.418 234.291 180.418 234.291 161.818 260.791 161.818 260.791 156.318", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 254.491 208.618 C 244.091 208.618 233.591 207.318 227.591 203.718 C 223.191 201.018 222.091 197.618 221.991 195.218 C 221.891 192.818 222.691 189.418 226.991 186.618 C 238.891 178.918 271.391 180.118 282.391 185.618 L 282.391 190.518 C 272.891 185.818 240.291 184.618 229.991 191.218 C 227.691 192.718 227.391 194.118 227.491 194.918 C 227.591 195.718 227.591 197.218 230.491 199.018 C 241.691 205.818 276.491 203.018 282.391 200.218 L 282.391 205.118 C 278.491 207.018 267.291 208.618 254.491 208.618 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 172.791 215.318 C 172.791 215.118 172.891 215.018 173.191 215.018 L 174.291 215.018 C 174.491 215.018 174.691 215.118 174.691 215.318 L 174.691 223.218 C 174.691 223.418 174.591 223.518 174.291 223.518 L 173.191 223.518 C 172.991 223.518 172.791 223.418 172.791 223.218 L 172.791 215.318 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 177.091 215.318 C 177.091 215.118 177.191 215.018 177.491 215.018 L 178.591 215.018 C 178.804 215 179.006 215.121 179.091 215.318 L 180.891 218.718 C 181.23 219.434 181.53 220.169 181.791 220.918 C 181.691 219.918 181.691 218.318 181.691 217.518 L 181.691 215.318 C 181.691 215.118 181.791 215.018 182.091 215.018 L 183.091 215.018 C 183.291 215.018 183.491 215.118 183.491 215.318 L 183.491 223.218 C 183.491 223.418 183.391 223.518 183.091 223.518 L 181.891 223.518 C 181.677 223.535 181.476 223.415 181.391 223.218 L 179.591 219.818 C 179.241 219.106 178.94 218.371 178.691 217.618 C 178.791 218.618 178.891 220.418 178.891 221.118 L 178.891 223.118 C 178.891 223.318 178.791 223.418 178.491 223.418 L 177.491 223.418 C 177.291 223.418 177.091 223.318 177.091 223.118 L 177.091 215.318 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 188.291 223.518 C 188.069 223.581 187.84 223.443 187.791 223.218 L 185.291 215.318 C 185.191 215.118 185.291 215.018 185.591 215.018 L 186.791 215.018 C 186.978 215.012 187.144 215.136 187.191 215.318 L 188.391 219.218 C 188.597 219.975 188.764 220.743 188.891 221.518 L 188.991 221.518 C 189.113 220.742 189.28 219.974 189.491 219.218 L 190.591 215.318 C 190.637 215.136 190.804 215.012 190.991 215.018 L 192.091 215.018 C 192.291 215.018 192.491 215.118 192.391 215.318 L 189.891 223.218 C 189.842 223.443 189.613 223.581 189.391 223.518 L 188.291 223.518 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 194.191 215.318 C 194.191 215.118 194.291 215.018 194.591 215.018 L 198.991 215.018 C 199.191 215.018 199.391 215.118 199.391 215.318 L 199.391 216.218 C 199.391 216.418 199.291 216.518 198.991 216.518 L 195.991 216.518 C 195.991 216.618 195.991 217.818 195.991 218.418 L 198.391 218.418 C 198.591 218.418 198.791 218.518 198.791 218.718 L 198.791 219.418 C 198.791 219.618 198.691 219.718 198.391 219.718 L 195.891 219.718 C 195.891 220.818 195.891 221.818 195.891 221.918 L 198.991 221.918 C 199.191 221.918 199.391 222.018 199.391 222.218 L 199.391 223.118 C 199.391 223.318 199.291 223.418 198.991 223.418 L 194.591 223.418 C 194.391 223.418 194.191 223.318 194.191 223.118 L 194.191 215.318 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 201.591 215.318 C 201.591 215.118 201.691 215.018 201.991 215.018 L 204.491 215.018 C 205.091 215.018 207.491 215.018 207.491 217.518 C 207.556 218.478 206.988 219.37 206.091 219.718 C 206.691 219.918 206.991 220.718 207.091 221.118 L 207.691 223.218 C 207.791 223.418 207.691 223.518 207.391 223.518 L 206.291 223.518 C 206.104 223.524 205.937 223.399 205.891 223.218 L 205.291 221.218 C 205.091 220.418 204.691 220.218 204.191 220.218 L 203.291 220.218 C 203.291 221.618 203.291 223.118 203.291 223.218 C 203.291 223.318 203.191 223.518 202.891 223.518 L 201.891 223.518 C 201.691 223.518 201.491 223.418 201.491 223.218 L 201.591 215.318 Z M 203.291 218.818 L 204.391 218.818 C 204.791 218.818 205.791 218.818 205.791 217.718 C 205.791 216.618 204.791 216.618 204.391 216.618 L 203.291 216.618 C 203.291 216.618 203.291 217.818 203.291 218.718 L 203.291 218.818 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 209.491 222.918 C 209.291 222.818 209.191 222.618 209.291 222.418 L 209.691 221.518 C 209.791 221.318 209.891 221.318 210.091 221.418 C 210.7 221.866 211.434 222.111 212.191 222.118 C 212.799 222.175 213.338 221.726 213.391 221.118 C 213.391 219.518 209.591 220.018 209.591 217.118 C 209.591 214.218 210.691 214.718 212.391 214.718 C 213.111 214.704 213.826 214.84 214.491 215.118 C 214.691 215.218 214.791 215.318 214.691 215.518 L 214.391 216.418 C 214.291 216.618 214.191 216.718 213.991 216.518 C 213.53 216.267 213.015 216.13 212.491 216.118 C 211.891 216.118 211.391 216.418 211.391 216.918 C 211.391 217.418 215.291 218.318 215.291 220.818 C 215.291 223.318 213.891 223.418 212.191 223.418 C 211.262 223.506 210.326 223.333 209.491 222.918 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 217.391 215.318 C 217.391 215.118 217.491 215.018 217.791 215.018 L 218.891 215.018 C 219.091 215.018 219.291 215.118 219.291 215.318 L 219.291 223.218 C 219.291 223.418 219.191 223.518 218.891 223.518 L 217.691 223.518 C 217.491 223.518 217.291 223.418 217.291 223.218 L 217.391 215.318 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 221.191 219.118 C 221.191 216.818 222.291 214.718 224.791 214.718 C 227.291 214.718 228.491 216.718 228.491 219.118 C 228.491 221.518 227.491 223.618 224.791 223.618 C 222.091 223.618 221.191 221.318 221.191 219.118 Z M 226.691 219.118 C 226.691 217.718 226.291 216.318 224.791 216.318 C 223.291 216.318 222.991 217.718 222.991 219.118 C 222.991 220.518 223.391 222.218 224.791 222.218 C 226.191 222.218 226.691 220.518 226.691 219.118 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 230.591 215.318 C 230.591 215.118 230.691 215.018 230.991 215.018 L 232.091 215.018 C 232.304 215 232.506 215.121 232.591 215.318 L 234.391 218.718 C 234.73 219.434 235.03 220.169 235.291 220.918 L 235.391 220.918 C 235.291 219.918 235.291 218.318 235.291 217.518 L 235.291 215.318 C 235.291 215.118 235.391 215.018 235.691 215.018 L 236.691 215.018 C 236.891 215.018 237.091 215.118 237.091 215.318 L 237.091 223.218 C 237.091 223.418 236.991 223.518 236.691 223.518 L 235.391 223.518 C 235.177 223.535 234.976 223.415 234.891 223.218 L 233.091 219.818 C 232.741 219.106 232.44 218.37 232.191 217.618 C 232.291 218.618 232.391 220.418 232.391 221.118 L 232.391 223.118 C 232.391 223.318 232.291 223.418 231.991 223.418 L 230.991 223.418 C 230.791 223.418 230.591 223.318 230.591 223.118 L 230.591 215.318 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 239.491 215.318 C 239.491 215.118 239.591 215.018 239.891 215.018 L 244.291 215.018 C 244.491 215.018 244.691 215.118 244.691 215.318 L 244.691 216.218 C 244.691 216.418 244.591 216.518 244.291 216.518 L 241.291 216.518 C 241.291 216.618 241.291 217.818 241.291 218.418 L 243.691 218.418 C 243.891 218.418 244.091 218.518 244.091 218.718 L 244.091 219.418 C 244.091 219.618 243.991 219.718 243.691 219.718 L 241.291 219.718 C 241.291 220.818 241.291 221.818 241.291 221.918 L 244.391 221.918 C 244.591 221.918 244.791 222.018 244.791 222.218 L 244.791 223.118 C 244.791 223.318 244.691 223.418 244.391 223.418 L 239.791 223.418 C 239.591 223.418 239.391 223.318 239.391 223.118 L 239.491 215.318 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 246.491 222.918 C 246.291 222.818 246.191 222.618 246.291 222.418 L 246.691 221.518 C 246.791 221.318 246.891 221.318 247.091 221.418 C 247.7 221.866 248.434 222.111 249.191 222.118 C 249.799 222.175 250.338 221.726 250.391 221.118 C 250.391 219.518 246.591 220.018 246.591 217.118 C 246.591 214.218 247.691 214.718 249.391 214.718 C 250.111 214.704 250.826 214.84 251.491 215.118 C 251.691 215.218 251.791 215.318 251.691 215.518 L 251.391 216.418 C 251.291 216.618 251.191 216.718 250.991 216.518 C 250.53 216.267 250.015 216.13 249.491 216.118 C 248.891 216.118 248.391 216.418 248.391 216.918 C 248.391 217.418 252.291 218.318 252.291 220.818 C 252.291 223.318 250.891 223.418 249.191 223.418 C 248.262 223.506 247.326 223.333 246.491 222.918 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 258.191 222.918 C 257.991 222.818 257.891 222.618 257.991 222.418 L 258.391 221.518 C 258.491 221.318 258.591 221.318 258.791 221.418 C 259.4 221.866 260.134 222.111 260.891 222.118 C 261.499 222.175 262.038 221.726 262.091 221.118 C 262.091 219.518 258.291 220.018 258.291 217.118 C 258.291 214.218 259.391 214.718 261.091 214.718 C 261.811 214.704 262.526 214.84 263.191 215.118 C 263.391 215.218 263.491 215.318 263.391 215.518 L 263.091 216.418 C 262.991 216.618 262.891 216.718 262.691 216.518 C 262.23 216.267 261.715 216.13 261.191 216.118 C 260.591 216.118 260.091 216.418 260.091 216.918 C 260.091 217.418 263.991 218.318 263.991 220.818 C 263.991 223.318 262.591 223.418 260.891 223.418 C 259.962 223.506 259.026 223.333 258.191 222.918 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 267.191 223.718 C 266.421 223.718 265.94 222.884 266.325 222.218 C 266.503 221.908 266.833 221.718 267.191 221.718 C 267.96 221.718 268.442 222.551 268.057 223.218 C 267.878 223.527 267.548 223.718 267.191 223.718 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 275.691 223.518 C 275.504 223.524 275.337 223.399 275.291 223.218 L 274.891 221.618 L 272.491 221.618 L 272.091 223.218 C 272.044 223.399 271.878 223.524 271.691 223.518 L 270.591 223.518 C 270.391 223.518 270.191 223.418 270.291 223.218 C 271.091 220.718 271.991 217.618 272.691 215.318 C 272.74 215.092 272.969 214.955 273.191 215.018 L 274.591 215.018 C 274.813 214.955 275.042 215.092 275.091 215.318 L 277.491 223.218 C 277.591 223.418 277.391 223.518 277.191 223.518 L 275.691 223.518 Z M 274.091 218.418 L 273.691 216.718 C 273.591 217.118 273.491 217.718 273.291 218.418 L 272.791 220.218 L 274.591 220.218 L 274.091 218.418 Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 280.191 223.718 C 279.421 223.718 278.94 222.884 279.325 222.218 C 279.503 221.908 279.833 221.718 280.191 221.718 C 280.96 221.718 281.442 222.551 281.057 223.218 C 280.878 223.527 280.548 223.718 280.191 223.718 Z", style: {
  fill: "#fff"
} }));
export default SvgLogoStcSmall;
