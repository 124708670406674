/* DON'T EDIT THIS FILE: edit original and run build again */ import { notNull } from "../../framework/core/not-null.ts";
import { batchColPipe } from "../../framework/firebase/batch-pipe.ts";
import { batchFetchCol, fetchCol } from "../../framework/firebase/fetch-col.ts";
import { fetchDoc } from "../../framework/firebase/fetch-doc.ts";
import { colPipe } from "../../framework/firebase/firestore-pipe.ts";
import { firestoreDocumentId } from "../../framework/firebase/firestore-wrappers-types.ts";
import {
  FirestoreCollection,
  createFirestoreCollection,
} from "../../framework/firebase/firestore-wrappers.ts";
import { FullBalanceSortOrder } from "./balance-sorting-options.ts";
import { profilesColName } from "./profiles-col-name.ts";
import { Profile, UserProfile } from "./schema.ts";

export const getProfileCol = () =>
  createFirestoreCollection<Profile>(profilesColName);

export const getProfileDocument = (uid: string) => getProfileCol().doc(uid);

export const maybeFetchProfile = async (
  uid: string
): Promise<UserProfile | undefined> => fetchDoc(getProfileDocument(uid), "uid");

export const fetchProfilesByUid = async (
  uids: string[]
): Promise<UserProfile[]> =>
  batchFetchCol(
    getProfileCol().where("disabled", "==", false),
    firestoreDocumentId(),
    "in",
    uids,
    "uid"
  );

export const fetchProfilesByEmail = async (
  emails: string[]
): Promise<UserProfile[]> =>
  batchFetchCol(
    getProfileCol().where("disabled", "==", false),
    "email",
    "in",
    emails,
    "uid"
  );

export const fetchProfile = async (uid: string): Promise<UserProfile> =>
  notNull(await maybeFetchProfile(uid));

export const getBalanceSortingFromProfile = (profile: UserProfile) =>
  profile?.balanceSorting ?? FullBalanceSortOrder.oldToNew;

export const findTenantProfiles = (tenant: string) =>
  fetchCol(getProfileCol().where("tenant", "==", tenant), "uid");

export const findProfileByEmail = (email: string) =>
  fetchCol(getProfileCol().where("email", "==", email), "uid").then(
    (profiles) => profiles[0]
  );

export const getProfilesByTenantPipe = (tenant: string) => {
  const col = getProfileCol()
    .where("tenant", "==", tenant)
    .where("disabled", "==", false);
  return colPipe(col, "uid", "viewUserProfilesPipe");
};

export const getDisabledProfilesByTenantPipe = (tenant: string) => {
  const col = getProfileCol()
    .where("tenant", "==", tenant)
    .where("disabled", "==", true);
  return colPipe(col, "uid", "viewDisabledUserProfilesPipe");
};

export const getProfilesByUidPipe = (
  profileCol: FirestoreCollection<Profile>,
  profileUids: string[],
  description: string
) =>
  batchColPipe(
    profileCol,
    firestoreDocumentId(),
    "in",
    profileUids,
    "uid",
    "getProfilesByUidPipe:" + description
  );
