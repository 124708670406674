/* DON'T EDIT THIS FILE: edit original and run build again */ import { getConfig } from "../../framework/config/config.ts";
import {
  IdentifyCallback,
  setTracker,
  setTrackerIdentify,
} from "../../framework/track/track.ts";
import {
  Identify,
  identify as amplitudeIdentify,
  track as amplitudeTrack,
  init,
  setUserId,
} from "@amplitude/analytics-browser";

const identifyToAmplitude: IdentifyCallback = ({ uid, userProps }) => {
  setUserId(uid ?? undefined);
  const event = new Identify();
  for (const [key, value] of Object.entries(userProps)) {
    if (value !== null) {
      event.set(key, value);
    }
  }
  amplitudeIdentify(event);
};

export const initAmplitude = () => {
  const { amplitudeApiKey } = getConfig();
  if (amplitudeApiKey) {
    init(amplitudeApiKey, {
      defaultTracking: true,
    });
    setTracker(amplitudeTrack);
    setTrackerIdentify(identifyToAmplitude);
  }
};
