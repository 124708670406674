/* DON'T EDIT THIS FILE: edit original and run build again */ import { useRealmFromUrl } from "../../framework/app-state/app-state-context.ts";
import { getTenantHandler } from "../../framework/tenant-collection/tenant.ts";
import { useEffect, useState } from "react";

export const TenantLogo = () => {
  const realm = useRealmFromUrl();
  const [TenantLogoComponent, setTenantLogoComponent] =
    useState<React.ComponentType | null>(null);
  useEffect(() => {
    getTenantHandler(realm)
      .getAuthPageLogo()
      .then((component) => setTenantLogoComponent(() => component));
  }, [realm]);
  return TenantLogoComponent ? (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ width: "400px", height: "120px" }}
    >
      <TenantLogoComponent />
    </div>
  ) : null;
};
