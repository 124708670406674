/* DON'T EDIT THIS FILE: edit original and run build again */ import firebase from "firebase/compat/app";
import { AttachmentRef } from "../../framework/attachment-type/attachment-ref-type.ts";
import { getConfig } from "../../framework/config/config.ts";
import { getFileExtension } from "../../framework/core/get-file-extension.ts";
import { generateId } from "../../framework/core/id.ts";
import { sandboxEnvironment } from "../../framework/environments/environment-const.ts";
import { sandboxFirebaseEmulatorsProjectId } from "../../framework/environments/sandbox-project-id.ts";
import { rewriteEmulatorUrl } from "../../framework/firebase/emulators-url-rewriter.ts";
import { getFirebaseFunctionsPort } from "../../framework/firebase/firebase-port.ts";
import { AttachmentFile } from "./attachment-file.ts";

const getAttachmentStoragePath = (uid: string, fileName: string) =>
  "users/" + uid + "/" + fileName;

const contentTypeByExtension: { [prop: string]: string } = {
  png: "image/png",
  webm: "video/webm",
  mp4: "video/mp4",
  gif: "image/gif",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  webp: "image/webp",
  svg: "image/svg+xml",
  txt: "text/plain",
  pdf: "application/pdf",
};

export const tmpFileToAttachment = async (
  tmpStoragePath: string,
  dstStoragePath: string
) => {
  await firebase.storage().bucket().file(tmpStoragePath).move(dstStoragePath);
};

// in the UI fileOrPath must a File object (as returned from a file input)
// in the backend it must be a string with the path
export const saveAttachment = async (
  attachmentFile: AttachmentFile,
  ownerUid: string
): Promise<AttachmentRef> => {
  const originalName = attachmentFile.getOriginalName();
  const extension = getFileExtension(originalName);
  const fileName = generateId() + "." + extension;
  const contentType = contentTypeByExtension[extension.toLowerCase()];
  const metadata: Record<string, any> = {
    contentDisposition: "attachment",
  };
  if (contentType) {
    metadata.contentType = contentType;
  }
  const storagePath = getAttachmentStoragePath(ownerUid, fileName);
  await attachmentFile.store(storagePath, metadata);
  return {
    type: "attachmentRef",
    id: fileName,
    storagePath: storagePath,
    originalName,
  };
};

export const getAttachmentDownloadUrlFromStoragePath = async (
  storagePath: string
) => {
  // @ts-expect-error .ref property is correct but not in the types
  return await firebase.storage().ref(storagePath).getDownloadURL();
};

export const getAttachmentDownloadUrl = async (attachment: AttachmentRef) =>
  await getAttachmentDownloadUrlFromStoragePath(attachment.storagePath);

export const getAttachmentPermalink = (id: string) => {
  const { environment } = getConfig();
  // We dont use the emulator hosting in the sandbox environment, so we link the function instead
  if (environment === sandboxEnvironment) {
    return rewriteEmulatorUrl(
      "http://0.0.0.0:" +
        getFirebaseFunctionsPort() +
        "/" +
        sandboxFirebaseEmulatorsProjectId +
        "/us-central1/http/h/permalink/" +
        id
    );
  }
  return "/h/permalink/" + id;
};

export const getAttachmentOriginalName = (attachment: AttachmentRef) =>
  attachment.originalName ?? attachment.id; // FIXME: some old attachments lack originalName (we should do an update...)

export const getAttachmentFile = async (attachment: AttachmentRef) => {
  return await firebase.storage().bucket().file(attachment.storagePath);
};
