/* DON'T EDIT THIS FILE: edit original and run build again */ export enum Gender {
  maleGender = "male",
  femaleGender = "female",
  undeterminedGender = "undetermined",
}

export const genderValueOptions = {
  [Gender.maleGender]: "Masculino",
  [Gender.femaleGender]: "Femenino",
  [Gender.undeterminedGender]: "Indeterminado",
};

export const getGenderLabel = (code: Gender) => genderValueOptions[code];
