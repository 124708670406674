/* DON'T EDIT THIS FILE: edit original and run build again */ import { SendEmailVerificationMailError } from "../../framework/auth-required-actions-typing/required-action-type.ts";

export const sendEmailVerificationMailCall = "sendEmailVerificationMailCall";

export type SendEmailVerificationMailResponse = {
  ok: boolean;
  error?: SendEmailVerificationMailError;
};

export type SendEmailVerificationMailCall = {
  name: typeof sendEmailVerificationMailCall;
  signature: (_param: null) => Promise<SendEmailVerificationMailResponse>;
};
