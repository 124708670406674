/* DON'T EDIT THIS FILE: edit original and run build again */ /* eslint-disable max-lines */
import firebase from "firebase/compat/app";
import { AtPath } from "../../framework/core/at-path.ts";
import { ElementOf } from "../../framework/core/element-of.ts";
import { Jsonable, JsonArray } from "../../framework/core/jsonable.ts";
import { NestedLeaveKey } from "../../framework/core/nested-key.ts";

import * as types from "./firestore-wrappers-types.ts";
import { serializePropsSample } from "./serialize-props-sample.ts";

export const createFirestoreCollection = <T extends types.DocumentBaseData>(
  name: string
) =>
  new FirestoreCollection<T>(
    firebase
      .firestore()
      .collection(name) as firebase.firestore.CollectionReference<T>
  );

export class FirestoreDocument<T extends types.DocumentBaseData> {
  private doc: types.FirestoreDocumentRef<T>;
  readonly id: string;
  readonly path: string;

  constructor(doc: types.FirestoreDocumentRef<T>) {
    this.doc = doc;
    this.id = doc.id;
    this.path = doc.path;
  }

  /**
   * do not use
   * */
  _getRealDoc() {
    return this.doc;
  }

  get() {
    return this.doc.get();
  }

  async update(data: types.UpdateData<T>) {
    try {
      return await this.doc.update(data);
    } catch (e) {
      throw new Error(
        "firestoreDocUpdateError: " +
          e.message +
          "\n\nFor document " +
          this.doc.path +
          ", with data: " +
          serializePropsSample(data)
      );
    }
  }

  onSnapshot(
    onNext: (snapshot: types.FirestoreDocumentSnapshot<T>) => void,
    onError?: (error: Error) => void
  ) {
    return this.doc.onSnapshot(onNext, onError);
  }

  collection<S extends types.DocumentBaseData>(path: string) {
    return new FirestoreCollection<S>(
      this.doc.collection(path) as firebase.firestore.CollectionReference<S>
    );
  }

  async set(
    data: types.MergeData<Partial<T>>,
    options: {
      readonly merge: true;
    }
  ): Promise<types.FirestoreWriteResult>;
  async set(
    data: types.SetData<T>,
    options?: firebase.firestore.SetOptions
  ): Promise<types.FirestoreWriteResult>;
  async set(data: any, options: any) {
    try {
      return await (options ? this.doc.set(data, options) : this.doc.set(data));
    } catch (e) {
      throw new Error(
        "firestoreDocSetError: " +
          e.message +
          "\nid = " +
          this.id +
          ", path = " +
          this.path +
          "\nWith data: " +
          serializePropsSample(data)
      );
    }
  }

  async create(data: types.SetData<T>): Promise<void> {
    await firebase.firestore().runTransaction(async (transaction) => {
      // we don't use runFirestoreTransaction to avoid a circular dependency
      const snap = await transaction.get(this.doc);
      if (snap.exists) {
        throw new Error("docAlreadyExists: " + this.path);
      }
      transaction.set(this.doc, data);
    });
  }

  async maybeCreate(data: types.SetData<T>): Promise<boolean> {
    let wasCreated = true;
    await firebase.firestore().runTransaction(async (transaction) => {
      // we don't use runFirestoreTransaction to avoid a circular dependency
      const snap = await transaction.get(this.doc);
      if (snap.exists) {
        wasCreated = false;
        return;
      }
      transaction.set(this.doc, data);
    });
    return wasCreated;
  }

  isEqual(b: FirestoreDocument<T>) {
    return this.doc.isEqual(b._getRealDoc());
  }

  async delete() {
    return this.doc.delete();
  }
}

export class FirestoreCollection<T extends types.DocumentBaseData> {
  constructor(
    private collection: firebase.firestore.Query<T>,
    private wheres: types.FieldOpAndValue<T>[] = [],
    private disjunctionNumber: number = 1,
    private orderBys: types.OrderAndDir<T>[] = [],
    private selectedFields: (keyof T & string)[] | null = null,
    private limitValue: number | null = null,
    private startAts: Jsonable[] = [],
    private endAts: Jsonable[] = [],
    private startAfters: Jsonable[] = [],
    private endBefores: Jsonable[] = []
  ) {}

  _getQuery() {
    let query = this.collection;
    this.wheres.forEach((where) => {
      const [field, op, value] = where;
      query = query.where(field, op, value);
    });
    this.orderBys.forEach((orderBy) => {
      const [field, direction] = orderBy;
      if (direction !== "desc" && direction !== "asc") {
        throw new Error("invalidDirectionStr: " + direction);
      }
      if (!field) {
        throw new Error("orderByFieldNotDefined");
      }
      query = query.orderBy(field, direction);
    });
    if (this.startAts.length > 0) {
      query = query.startAt(...this.startAts);
    }
    if (this.endAts.length > 0) {
      query = query.endAt(...this.endAts);
    }
    if (this.startAfters.length > 0) {
      query = query.startAfter(...this.startAfters);
    }
    if (this.endBefores.length > 0) {
      query = query.endBefore(...this.endBefores);
    }
    if (this.selectedFields) {
      query = query.select(
        ...this.selectedFields
      ) as firebase.firestore.Query<T>;
    }
    if (this.limitValue) {
      query = query.limit(this.limitValue);
    }
    return query;
  }

  where<K extends string>(
    fieldPath: K,
    opStr: types.WhereFilterOpElemLike,
    value: AtPath<T, K, ".">
  ): FirestoreCollection<T>;
  where<K extends string>(
    fieldPath: K,
    opStr: "!=",
    value: AtPath<T, K, "."> | null
  ): FirestoreCollection<T>;
  where<K extends string>(
    fieldPath: K,
    opStr: types.WhereFilterOpElemInArrayLike,
    value: ElementOf<AtPath<T, K, ".">>
  ): FirestoreCollection<T>;
  where<K extends string>(
    fieldPath: K,
    opStr: types.WhereFilterOpArrayLike,
    value: NonNullable<AtPath<T, K, ".">> extends JsonArray
      ? AtPath<T, K, ".">
      : AtPath<T, K, ".">[]
  ): FirestoreCollection<T>;
  where(
    fieldPath: types.FieldPath,
    opStr: types.WhereFilterOp,
    value: Jsonable
  ): FirestoreCollection<T>;
  where(fieldPath: any, opStr: any, value: any) {
    let disjunctions = this.disjunctionNumber;
    if (["in", "not-in", "array-contains-any"].includes(opStr)) {
      disjunctions *= value.length;
      if (disjunctions > 30) {
        // firestore limit
        throw new Error("queryExceedsFirebaseDisjunctionLimit");
      }
    }
    return new FirestoreCollection(
      this.collection,
      [...this.wheres, [fieldPath, opStr, value]],
      disjunctions,
      this.orderBys,
      this.selectedFields,
      this.limitValue,
      this.startAts,
      this.endAts,
      this.startAfters,
      this.endBefores
    );
  }

  limit(number: number) {
    return new FirestoreCollection(
      this.collection,
      this.wheres,
      this.disjunctionNumber,
      this.orderBys,
      this.selectedFields,
      number,
      this.startAts,
      this.endAts,
      this.startAfters,
      this.endBefores
    );
  }

  orderBy(
    fieldPath: types.FieldPath | NestedLeaveKey<T, ".">,
    directionStr: firebase.firestore.OrderByDirection
  ) {
    return new FirestoreCollection(
      this.collection,
      this.wheres,
      this.disjunctionNumber,
      [...this.orderBys, [fieldPath, directionStr]],
      this.selectedFields,
      this.limitValue,
      this.startAts,
      this.endAts,
      this.startAfters,
      this.endBefores
    );
  }

  startAt(...fieldValues: Jsonable[]) {
    if (this.startAts.length > 0) {
      throw new Error("startAtAlreadySet");
    }
    if (this.startAfters.length > 0) {
      throw new Error("startAfterAlreadySet");
    }
    if (this.orderBys.length < fieldValues.length) {
      throw new Error("startAtFieldValuesMismatch");
    }
    return new FirestoreCollection(
      this.collection,
      this.wheres,
      this.disjunctionNumber,
      this.orderBys,
      this.selectedFields,
      this.limitValue,
      fieldValues,
      this.endAts,
      this.startAfters,
      this.endBefores
    );
  }

  endAt(...fieldValues: Jsonable[]) {
    if (this.endAts.length > 0) {
      throw new Error("endAtAlreadySet");
    }
    if (this.endBefores.length > 0) {
      throw new Error("endBeforeAlreadySet");
    }
    if (this.orderBys.length < fieldValues.length) {
      throw new Error("endAtFieldValuesMismatch");
    }
    return new FirestoreCollection(
      this.collection,
      this.wheres,
      this.disjunctionNumber,
      this.orderBys,
      this.selectedFields,
      this.limitValue,
      this.startAts,
      fieldValues,
      this.startAfters,
      this.endBefores
    );
  }

  startAfter(...fieldValues: Jsonable[]) {
    if (this.startAfters.length > 0) {
      throw new Error("startAfterAlreadySet");
    }
    if (this.startAts.length > 0) {
      throw new Error("startAtAlreadySet");
    }
    if (this.orderBys.length < fieldValues.length) {
      throw new Error("startAfterFieldValuesMismatch");
    }
    return new FirestoreCollection(
      this.collection,
      this.wheres,
      this.disjunctionNumber,
      this.orderBys,
      this.selectedFields,
      this.limitValue,
      this.startAts,
      this.endAts,
      fieldValues,
      this.endBefores
    );
  }

  endBefore(...fieldValues: Jsonable[]) {
    if (this.endBefores.length > 0) {
      throw new Error("endBeforeAlreadySet");
    }
    if (this.endAts.length > 0) {
      throw new Error("endAtAlreadySet");
    }
    if (this.orderBys.length < fieldValues.length) {
      throw new Error("endBeforeFieldValuesMismatch");
    }
    return new FirestoreCollection(
      this.collection,
      this.wheres,
      this.disjunctionNumber,
      this.orderBys,
      this.selectedFields,
      this.limitValue,
      this.startAts,
      this.endAts,
      this.startAfters,
      fieldValues
    );
  }

  toJson() {
    return {
      wheres: this.wheres,
      disjunctions: this.disjunctionNumber,
      orderBys: this.orderBys,
      selectedFields: this.selectedFields,
      limitValue: this.limitValue,
      startAts: this.startAts,
      endAts: this.endAts,
      startAfters: this.startAfters,
      endBefores: this.endBefores,
    };
  }

  doc(path: string) {
    const collection = this
      .collection as firebase.firestore.CollectionReference<T>;
    return new FirestoreDocument<T>(collection.doc(path));
  }

  get() {
    return this._getQuery().get();
  }

  onSnapshot(
    onNext: (snapshot: types.FirestoreQuerySnapshot<T>) => void,
    onError: (error: Error) => void
  ) {
    return this._getQuery().onSnapshot(onNext, onError);
  }

  isEqual(b: FirestoreCollection<T>) {
    return this._getQuery().isEqual(b._getQuery());
  }

  select(...fieldNames: types.FieldKey<T>[]) {
    return new FirestoreCollection(
      this.collection,
      this.wheres,
      this.disjunctionNumber,
      this.orderBys,
      fieldNames,
      this.limitValue,
      this.startAts,
      this.endAts,
      this.startAfters,
      this.endBefores
    );
  }

  disjunctions() {
    return this.disjunctionNumber;
  }
}
