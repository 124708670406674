/* DON'T EDIT THIS FILE: edit original and run build again */ import { isCompanyInlineEntity } from "../../credit/entity/inline-entity.ts";
import { notCondition } from "../../credit/live-form/combine-conditions.ts";
import { LiveFieldConditionCallback } from "../../credit/live-form/types.ts";
import { notNull } from "../../framework/core/not-null.ts";
import { getTenantHandler } from "../../framework/tenant-collection/tenant.ts";

export const onlyPeople: LiveFieldConditionCallback = ({
  liveData: { recordValue },
}) => isCompanyInlineEntity(notNull(recordValue)) === false;

export const onlyCompanies: LiveFieldConditionCallback = ({
  liveData: { recordValue },
}) => isCompanyInlineEntity(notNull(recordValue)) === true;

export const tenantUsesUruguayLaw = (tenant: string) =>
  !!getTenantHandler(tenant).getOnboardingOptions()?.usesUruguayLaw();

export const onlyUruguay: LiveFieldConditionCallback = ({
  liveData: { baseFolder },
}) => tenantUsesUruguayLaw(notNull(baseFolder).tenant);

export const notUruguay: LiveFieldConditionCallback = notCondition(onlyUruguay);

export const onlyUruguayCompanies: LiveFieldConditionCallback = (param) =>
  onlyUruguay(param) && onlyCompanies(param);

export const onlyUruguayPeople: LiveFieldConditionCallback = (param) =>
  onlyUruguay(param) && onlyPeople(param);
