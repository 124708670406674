/* DON'T EDIT THIS FILE: edit original and run build again */ import { RequiredActionCallback } from "../../framework/auth-required-actions-typing/required-action-type.ts";
import { requiredActionsSettingId } from "../../framework/auth-required-actions-typing/settings-id.ts";
import { enforceVerifyTermsAndConditionsId } from "../../framework/auth-verify-terms-typing/required-action-type.ts";
import { endClientUserRole } from "../../framework/permission/all-roles.ts";
import { RolesMap } from "../../framework/permission/schema.ts";
import { Settings } from "../../framework/settings/schema.ts";
import { termsAndConditionsVersionNumber } from "../../framework/terms-of-use-version-number/terms-of-use-version.ts";

export const isNotTermsAndConditionsVerified: RequiredActionCallback = (
  { privateProfile },
  settings
) => {
  const acceptedNumber =
    privateProfile?.acceptedTermsAndConditionsVersionNumber;
  if (acceptedNumber === undefined) {
    return true;
  }
  return (
    acceptedNumber !==
    (settings?.termsAndConditionsCurrentVersionNumber ??
      termsAndConditionsVersionNumber)
  );
};

export const shouldEnforceTermsAndConditionsVerify: RequiredActionCallback = (
  { roles, currentTenant }: { roles?: RolesMap; currentTenant: string },
  settings?: Settings
) =>
  settings?.[requiredActionsSettingId]?.[currentTenant]?.[
    enforceVerifyTermsAndConditionsId
  ] && !roles?.[endClientUserRole];
