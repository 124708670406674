/* DON'T EDIT THIS FILE: edit original and run build again */ import { argentinaCountryCode } from "../../framework/country/country-codes.ts";
import md5 from "md5";

export const entityIdRegex = /ENTITY:([0-9a-f]{32})/;
const entityIdFullLineRegex = new RegExp("^" + entityIdRegex.source + "$");

const getNationalIdCode = (
  country: string,
  type: "cuit" | "unspecified",
  id: string
) =>
  country +
  "-" +
  type +
  ":" +
  id.replace(/\./g, "%2E").replace(/\//g, "%2F").replace(/ /g, "%20");

const getRawEntityId = ({
  cuit,
  country,
  foreignIdNumber,
}: {
  cuit?: string;
  country?: string;
  foreignIdNumber?: string;
}) => {
  if (cuit) {
    return getNationalIdCode(argentinaCountryCode, "cuit", cuit);
  } else if (country && foreignIdNumber) {
    return getNationalIdCode(country, "unspecified", foreignIdNumber);
  } else {
    throw new Error("missingKeyInfoForId");
  }
};

export const getEntityId = ({
  cuit,
  country,
  foreignIdNumber,
}: {
  cuit?: string;
  country?: string;
  foreignIdNumber?: string;
}) =>
  "ENTITY:" +
  md5(
    getRawEntityId({
      cuit,
      country,
      foreignIdNumber,
    })
  );

export const isEntityId = (id: string): boolean =>
  entityIdFullLineRegex.test(id);
