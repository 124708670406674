/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../../framework/html-editor/button/command-button.tsx";
import { UnderlineIcon } from "../../../framework/theme-icons/icon.ts";
import { useActive, useCommands } from "@remirror/react";
import { useCallback } from "react";

export const UnderlineButton = () => {
  const { toggleUnderline } = useCommands();

  const handleAction = useCallback(() => {
    if (toggleUnderline.enabled()) {
      toggleUnderline();
    }
  }, [toggleUnderline]);

  const active = useActive().underline();
  const enabled = toggleUnderline.enabled();

  return (
    <CommandButton
      commandName="underline"
      active={active}
      enabled={enabled}
      onAction={handleAction}
    >
      <UnderlineIcon />
    </CommandButton>
  );
};
