/* DON'T EDIT THIS FILE: edit original and run build again */ import { useRealmFromUrl } from "../../framework/app-state/app-state-context.ts";
import { useAccountCached } from "../../framework/auth-ui/use-account.ts";
import { canAccessTenant } from "../../framework/auth/allowed-tenants.ts";
import { unknownTenant } from "../../framework/tenant-collection/null-tenant.ts";
import { getTenantCodeFromUrl } from "../../framework/url-parsing/parse-tenant-from-url.ts";

export const useCurrentTenant = (guessIfInvalid = false): string => {
  const tenantFromUrl = getTenantCodeFromUrl(document.location);
  const realmFromUrl = useRealmFromUrl();
  const { profile } = useAccountCached();
  if (
    (tenantFromUrl === unknownTenant && !guessIfInvalid) ||
    canAccessTenant(profile, tenantFromUrl)
  ) {
    return tenantFromUrl;
  }
  // eslint-disable-next-line no-restricted-properties
  return profile?.tenant ?? realmFromUrl;
};
