/* DON'T EDIT THIS FILE: edit original and run build again */ import { Checkbox } from "./checkbox.tsx";

export const LabeledCheckbox = ({
  label,
  value,
  setValue,
  id,
  onChange,
  className = "",
  onBlur,
  type = "checkbox",
  block = true,
  autoFocus = false,
  disabled = false,
}: {
  label: string;
  value: boolean;
  setValue: (value: boolean) => void;
  id?: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  type?: "checkbox" | "radio";
  block?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}) => (
  <label className={(block ? "d-block" : "") + " " + (className || "")}>
    <Checkbox
      id={id}
      type={type}
      value={value}
      setValue={setValue}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      disabled={disabled}
    />
    <div className={"ps-2 d-inline " + (disabled ? "text-gray40" : "")}>
      {label}
    </div>
  </label>
);
