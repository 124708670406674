/* DON'T EDIT THIS FILE: edit original and run build again */ import firebase from "firebase/compat/app";
import { AttachmentFile } from "./attachment-file.ts";

const storeFileWithClientApi = async (
  file: File,
  storagePath: string,
  metadata: any
) => {
  // @ts-expect-error .ref property is correct but not in the types
  const ref = firebase.storage().ref(storagePath);
  await ref.put(file);
  await ref.updateMetadata(metadata);
};

export const BrowserAttachmentFile = class extends AttachmentFile {
  private file: File;

  // this represents a File object in the browser
  constructor(file: File) {
    super();
    this.file = file;
  }

  async store(storagePath: string, metadata: any) {
    await storeFileWithClientApi(this.file, storagePath, metadata);
  }

  getOriginalName() {
    return this.file.name;
  }
};
