/* DON'T EDIT THIS FILE: edit original and run build again */ import { Div } from "../../onboarding/pdf-wrapper/pdf-wrapper.tsx";

export const CircledNumber = ({ number }: { number: number }) => (
  <Div
    className={
      "rounded-circle p-1 me-2 d-flex flex-grow-0 align-items-center justify-content-center bg-gray10 text-white text-s small-square"
    }
  >
    {number}
  </Div>
);
