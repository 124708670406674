/* DON'T EDIT THIS FILE: edit original and run build again */ import { useEffect, useRef } from "react";

export const useMemoCompare = <TType>(
  next: TType,
  compare: (a: TType | undefined, b: TType | undefined) => boolean
): TType => {
  const previousRef = useRef<TType>();
  const previous = previousRef.current;
  const isEqual = compare(previous, next);
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  return isEqual ? (previous as TType) : next;
};
