/* DON'T EDIT THIS FILE: edit original and run build again */ import { DeleteForeverIcon } from "../../framework/theme-icons/icon.ts";
import { MouseEventHandler } from "react";
import { Button } from "react-bootstrap";

export const RemoveButton = ({
  onClick,
  className = "",
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => (
  <div className="flex-d align-items-center">
    <Button
      variant="warning"
      onClick={onClick}
      className={"d-flex align-items-center px-4 py-2 " + className}
    >
      <DeleteForeverIcon className="me-1 icon-3" />
      <span>Quitar</span>
    </Button>
  </div>
);
