/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { firstCapitalTenant } from "./code.ts";
import { firstCapitalOnboardingOptions } from "./firstcapital-onboarding-options.ts";
import { firstCapitalFullLabel } from "./full-label.ts";
import { firstCapitalLabel } from "./label.ts";

class FirstCapitalTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_firstcapital.svg?react")).default;
  }

  getCode(): string {
    return firstCapitalTenant;
  }

  getPathLabel(): string | null {
    return "first-capital";
  }

  getLabel(): string {
    return firstCapitalLabel;
  }

  getFullLabel(): string {
    return firstCapitalFullLabel;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return firstCapitalOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@firstcapital.group";
  }

  getComplianceEmailAddress(): string {
    return "compliance@firstcapital.group";
  }

  getCuit(): string {
    return "30-71223938-3";
  }

  getCnvRegistryId(): string | null {
    return "532";
  }

  hasSemaphoreSettings(): boolean {
    return false;
  }
}

const firstCapitalTenantHandler = new FirstCapitalTenantHandler();

export default firstCapitalTenantHandler;
