/* DON'T EDIT THIS FILE: edit original and run build again */ import { removeAccents } from "../../framework/core/remove-accents.ts";

export const cabaProvinceCode = "AR-C";
export const baProvinceCode = "AR-B";

export const argentineProvinces: { [code: string]: string } = {
  [baProvinceCode]: "Buenos Aires",
  "AR-K": "Catamarca",
  "AR-H": "Chaco",
  "AR-U": "Chubut",
  [cabaProvinceCode]: "Ciudad Autónoma de Buenos Aires",
  "AR-W": "Corrientes",
  "AR-X": "Córdoba",
  "AR-E": "Entre Ríos",
  "AR-P": "Formosa",
  "AR-Y": "Jujuy",
  "AR-L": "La Pampa",
  "AR-F": "La Rioja",
  "AR-M": "Mendoza",
  "AR-N": "Misiones",
  "AR-Q": "Neuquén",
  "AR-R": "Río Negro",
  "AR-A": "Salta",
  "AR-J": "San Juan",
  "AR-D": "San Luis",
  "AR-Z": "Santa Cruz",
  "AR-S": "Santa Fe",
  "AR-G": "Santiago del Estero",
  "AR-V": "Tierra del Fuego",
  "AR-T": "Tucumán",
};

const simplifyName = (name: string) => removeAccents(name.toLowerCase());

const simpleNameToCodeMap = Object.fromEntries(
  Object.entries(argentineProvinces).map(([code, name]) => [
    simplifyName(name),
    code,
  ])
);
simpleNameToCodeMap["capital federal"] = cabaProvinceCode;

export const getArgentinaProvinceCodeByName = (name: string) => {
  const converted = simpleNameToCodeMap[removeAccents(name.toLowerCase())];
  if (converted) {
    return converted;
  } else {
    return null;
  }
};
