/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { adcapTenant } from "./code.ts";
import { adcapFullLabel } from "./full-label.ts";
import { adcapLabel } from "./label.ts";

class AdcapTenantHandler extends AlycTenantHandler {
  getCode(): string {
    return adcapTenant;
  }

  getPathLabel(): string | null {
    return "adcap";
  }

  getLabel(): string {
    return adcapLabel;
  }

  getFullLabel(): string {
    return adcapFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71161012-6";
  }

  getCnvRegistryId(): string {
    return "148";
  }
}

const adcapTenantHandler = new AdcapTenantHandler();

export default adcapTenantHandler;
