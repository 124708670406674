/* DON'T EDIT THIS FILE: edit original and run build again */ import { getLiveFormInputFactory } from "../../credit/live-form-field-collection/get-input-factory.ts";
import { RawLiveForm } from "../../credit/live-form/ui/raw-live-form.tsx";
import { LiveFormParams } from "../../credit/live-form/ui/types.ts";

export const LiveForm = ({
  liveRecord,
  saveField,
  Widgets = {},
  autoFocus = false,
  allowDefaultValue = true,
  onEnterPress,
}: LiveFormParams) => (
  <RawLiveForm
    getLiveFormInputFactory={getLiveFormInputFactory}
    liveRecord={liveRecord}
    saveField={saveField}
    Widgets={Widgets}
    autoFocus={autoFocus}
    allowDefaultValue={allowDefaultValue}
    onEnterPress={onEnterPress}
  />
);
