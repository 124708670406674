/* DON'T EDIT THIS FILE: edit original and run build again */ import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { useDeepMemo } from "../../framework/react/use-deep-memo.ts";
import deepEqual from "fast-deep-equal";
import { useEffect, useState } from "react";

const entries: { [id: number]: any } = {};
let lastId = 0;

const getExistingEntry = (key: any) => {
  for (const id in entries) {
    const entry = entries[id];
    if (deepEqual(entries[id].key, key)) {
      return [id, entry];
    }
  }
  return [null, null];
};

const getOrCreateCacheEntry = (key: any, create: any, destroy: any) => {
  const [oldEntryId, existingEntry] = getExistingEntry(key);
  const newEntry = existingEntry ? null : { key, value: create(), count: 0 };
  let entryId: any;
  if (newEntry) {
    const newEntryId = ++lastId;
    entries[newEntryId] = newEntry;
    entryId = newEntryId;
  } else {
    entryId = oldEntryId;
  }
  const entry = existingEntry ?? newEntry;
  if (entry.value && entry.value.isDeleted()) {
    const createCallbackName = key?.[0]?.name;
    throw new Error(
      "cannotReturnADeletedPipe: " +
        entryId +
        " existingEntry: " +
        (existingEntry ? "yes" : "no") +
        (createCallbackName ? ", creator: " + createCallbackName : "")
    );
  }
  ++entry.count;
  return [
    entry.value,
    () => {
      const newCount = --entry.count;
      if (newCount === 0) {
        delete entries[entryId];
        destroy(entry.value);
      }
    },
  ];
};

const isPipeLoading = (pipe: DataPipe<any> | null): boolean =>
  !!pipe && pipe.isLoading();

const getKey = (creator: any, memoizedParams: any) => [creator, memoizedParams];

export function usePipe<TPipe, const TParams extends unknown[]>(
  creator: (...args: TParams) => DataPipe<TPipe> | null,
  params: TParams,
  discardablePipe = true
):
  | [loading: true, result: null, error: null]
  | [loading: false, result: TPipe, error: null]
  | [loading: false, result: null, error: string | Error] {
  const memoizedParams = useDeepMemo(params);
  const [_, existingEntry] = getExistingEntry(getKey(creator, memoizedParams));
  const existingPipe = existingEntry ? existingEntry.value : null;
  const [state, setState] = useState({
    loading: existingPipe ? isPipeLoading(existingPipe) : true,
    result: existingPipe ? existingPipe.getValue() : null,
    error: null,
  });
  useEffect(() => {
    const [pipe, cleanup] = getOrCreateCacheEntry(
      getKey(creator, memoizedParams),
      creator ? () => creator(...memoizedParams) : () => null,
      (pipe: DataPipe<TPipe> | null) => {
        if (pipe && discardablePipe) {
          pipe.delete();
        }
      }
    );
    const updateState = () =>
      setState({
        loading: isPipeLoading(pipe),
        result: pipe ? pipe.getValue() : null,
        error: pipe ? pipe.getError() : null,
      });
    if (pipe) {
      pipe.addDataListener(updateState);
    }
    if (!isPipeLoading(pipe)) {
      updateState();
    }
    return () => {
      if (pipe) {
        pipe.removeDataListener(updateState);
      }
      setTimeout(() => {
        cleanup();
      }, 10000);
    };
  }, [creator, memoizedParams, discardablePipe]);
  return [state.loading, state.result, state.error];
}
