/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { avalruralTenant } from "./code.ts";
import { avalruralFullLabel } from "./full-label.ts";
import { avalruralLabel } from "./label.ts";

class AvalRuralTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return avalruralTenant;
  }

  getPathLabel(): string | null {
    return "aval-rural";
  }

  getLabel(): string {
    return avalruralLabel;
  }

  getFullLabel(): string {
    return avalruralFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-70906182-4";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  doProducersSignUpViaMatchfinRealm(): boolean {
    return true;
  }
}

const avalRuralTenantHandler = new AvalRuralTenantHandler();

export default avalRuralTenantHandler;
