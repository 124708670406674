/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";

class MatchFinLineOptions extends BaseLineOptions {
  endClientsCanRequestMatchfinAnalystsReview(): boolean {
    return true;
  }

  canChooseAlyc(): boolean {
    return true;
  }
}

export const matchfinLineOptions = new MatchFinLineOptions();
