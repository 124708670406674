/* DON'T EDIT THIS FILE: edit original and run build again */ import { CommandButton } from "../../../framework/html-editor/button/command-button.tsx";
import { LinkIcon } from "../../../framework/theme-icons/icon.ts";
import { getMarkRange } from "@remirror/core";
import {
  useActive,
  useAttrs,
  useChainedCommands,
  useCurrentSelection,
} from "@remirror/react";
import { useCallback } from "react";

export const LinkButton = () => {
  const chain = useChainedCommands();
  const currentHref = useAttrs().link()?.href;
  const { $from, empty } = useCurrentSelection();
  const active = useActive().link();

  const handleAction = useCallback(() => {
    const markRange = getMarkRange($from, "link");
    const rangeCurrentHref = markRange?.mark?.attrs?.href;
    const href = prompt("URL", rangeCurrentHref || currentHref || "");

    if (href) {
      const range = markRange ? markRange : undefined;
      chain.updateLink({ href, auto: false }, range);
    }

    chain.focus().run();
  }, [$from, chain, currentHref]);

  const enabled = !empty;

  return (
    <CommandButton
      commandName="link"
      active={active}
      enabled={enabled}
      onAction={handleAction}
    >
      <LinkIcon />
    </CommandButton>
  );
};
