/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { matbarofexTenant } from "./code.ts";
import { matbarofexFullLabel } from "./full-label.ts";
import { matbarofexLabel } from "./label.ts";

class MatbarofexTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return matbarofexTenant;
  }

  getLabel(): string {
    return matbarofexLabel;
  }

  getPathLabel(): string | null {
    return "matba-rofex";
  }

  getFullLabel(): string {
    return matbarofexFullLabel;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "20-238486050-0";
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const matbarofexTenantHandler = new MatbarofexTenantHandler();

export default matbarofexTenantHandler;
