/* DON'T EDIT THIS FILE: edit original and run build again */ import { useRealmFromUrl } from "../../framework/app-state/app-state-context.ts";
import { RequiredActionsContext } from "../../framework/auth-required-actions-context/use-required-actions.front.ts";
import { RequiredActions } from "../../framework/auth-required-actions/required-actions-item.front.tsx";
import { useRequiredActions } from "../../framework/auth-required-actions/required-actions.front.ts";
import { useAccountCached } from "../../framework/auth-ui/use-account.ts";
import { canAccessTenant } from "../../framework/auth/allowed-tenants.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import { getLoginRedirectPath } from "../../framework/routing/tenant-routing.tsx";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import {
  ForbiddenPage,
  SimpleForbiddenPage,
} from "../../framework/theme-pages/forbidden-page.front.tsx";
import {
  PageNotFoundPage,
  SimplePageNotFoundPage,
} from "../../framework/theme-pages/page-not-found.front.tsx";
import { track } from "../../framework/track/track.ts";
import { useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

const RequiredActionsWrapper = ({
  children,
  requiresAccount,
}: {
  children: React.ReactNode;
  requiresAccount: boolean;
}) => {
  const { actions, hasRequiredActions } = useRequiredActions();
  return (
    <RequiredActionsContext.Provider value={{ hasRequiredActions }}>
      {requiresAccount && hasRequiredActions ? (
        <RequiredActions actions={actions} />
      ) : (
        children
      )}
    </RequiredActionsContext.Provider>
  );
};

export const RouteWrapper = ({
  path,
  requires = {},
  Component,
  redirectOnNoAccount,
  hardcodedProps,
  currentTenant,
  isMatchfinSpecific,
}: {
  path: string;
  requires?: {
    account?: boolean;
    permission?: string;
    tenant?: boolean;
  };
  Component: any;
  redirectOnNoAccount?: string;
  hardcodedProps?: any;
  currentTenant?: string;
  isMatchfinSpecific?: boolean;
}) => {
  const { tenantLabel: _, ...routeProps } = useParams();
  const location = useLocation();
  const { account, profile, roles } = useAccountCached();
  const realm = useRealmFromUrl();

  useEffect(() => {
    track("navigate", { path: path, props: routeProps });
  }, [path, routeProps]);

  if (requires.account) {
    if (!account) {
      // if no account, we assume navigation is absolute
      const redirectTo =
        redirectOnNoAccount ??
        getLoginRedirectPath({ ...location, routeProps });
      return <Navigate to={redirectTo} replace={true} />;
    }
    if (
      requires.permission &&
      (!roles || !userHasPermission(roles, requires.permission))
    ) {
      Component = ForbiddenPage;
    }
    if (
      isMatchfinSpecific &&
      (realm !== matchfinTenant || currentTenant !== matchfinTenant)
    ) {
      Component = PageNotFoundPage;
    }
  } else {
    if (
      isMatchfinSpecific &&
      (realm !== matchfinTenant || currentTenant !== matchfinTenant)
    ) {
      Component = SimplePageNotFoundPage;
    }
  }
  if (requires.tenant && !canAccessTenant(profile, currentTenant)) {
    Component = SimpleForbiddenPage;
  }

  return (
    <RequiredActionsWrapper requiresAccount={!!requires.account}>
      <Component {...routeProps} {...hardcodedProps} />
    </RequiredActionsWrapper>
  );
};
